import { Fade, Flex } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import access from "utils/DotNotation";
import FormTransforms from "../FormTransforms";
import {
  FormBuilderV2Props,
  Transformation,
  TransformContextType,
} from "../types";
import SubmitForm from "./Submit";
import { TransformProvider } from "./TransformProvider";

const FullForm = ({
  children,
  submit,
  initialValues,
  disabled,
}: FormBuilderV2Props<any>) => {
  const [transformations, setTransformations] = useState<Transformation[]>([]);
  const [rendered, setRendered] = useState(false);

  const handleAddTransformation: TransformContextType["bind"] = (id, t, r) => {
    !r
      ? setTransformations((tg) => [...tg, [id, t as any]])
      : setTransformations((tg) => [...tg, [id, t, r]]);
  };

  const formController = useForm({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const applyTransforms = (data?: any) => {
    if (!data) return undefined;
    let transformed = { ...data };
    transformations.forEach((element) => {
      const el = access(transformed, element[0]);
      if (typeof element[1] === "string" || element[1] instanceof String) {
        transformed = access(
          transformed,
          element[0],
          true,
          FormTransforms[element[1] as keyof typeof FormTransforms][0](el)
        );
      } else if (element[1])
        transformed = access(transformed, element[0], true, element[1](el));
    });

    return transformed;
  };

  useEffect(() => {
    if (rendered) formController.reset(applyTransforms(initialValues));
  }, [transformations, rendered]);

  useEffect(() => setRendered(true), []);

  const _handleSubmit = (data: any) => {
    if (!data) return;
    let transformed = _.cloneDeep(data);

    transformations.forEach((element) => {
      const el = access(transformed, element[0]);
      if (typeof element[1] === "string" || element[1] instanceof String) {
        transformed = access(
          transformed,
          element[0],
          true,
          FormTransforms[element[1] as keyof typeof FormTransforms][1](el)
        );
      } else if (element[2])
        transformed = access(transformed, element[0], true, element[2](el));
    });

    submit?.onSubmit?.(transformed);
  };

  return (
    <TransformProvider value={{ bind: handleAddTransformation, disabled }}>
      <FormProvider {...formController}>
        <form
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
          onSubmit={
            submit ? formController.handleSubmit(_handleSubmit) : undefined
          }
        >
          <Fade in>
            <Flex
              w="100%"
              wrap="wrap"
              gap="24px"
              alignItems="flex-start"
              justifyContent="space-between"
              pb={submit ? "140px" : undefined}
            >
              {children}
            </Flex>
          </Fade>
          {submit && !disabled && <SubmitForm {...submit} />}
        </form>
      </FormProvider>
    </TransformProvider>
  );
};

export default FullForm;
