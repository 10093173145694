import { Badge, Text } from "@chakra-ui/react";
import { Column } from "components/layout/TableComponent";
import { NavLink } from "react-router-dom";
import { SCOPE_COLORS } from "utils/DataMap";

const MessageColumns: Column<Message>[] = [
  {
    id: "title",
    render: (value, data) => (
      <NavLink to={data.id ?? ""}>
        <Text>{value.it ?? "No title"} </Text>
      </NavLink>
    ),
    title: "Title IT",
    width: "100%",
  },
  {
    id: "id",
    render: (value) => <Text>{value}</Text>,
    title: "Id",
    width: "30%",
  },
  {
    id: "scope",
    render: (value) => (
      <Badge colorScheme={SCOPE_COLORS[value as Message["scope"]]}>
        {value}
      </Badge>
    ),
    title: "Scope",
    width: "20%",
  },
  {
    id: "repeat",
    render: (value) => <Text>{value}</Text>,
    title: "Name",
    width: "20%",
  },
];

export default MessageColumns;
