import { gql } from "@apollo/client";

const NOTIFICATION_FRAGMENT = gql`
  fragment notificationFragment on Notification {
    id
    sentOnDate
    createdAt
    lastUpdate
    occurrances
    numberOfRecipients
    status
    info {
      it
      en
      de
    }
    recipients
    creator {
      _id
      displayName
    }
    area
    notificationType
    involvedNames
    involvedIds
    head {
      it
      en
      de
    }
    content {
      it
      en
      de
    }
    description {
      it
      en
      de
    }
  }
`;

export const AREA_NOTIFICATIONS = gql`
  query AREA_NOTIFICATIONS {
    areaAllNotifications {
      ...notificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const CREATE_NOTIFICATION = gql`
  mutation areaCreateNotification(
    $recipients: AreaNotificationRecipients!
    $head: NotificationInfoInput!
    $content: NotificationInfoInput
    $description: NotificationInfoInput
  ) {
    areaCreateNotification(
      recipients: $recipients
      head: $head
      content: $content
      description: $description
    ) {
      id
    }
  }
`;

export const CREATE_TEST_NOTIFICATION = gql`
  mutation areaCreateTestNotification(
    $head: NotificationInfoInput!
    $content: NotificationInfoInput
    $description: NotificationInfoInput
  ) {
    areaCreateTestNotification(
      head: $head
      content: $content
      description: $description
    )
  }
`;

export const CANCEL_NOTIFICATION = gql`
  mutation areaUpdateNotification($id: ID!) {
    areaUpdateNotification(id: $id, status: cancel) {
      ...notificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const NOTIFICATION = gql`
  query NOTIFICATION($id: ID!) {
    Notification(id: $id) {
      ...notificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;
