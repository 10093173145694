import { Button, Flex, Grid, Tab, TabList, Tabs } from "@chakra-ui/react";
import { useBehave, useTask } from "behave-react";
import { useEffect, useState } from "react";
import { AreasAction, AreasSelect } from "store/modules/areas";

import { IoAdd } from "react-icons/io5";
import { NavLink, useParams } from "react-router-dom";

import PoisColumns from "./TableColumns";
import Card from "components/layout/Card/Card";
import TableComponent from "components/layout/TableComponent";
import useCoolQuery from "hooks/useCoolQuery";
import { ALL_IAMS } from "apollo/queries/iams";

const TablePage = () => {
  const { data, loading, refetch } = useCoolQuery<any, GetIamsResponse>(
    ALL_IAMS,
    "bike",
    {}
  );

  const handleQuery = (v: string, d: Message) => d.title.it.includes(v);

  return (
    <Card pl="0" pr="0" flexDirection="column">
      <TableComponent
        action2={
          <NavLink to="create">
            <Button variant="brand" leftIcon={<IoAdd />}>
              Add new
            </Button>
          </NavLink>
        }
        title="In App Messages"
        query={handleQuery}
        isLoading={loading}
        onRefresh={refetch}
        queryPlaceholder="Search for Track"
        columns={PoisColumns}
        data={data?.allInAppMessages ?? []}
      />
    </Card>
  );
};

export default TablePage;
