import {
  Tabs,
  TabList,
  Tab,
  Divider,
  Button,
  useToast,
  Badge,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import useCoolMutation from "hooks/useCoolMutation";

import { useEffect, useState } from "react";
import { ApolloContext } from "apollo/client";

import {
  AREA_TYPES,
  MOWI_TYPES,
  NOTIFICATION_RECIPEINTS,
  NOTIFICATION_TYPES,
  PRIMARY_SERVICES,
  REPEAT_IAM,
  SCOPE_IAM,
  SUGGESTER_PERIOD,
} from "utils/DataMap";
import { useNavigate, useParams } from "react-router-dom";

import GeoLocation from "components/layout/GeoLocation";
import { TRACK, TRACK_CREATE } from "apollo/queries/tracks";
import useCoolQuery from "hooks/useCoolQuery";
import { CREATE_IAMS } from "apollo/queries/iams";
import NotificationPreview from "./components/NotificationPreview";
import CleanPayload from "utils/CleanPayload";
import {
  CANCEL_NOTIFICATION,
  CREATE_NOTIFICATION,
  NOTIFICATION,
} from "apollo/queries/notifications";
import {
  Field,
  FormBuilderV2,
  FormCard,
} from "components/layout/FormBuilderV2";
const TypedField = Field<Notification>;

interface Props {
  isEdit?: boolean;
}

const CreateUpdateNotification = ({ isEdit }: Props) => {
  const toast = useToast();
  const [infosLen, setInfosLen] = useState("it");
  const { pid, id } = useParams();
  const [image, setImage] = useState<string>();

  const { data: dataNotification, loading: loadingFetch } = useCoolQuery<
    any,
    GetNotification
  >(NOTIFICATION, { id: pid }, { disableFetchOnLoad: !isEdit });

  const navigate = useNavigate();

  const [create, { loading, error, data }] = useCoolMutation(
    CREATE_NOTIFICATION,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: isEdit
            ? "Successfully update Notification!"
            : "Succesfully created Notification",
        });
        !isEdit && navigate("/areas/" + id + "/notifications");
      },
    }
  );

  const [deleteNotification, { loading: loadingDelete }] = useCoolMutation(
    CANCEL_NOTIFICATION,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: "Succesfully cancelled Notification",
        });
        navigate("/areas/" + id + "/notifications");
      },
    }
  );

  const handleCreate = (data: Notification) => {
    const variables = CleanPayload(data);
    create({ variables });
  };

  return (
    <FormBuilderV2
      loading={loadingFetch}
      editMode={isEdit}
      initialValues={dataNotification?.Notification}
      submit={{
        action: isEdit ? "Update" : "Create",
        color: isEdit ? "purple" : "green",
        loading,
        onSubmit: handleCreate,
        title: isEdit ? "Update Notification?" : "Create Notification?",
        subtitle: isEdit
          ? "This will update the notification"
          : "This will create a new notification in the current area.",
      }}
    >
      <FormCard title="Message summary">
        <TypedField
          id="notificationType"
          type="select"
          label="Type"
          options={NOTIFICATION_TYPES}
          required
        />
        <TypedField
          type="select"
          id="recipients"
          label="Recipients"
          options={NOTIFICATION_RECIPEINTS}
          required
        />
      </FormCard>
      <NotificationPreview />
      <FormCard>
        <Tabs>
          <TabList>
            <Tab onClick={() => setInfosLen("it")}>Italian</Tab>
            <Tab onClick={() => setInfosLen("en")}>English</Tab>
            <Tab onClick={() => setInfosLen("de")}>Deutsch</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField type="text" label="Head IT" id="head.it" />
              <TypedField type="textArea" label="Content IT" id="content.it" />
              <TypedField
                type="textArea"
                label="Description IT"
                placeholder="Description IT"
                id="description.it"
              />
              <TypedField type="text" label="Info IT" id="info.it" />
            </TabPanel>

            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField type="text" label="Head EN" id="head.en" />
              <TypedField type="textArea" label="Content EN" id="content.en" />
              <TypedField
                type="textArea"
                label="Description EN"
                placeholder="Description EN"
                id="description.en"
              />
              <TypedField type="text" label="Info EN" id="info.en" />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField type="text" label="Head DE" id="head.de" />
              <TypedField type="textArea" label="Content DE" id="content.de" />
              <TypedField
                type="textArea"
                label="Description DE"
                placeholder="Description DE"
                id="description.de"
              />
              <TypedField type="text" label="Info DE" id="info.de" />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormCard>
      <FormCard
        title="Notification status:"
        hide={!isEdit}
        rightAction={
          <Badge fontSize="16px">{dataNotification?.Notification.status}</Badge>
        }
      />

      <FormCard
        hide={!isEdit || dataNotification?.Notification.status === "cancel"}
        title="Delete In App Message"
        subtitle="Do you want to delete this IAM?"
        rightAction={
          <Button
            variant="outline"
            isLoading={loadingDelete}
            colorScheme="red"
            onClick={() => deleteNotification({ variables: { id } })}
          >
            Delete
          </Button>
        }
      />
    </FormBuilderV2>
  );
};

export default CreateUpdateNotification;
