import { gql } from "@apollo/client";

const USER_TRACK_FRAGMENT = gql`
  fragment trackFragment on UserTrack {
    id
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    name

    geoLocation {
      size
      label
      color
      coordinatesMultiLineString
      coordinatesPoint
      coordinatesLineString
    }
    technicalInfo {
      length
      altimetry {
        highestPoint
        lowestPoint
        differencePlus
        differenceMinus
      }
      maxSlope
    }
    level
    category
    area
    typology
    localities
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
      howToStart {
        it
        en
        de
      }
    }
    detailedInfo {
      trackNumber
      isCircular
      stations {
        start {
          name {
            it
            en
            de
          }
          altitude
        }
        end {
          name {
            it
            en
            de
          }
          altitude
        }
      }
      timeAvarage
      physicalEfford
      technicalAbility
      emotion
      landscape
      locality {
        it
        en
        de
      }
      level
      suggestedPeriod
      origin
      zone {
        name
        icon
      }
      isForPedestrians
      isForFamily
    }
    externalInfo{
      externalId
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const USER_TRACK_MEDIA_FRAGMENT = gql`
  fragment trackMediaFragment on UserTrack {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

const TRACK_FRAGMENT = gql`
  fragment trackFragment on Track {
    id
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    name
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    technicalInfo {
      length
      altimetry {
        highestPoint
        lowestPoint
        differencePlus
        differenceMinus
      }
      maxSlope
    }
    level
    category
    area
    typology
    localities
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
      howToStart {
        it
        en
        de
      }
    }
    detailedInfo {
      trackNumber
      isCircular
      stations {
        start {
          name {
            it
            en
            de
          }
          altitude
        }
        end {
          name {
            it
            en
            de
          }
          altitude
        }
      }
      timeAvarage
      physicalEfford
      technicalAbility
      emotion
      landscape
      locality {
        it
        en
        de
      }
      level
      suggestedPeriod
      origin
      zone {
        name
        icon
      }
      isForPedestrians
      isForFamily
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const TRACK_MEDIA_FRAGMENT = gql`
  fragment trackMediaFragment on Track {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

export const AREA_TRACKS = gql`
  query AREA_TRACKS($area: ID!) {
    userTracks(area: $area) {
      ...trackFragment
    }
  }
  ${USER_TRACK_FRAGMENT}
`;

export const TRACK = gql`
  query TRACK($id: ID!) {
    userTrack(id: $id) {
      ...trackFragment
      ...trackMediaFragment
    }
  }
  ${USER_TRACK_FRAGMENT}
  ${USER_TRACK_MEDIA_FRAGMENT}
`;

export const TRACK_CREATE = gql`
  mutation TRACK_CREATE(
    $name: String
    $media: TrackMediaInput
    $technicalInfo: TrackTechnicalInfoInput
    $geoLocation: TrackGeoLocationInput
    $externalInfo: TrackExternalInfoInput
    $level: String
    $category: String
    $originalStatus: Boolean
    $area: ID
    $typology: String
    $localities: [String]
    $tagFilters: [String]
    $description: TrackDescriptionInput
    $detailedInfo: TrackDetailedInfoInput
    $nextTracks: [ID]
    $files: [StorageFileInput]
  ) {
    createTrack(
      name: $name
      media: $media
      externalInfo: $externalInfo
      technicalInfo: $technicalInfo
      geoLocation: $geoLocation
      level: $level
      category: $category
      originalStatus: $originalStatus
      area: $area
      typology: $typology
      localities: $localities
      tagFilters: $tagFilters
      description: $description
      detailedInfo: $detailedInfo
      nextTracks: $nextTracks
      files: $files
    ) {
      ...trackFragment
      ...trackMediaFragment
    }
  }
  ${TRACK_FRAGMENT}
  ${TRACK_MEDIA_FRAGMENT}
`;

export const TRACK_DELETE = gql`
  mutation TRACK_DELETE($id: ID!) {
    deleteTrack(id: $id) {
      id
    }
  }
`;

export const TRACK_UPDATE = gql`
  mutation TRACK_UPDATE(
    $id: ID!
    $name: String
    $media: TrackMediaInput
    $externalInfo: TrackExternalInfoInput
    $technicalInfo: TrackTechnicalInfoInput
    $geoLocation: TrackGeoLocationInput
    $level: String
    $category: String
    $originalStatus: Boolean
    $area: ID
    $typology: String
    $localities: [String]
    $tagFilters: [String]
    $description: TrackDescriptionInput
    $detailedInfo: TrackDetailedInfoInput
    $nextTracks: [ID]
    $files: [StorageFileInput]
  ) {
    updateTrack(
      id: $id
      name: $name
      media: $media
      technicalInfo: $technicalInfo
      externalInfo: $externalInfo
      geoLocation: $geoLocation
      level: $level
      category: $category
      originalStatus: $originalStatus
      area: $area
      typology: $typology
      localities: $localities
      tagFilters: $tagFilters
      description: $description
      detailedInfo: $detailedInfo
      nextTracks: $nextTracks
      files: $files
    ) {
      ...trackFragment
      ...trackMediaFragment
    }
  }
  ${TRACK_FRAGMENT}
  ${TRACK_MEDIA_FRAGMENT}
`;
