import { gql } from "@apollo/client";

const USER_TRANSPORT_FRAGMENT = gql`
  fragment transportFragment on UserTransport {
    id
    name
    area
    category
    typology
    primaryService
    localities
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
    }
    geoLocation {
      size
      label
      color
      coordinatesMultiLineString
      coordinatesPoint
      coordinatesLineString
    }
    specialPOIs {
      name
      primaryService
      description {
        short {
          it
          en
          de
        }
        long {
          it
          en
          de
        }
      }
      altimetry {
        highestPoint
      }
      geoLocation {
        coordinatesMultiLineString
        coordinatesLineString
        coordinatesPoint
      }
      detailedInfo {
        tagServices
        isForDisabled
      }
    }
    detailedInfo {
      locality {
        it
        en
        de
      }
      stations {
        start {
          name {
            it
            en
            de
          }
          altitude
        }
        end {
          name {
            it
            en
            de
          }
          altitude
        }
      }
      isForPedestrians
      isForDisabled
      isForSnowscoot
      importance
      tagServices
    }
    externalInfo {
      externalId
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const USER_TRANSPORT_MEDIA_FRAGMENT = gql`
  fragment transportMediaFragment on UserTransport {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

const TRANSPORT_FRAGMENT = gql`
  fragment transportFragment on Transport {
    id
    name
    area
    category
    typology
    primaryService
    localities
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    specialPOIs {
      name
      primaryService
      description {
        short {
          it
          en
          de
        }
        long {
          it
          en
          de
        }
      }
      altimetry {
        highestPoint
      }
      geoLocation {
        coordinatesMultiLineString
        coordinatesLineString
        coordinatesPoint
      }
      detailedInfo {
        tagServices
        isForDisabled
      }
    }
    detailedInfo {
      locality {
        it
        en
        de
      }
      stations {
        start {
          name {
            it
            en
            de
          }
          altitude
        }
        end {
          name {
            it
            en
            de
          }
          altitude
        }
      }
      isForPedestrians
      isForDisabled
      isForSnowscoot
      importance
      tagServices
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const TRANSPORT_MEDIA_FRAGMENT = gql`
  fragment transportMediaFragment on Transport {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

export const AREA_TRANSPORTS = gql`
  query AREA_TRANSPORTS($area: ID!) {
    userTransports(area: $area) {
      ...transportFragment
    }
  }
  ${USER_TRANSPORT_FRAGMENT}
`;

export const TRANSPORT = gql`
  query TRANSPORT($id: ID!) {
    userTransport(id: $id) {
      ...transportFragment
      ...transportMediaFragment
    }
  }
  ${USER_TRANSPORT_FRAGMENT}
  ${USER_TRANSPORT_MEDIA_FRAGMENT}
`;

export const TRANSPORT_UPDATE = gql`
  mutation TRANSPORT_UPDATE(
    $id: ID!
    $name: String!
    $area: ID!
    $category: String
    $originalStatus: Boolean
    $typology: String
    $localities: [String]
    $tagFilters: [String]
    $primaryService: String
    $description: TransportDescriptionInput
    $media: TransportMediaInput
    $geoLocation: TransportGeoLocationInput
    $specialPOIs: [TransportSpecialPoiInput]
    $detailedInfo: TransportDetailedInfoInput
    $externalInfo: TransportExternalInfoInput
    $files: [StorageFileInput]
  ) {
    updateTransport(
      id: $id
      name: $name
      area: $area
      category: $category
      originalStatus: $originalStatus
      typology: $typology
      localities: $localities
      tagFilters: $tagFilters
      primaryService: $primaryService
      description: $description
      media: $media
      geoLocation: $geoLocation
      specialPOIs: $specialPOIs
      detailedInfo: $detailedInfo
      externalInfo: $externalInfo
      files: $files
    ) {
      ...transportFragment
    }
  }
  ${TRANSPORT_FRAGMENT}
`;

export const TRANSPORT_CREATE = gql`
  mutation TRANSPORT_CREATE(
    $name: String!
    $area: ID!
    $category: String
    $originalStatus: Boolean
    $typology: String
    $localities: [String]
    $tagFilters: [String]
    $primaryService: String
    $description: TransportDescriptionInput
    $media: TransportMediaInput
    $geoLocation: TransportGeoLocationInput
    $specialPOIs: [TransportSpecialPoiInput]
    $detailedInfo: TransportDetailedInfoInput
    $externalInfo: TransportExternalInfoInput
    $files: [StorageFileInput]
  ) {
    createTransport(
      name: $name
      area: $area
      category: $category
      originalStatus: $originalStatus
      typology: $typology
      localities: $localities
      tagFilters: $tagFilters
      primaryService: $primaryService
      description: $description
      media: $media
      geoLocation: $geoLocation
      specialPOIs: $specialPOIs
      detailedInfo: $detailedInfo
      externalInfo: $externalInfo
      files: $files
    ) {
      ...transportFragment
      ...transportMediaFragment
    }
  }
  ${TRANSPORT_FRAGMENT}
  ${TRANSPORT_MEDIA_FRAGMENT}
`;

export const TRANSPORT_DELETE = gql`
  mutation TRANSPORT_DELETE($id: ID!) {
    deleteTransport(id: $id) {
      id
    }
  }
`;
