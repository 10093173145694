import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from "@chakra-ui/react";

interface ScopeSelectPopupProps {
  onSelect: (scope: string) => void;
  onClose: () => void;
  isOpen: boolean;
}
const ScopeSelectPopup = ({
  onSelect,
  onClose,
  isOpen,
}: ScopeSelectPopupProps) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Input
            textTransform="uppercase"
            placeholder="Write the scope"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSelect(e.currentTarget.value);
                e.currentTarget.value = "";
              }
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ScopeSelectPopup;
