import { Button, Flex, Image, Spinner, Text, useToast } from "@chakra-ui/react";
import { ADMIN_ADD_FILES } from "apollo/queries/auth";
import { FormCard } from "components/layout/FormBuilderV2";
import useCoolMutation from "hooks/useCoolMutation";
import { useEffect, useState } from "react";
import { useFilePicker } from "use-file-picker";
import urlToFile from "utils/urlToFile";

interface UpdateAreaAssetsProps {
  onLogoChange: (url?: { url: string; _id: string }) => void;
  onCoverChange: (url?: { url: string; _id: string }) => void;
  onInsert: (value: string) => void;
  onRemove: (value: string) => void;
  logo?: { url: string; _id: string };
  cover?: { url: string; _id: string };
}

const UpdateAreaAssets = ({
  logo,
  cover,
  onCoverChange,
  onLogoChange,
  onInsert,
  onRemove,
}: UpdateAreaAssetsProps) => {
  const toast = useToast();
  const [mode, setMode] = useState<"logo" | "cover">();
  const [uploading, setUploading] = useState(false);
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  const [uploadMedia] = useCoolMutation<any, AdminAddFiles>(ADMIN_ADD_FILES, {
    error: (e) => {
      toast({ title: "Error", status: "error", description: e });
      setUploading(false);
    },
    hasUpload: true,
  });

  const handleUpload = async () => {
    setUploading(true);
    const file = await urlToFile(filesContent[0].content, "12-image");

    const { data } = await uploadMedia({
      variables: {
        media: [file],
        scope: "areas",
      },
    });

    if (mode === "cover") {
      const url = data?.AdminAddFiles?.[0].url ?? "";
      const _id = data?.AdminAddFiles?.[0]._id ?? "";
      onRemove(cover?._id ?? "");
      onCoverChange({ _id, url });
      onInsert(_id);
    } else {
      const url = data?.AdminAddFiles?.[0].url ?? "";
      const _id = data?.AdminAddFiles?.[0]._id ?? "";
      onRemove(logo?._id ?? "");
      onLogoChange({ _id, url });
      onInsert(_id);
    }

    setUploading(false);
  };

  useEffect(() => {
    if (!filesContent.length) return;
    handleUpload();
  }, [filesContent]);

  return (
    <FormCard>
      <Flex gap="8px" flexDirection="column" w="100%">
        <Flex alignItems="center" w="100%">
          <Text fontWeight="bold" fontSize="18px">
            Area Cover
          </Text>
          <Button
            ms="32px"
            colorScheme="red"
            size="xs"
            variant="link"
            onClick={() => {
              onRemove(cover?._id ?? "");
              onCoverChange(undefined);
            }}
          >
            Remove
          </Button>
        </Flex>
        <Flex position="relative" justifyContent="center">
          <Image
            borderRadius="16px"
            src={cover?.url ?? "/imgs/areaPlaceholder.png"}
            w="100%"
            backgroundImage={"/imgs/transparent_bg.png"}
            fallbackSrc="/imgs/areaPlaceholder.png"
            h="200px"
            objectFit="cover"
            cursor="pointer"
            border="1px solid #ddd"
            onClick={() => {
              setMode("cover");
              openFileSelector();
            }}
          />
          {uploading && mode === "cover" && (
            <Spinner position="absolute" left="48%" top="40%" />
          )}
        </Flex>
      </Flex>
      <Flex gap="8px" flexDirection="column" w="100%">
        <Flex alignItems="center" w="100%">
          <Text fontWeight="bold" fontSize="18px">
            Area Logo
          </Text>
          <Button
            ms="32px"
            colorScheme="red"
            size="xs"
            variant="link"
            onClick={() => {
              onRemove(logo?._id ?? "");
              onLogoChange(undefined);
            }}
          >
            Remove
          </Button>
        </Flex>
        <Flex position="relative" justifyContent="center">
          <Image
            borderRadius="16px"
            w="100%"
            backgroundImage={"/imgs/transparent_bg.png"}
            h="200px"
            src={logo?.url}
            objectFit="contain"
            border="1px solid #ddd"
            cursor="pointer"
            onClick={() => {
              setMode("logo");
              openFileSelector();
            }}
          />
          {uploading && mode === "logo" && (
            <Spinner position="absolute" left="48%" top="40%" />
          )}
        </Flex>
      </Flex>
    </FormCard>
  );
};

export default UpdateAreaAssets;
