import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import Navigation from "components/navigation";
import { Behavior } from "behave-react";
import Store from "../../store";
import { ApolloProvider } from "@apollo/client";
import client from "apollo/client";

const App = () => {
  return (
    <Behavior store={Store.store} persistor={Store.persistor}>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <Navigation />
          </BrowserRouter>
        </ChakraProvider>
      </ApolloProvider>
    </Behavior>
  );
};

export default App;
