import { useBehave } from "behave-react";
import { SessionSelect } from "store/modules/session";
import Authorized from "./Authorized";
import Unauthorized from "./Unauthorized";

const Navigation = () => {
  const { select } = useBehave();
  const authenticated = select(SessionSelect.authenticated);
  return authenticated ? <Authorized /> : <Unauthorized />;
};

export default Navigation;
