import {
  Tabs,
  TabList,
  Tab,
  Button,
  useToast,
  Divider,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import useCoolMutation from "hooks/useCoolMutation";

import { useEffect, useState } from "react";

import { SUGGESTER_PERIOD, TRACK_ORIGIN, YES_NO_OPTIONAL } from "utils/DataMap";
import { useNavigate, useParams } from "react-router-dom";

import GeoLocation from "components/layout/GeoLocation";
import {
  TRACK,
  TRACK_CREATE,
  TRACK_DELETE,
  TRACK_UPDATE,
} from "apollo/queries/tracks";
import useCoolQuery from "hooks/useCoolQuery";
import MediaSelector from "components/layout/MediaSelector";
import CleanPayload from "utils/CleanPayload";
import useAreaBundle from "hooks/useAreaBundle";
import {
  FormBuilderV2,
  FormCard,
  Field,
  FormRow,
} from "components/layout/FormBuilderV2";

const TypedField = Field<Track>;

interface Props {
  isEdit?: boolean;
}

const CreateUpdateTrack = ({ isEdit }: Props) => {
  const toast = useToast();

  const { pid, id } = useParams();
  const [category, setCategory] = useState<string>();
  const bundleTracks = useAreaBundle(id ?? "", "track");
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState<any>();
  const [coordinateType, setCoordinateType] = useState<any>();
  const [selectedBundle, setSelectedBundle] = useState<any>();

  const [fileToInsert, setFilesToInsert] = useState<string[]>([]);
  const [fileToRemove, setFilesToRemove] = useState<string[]>([]);

  const [media, setMedia] = useState<Partial<Track["media"]>>();

  const { data: dataTrack, loading: loadingFetch } = useCoolQuery<
    any,
    GetTrackResponse
  >(TRACK, { id: pid }, { disableFetchOnLoad: !isEdit });

  const [createUpdate, { loading }] = useCoolMutation(
    isEdit ? TRACK_UPDATE : TRACK_CREATE,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: isEdit
            ? "Track updated succesfully"
            : "Track created succesfully",
        });
        !isEdit && navigate("/areas/" + id + "/tracks");
      },
    }
  );

  const [deleteTrack, { loading: loadingDelete }] = useCoolMutation(
    TRACK_DELETE,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: "Track deleted succesfully",
        });
        navigate("/areas/" + id + "/tracks");
      },
    }
  );

  const handleCreate = (data: Track) => {
    const variables = CleanPayload(data);

    const parsedCoordinates = JSON.parse(coordinates ?? "[]");

    variables.media = {
      cover: { url: media?.cover?.url, _id: media?.cover?._id },
      pictures: media?.pictures?.map((pic) => ({
        url: pic.url,
        _id: pic._id,
      })),
      videos: data.media?.videos?.map((pic) => ({
        url: pic.url,
      })),
    };
    variables.category = category;
    variables.area = id;
    variables.geoLocation = {
      [coordinateType === "Point"
        ? "coordinatesPoint"
        : coordinateType === "LineString"
        ? "coordinatesLineString"
        : "coordinatesMultiLineString"]: parsedCoordinates,
    };
    const files = [
      ...fileToInsert
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "insert" })),
      ...fileToRemove
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "remove" })),
    ];
    variables.files = files;
    createUpdate({ variables });
  };

  useEffect(() => {
    setCoordinates(
      JSON.stringify(
        dataTrack?.userTrack.geoLocation.coordinatesLineString?.length
          ? dataTrack?.userTrack.geoLocation.coordinatesLineString
          : dataTrack?.userTrack.geoLocation.coordinatesMultiLineString?.length
          ? dataTrack?.userTrack.geoLocation.coordinatesMultiLineString
          : dataTrack?.userTrack.geoLocation.coordinatesPoint
      )
    );
    setCoordinateType(
      dataTrack?.userTrack.geoLocation.coordinatesLineString?.length
        ? "LineString"
        : dataTrack?.userTrack.geoLocation.coordinatesMultiLineString?.length
        ? "MultiLineString"
        : "Point"
    );
    setMedia(dataTrack?.userTrack?.media);
  }, [dataTrack]);

  useEffect(() => {
    const cate = dataTrack?.userTrack.category ?? bundleTracks?.[0]?.name;

    setCategory(cate);
    setSelectedBundle(
      bundleTracks?.find((a) => a.name === cate) ?? bundleTracks?.[0]
    );
  }, [bundleTracks, dataTrack?.userTrack]);
  useEffect(() => {
    setSelectedBundle(bundleTracks?.find((a) => a.name === category));
  }, [category]);

  return (
    <FormBuilderV2
      loading={loadingFetch}
      editMode={isEdit}
      initialValues={dataTrack?.userTrack}
      submit={{
        action: isEdit ? "Update" : "Create",
        color: isEdit ? "purple" : "green",
        loading,
        onSubmit: handleCreate,
        title: isEdit ? "Update Track?" : "Create Track?",
        subtitle: isEdit
          ? "This will update the Track"
          : "This will create a new Track in the current area.",
      }}
    >
      <FormCard title="Track summary">
        <TypedField id="name" type="text" label="Name" required />
        <TypedField
          type="select"
          label="Category"
          value={category}
          onChange={(v) => setCategory(v.target.value)}
          options={bundleTracks?.map((a) => ({
            name: a.name,
            value: a.name,
          }))}
          required
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="select"
          id="typology"
          label="Typology"
          options={selectedBundle?.typologies.map((a: any) => ({
            name: a.name,
            value: a.name,
          }))}
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="tags"
          id="localities"
          label="Localities"
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
          options={[
            { name: "", value: undefined },
            ...(selectedBundle?.localities.map((a: any) => ({
              name: a.name,
              value: a.name,
            })) ?? []),
          ]}
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="select"
          id="tagFilters"
          label="TagFilters"
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
          options={[
            { name: "", value: undefined },
            ...(selectedBundle?.tagFilters.map((a: any) => ({
              name: a.name,
              value: a.name,
            })) ?? []),
          ]}
        />
        <TypedField id="originalStatus" type="switch" label="Original status" />
        <TypedField
          id="externalInfo.externalId"
          type="text"
          label="External Id"
        />
      </FormCard>
      <GeoLocation
        type={coordinateType}
        coordinates={coordinates}
        onChange={(d, v) => {
          setCoordinateType(d);
          setCoordinates(v);
        }}
      />
      <FormCard title="Technical Info">
        <TypedField id="technicalInfo.length" type="number" label="Lenght" />
        <TypedField
          id="technicalInfo.maxSlope"
          type="number"
          label="Max Slope"
        />
        <TypedField
          id="technicalInfo.altimetry.highestPoint"
          type="number"
          label="Altimetry Highest Point"
        />
        <TypedField
          id="technicalInfo.altimetry.lowestPoint"
          type="number"
          label="Altimetry Lowest Point"
        />
        <TypedField
          id="technicalInfo.altimetry.differencePlus"
          type="number"
          label="Altimetry difference plus"
        />
        <TypedField
          id="technicalInfo.altimetry.differenceMinus"
          type="number"
          label="Altimetry difference minus"
        />
      </FormCard>
      <FormCard>
        <Tabs>
          <TabList>
            <Tab>Italian</Tab>
            <Tab>English</Tab>
            <Tab>Deutsch</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Station start name IT"
                id="detailedInfo.stations.start.name.it"
              />
              <TypedField
                type="text"
                label="Station end name IT"
                id="detailedInfo.stations.end.name.it"
              />
              <TypedField
                type="textArea"
                label="Description Long IT"
                id="description.long.it"
              />
              <TypedField
                type="text"
                label="Description Short IT"
                id="description.short.it"
              />
              <TypedField
                type="textArea"
                label="Locality IT"
                id="detailedInfo.locality.it"
              />
              <TypedField
                type="text"
                label="How to start IT"
                id="description.howToStart.it"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Station start name EN"
                id="detailedInfo.stations.start.name.en"
              />
              <TypedField
                type="text"
                label="Station end name EN"
                id="detailedInfo.stations.end.name.en"
              />
              <TypedField
                type="textArea"
                label="Description Long EN"
                id="description.long.en"
              />
              <TypedField
                type="text"
                label="Description Short EN"
                id="description.short.en"
              />
              <TypedField
                type="textArea"
                label="Locality EN"
                id="detailedInfo.locality.en"
              />
              <TypedField
                type="text"
                label="How to start EN"
                id="description.howToStart.en"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Station start name DE"
                id="detailedInfo.stations.start.name.de"
              />
              <TypedField
                type="text"
                label="Station end name DE"
                id="detailedInfo.stations.end.name.de"
              />
              <TypedField
                type="textArea"
                label="Description Long DE"
                id="description.long.de"
              />
              <TypedField
                type="text"
                label="Description Short DE"
                id="description.short.de"
              />
              <TypedField
                type="textArea"
                label="Locality DE"
                id="detailedInfo.locality.de"
              />
              <TypedField
                type="text"
                label="How to start DE"
                id="description.howToStart.de"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormCard>
      <FormCard title="Detailed infos">
        <TypedField
          id="detailedInfo.trackNumber"
          type="text"
          label="Track number"
        />
        <FormRow>
          <TypedField
            id="detailedInfo.stations.start.altitude"
            type="number"
            label="Start altitude"
            rightAddon="mt"
          />
          <TypedField
            id="detailedInfo.stations.end.altitude"
            type="number"
            label="End altitude"
            rightAddon="mt"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="detailedInfo.timeAvarage"
            type="number"
            label="Time average"
          />
          <TypedField
            id="detailedInfo.physicalEfford"
            type="number"
            label="Physical effort"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="detailedInfo.technicalAbility"
            type="number"
            label="Technical ability"
          />
          <TypedField id="detailedInfo.emotion" type="number" label="Emotion" />
        </FormRow>
        <FormRow>
          <TypedField
            id="detailedInfo.landscape"
            type="number"
            label="Landscape"
          />
          <TypedField id="detailedInfo.level" type="number" label="Level" />
        </FormRow>
        <TypedField
          label="Suggested period"
          id="detailedInfo.suggestedPeriod"
          transform="strArrayToInt"
          type="tags"
          options={SUGGESTER_PERIOD}
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
        />

        <FormRow>
          <TypedField
            id="detailedInfo.origin"
            type="select"
            label="Origin"
            options={TRACK_ORIGIN}
          />
          <TypedField
            id="detailedInfo.zone.name"
            type="text"
            label="Zone name"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="detailedInfo.isCircular"
            type="switch"
            label="Is Circular?"
          />
          <TypedField
            id="detailedInfo.isForPedestrians"
            type="switch"
            label="Is for pedestrian?"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="detailedInfo.isForFamily"
            type="select"
            transform="strToBool"
            label="For family"
            options={YES_NO_OPTIONAL}
          />
        </FormRow>
      </FormCard>
      <FormCard title="Geo Location ">
        <TypedField type="text" label="Label" id="geoLocation.label" />
        <TypedField
          type="text"
          label="Color"
          id="geoLocation.color"
          placeholder="#fff"
        />
        <TypedField type="number" label="Size" id="geoLocation.size" />
        <TypedField
          type="switch"
          label="Hide if not selected"
          id="geoLocation.hideIfNotSelected"
        />
      </FormCard>

      <MediaSelector
        type="tracks"
        values={media}
        onInsert={(id) => setFilesToInsert((a) => [...a, id])}
        onRemove={(id) => setFilesToRemove((a) => [...a, id])}
        onChange={setMedia}
      />

      <FormCard
        hide={!isEdit}
        title="Delete Track"
        subtitle="Do you want to delete this Track?"
        rightAction={
          <Button
            variant="outline"
            isLoading={loadingDelete}
            colorScheme="red"
            onClick={() => deleteTrack({ variables: { id: pid } })}
          >
            Delete
          </Button>
        }
      />
    </FormBuilderV2>
  );
};

export default CreateUpdateTrack;
