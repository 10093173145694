import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  List,
  ListItem,
  Icon,
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { FaCircle } from "react-icons/fa";
import { NavRoute } from "routes";

interface SidebarLinksProps {
  routes: NavRoute[];
}

export function SidebarLinks({ routes }: SidebarLinksProps) {
  const location = useLocation();

  const id = location.pathname.split("/")[2];

  const activeRoute = (routeName: string) => {
    return location?.pathname.match(routeName.replace(":id", ".{1,}") + "$") 
      ? "brand.500"
      : "secondaryGray.600";
  };

  const createLinks = (routes: NavRoute[]) => {
    return routes.map((route, key) => {
      if (route.hideFromSidebar) return null;
      return (
        <NavLink to={route.path.replace(":id", id)} key={key}>
          {route.icon ? (
            <Flex
              align="center"
              justifyContent="space-between"
              w="100%"
              ps="17px"
              mb="0px"
              ml={!route.submenu ? "0px" : "24px"}
            >
              <HStack
                mb="6px"
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Icon
                    as={route.icon}
                    width="20px"
                    height="20px"
                    me="12px"
                    color={activeRoute(route.path.toLowerCase())}
                  />
                  <Text
                    me="auto"
                    color={activeRoute(route.path.toLowerCase())}
                    fontWeight="500"
                  >
                    {route.name}
                  </Text>
                </Flex>
              </HStack>
            </Flex>
          ) : (
            <ListItem>
              <Flex ps="34px" alignItems="center" mb="8px">
                <Text
                  color={activeRoute(route.path.toLowerCase())}
                  fontWeight="500"
                  fontSize="sm"
                >
                  {route.name}
                </Text>
              </Flex>
            </ListItem>
          )}
        </NavLink>
      );
    });
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
