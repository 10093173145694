import React from "react";
import {
  Box,
  BoxProps,
  Flex,
  FormLabel,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormFieldComponent } from "../types";

interface SwitchFieldProps extends BoxProps {
  id?: string;
  label?: string;
  reversed?: boolean;
  formRef?: any;
  description?: string;
  disabled?: boolean;
  isChecked?: boolean;
}

const SwitchField = ({
  id,
  label,
  formRef,
  description,
  disabled,
  value,
  reversed,
  isChecked,
  onChange,
}: FormFieldComponent<any>) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  return (
    <Box w="100%" fontWeight="500">
      {reversed ? (
        <Flex align="center" borderRadius="16px">
          <Switch
            id={id}
            isDisabled={disabled}
            variant="main"
            colorScheme="brandScheme"
            size="md"
            isChecked={value}
            onChange={onChange}
            {...formRef}
          />

          <FormLabel
            ms="15px"
            htmlFor={id}
            _hover={{ cursor: "pointer" }}
            flexDirection="column"
            mb="0px"
            maxW="75%"
          >
            <Text color={textColorPrimary} fontSize="md" fontWeight="500">
              {label}
            </Text>
            <Text color="secondaryGray.600" fontSize="md">
              {description}
            </Text>
          </FormLabel>
        </Flex>
      ) : (
        <Flex justify="space-between" align="center" borderRadius="16px">
          <FormLabel
            htmlFor={id}
            _hover={{ cursor: "pointer" }}
            flexDirection="column"
            maxW="75%"
          >
            <Text color={textColorPrimary} fontSize="md" fontWeight="500">
              {label}
            </Text>
            <Text color="secondaryGray.600" fontSize="md">
              {description}
            </Text>
          </FormLabel>

          <Switch
            id={id}
            variant="main"
            isDisabled={disabled}
            isChecked={isChecked}
            onChange={onChange}
            colorScheme="brandScheme"
            size="md"
            {...formRef}
          />
        </Flex>
      )}
    </Box>
  );
};
export default SwitchField;
