import {
  Tabs,
  TabList,
  Tab,
  Divider,
  Button,
  useToast,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import useCoolMutation from "hooks/useCoolMutation";

import { useEffect, useState } from "react";

import { PRIMARY_SERVICES } from "utils/DataMap";
import { useNavigate, useParams } from "react-router-dom";

import GeoLocation from "components/layout/GeoLocation";
import { POI, POI_CREATE, POI_DELETE, POI_UPDATE } from "apollo/queries/pois";
import useCoolQuery from "hooks/useCoolQuery";
import MediaSelector from "components/layout/MediaSelector";
import CleanPayload from "utils/CleanPayload";
import useAreaBundle from "hooks/useAreaBundle";

import {
  Field,
  FormBuilderV2,
  FormCard,
  FormRow,
} from "components/layout/FormBuilderV2";

const TypedField = Field<Poi>;

interface Props {
  isEdit?: boolean;
}

const CreateUpdatePois = ({ isEdit }: Props) => {
  const toast = useToast();

  const { pid, id } = useParams();

  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState<any>();
  const [coordinateType, setCoordinateType] = useState<any>();
  const [category, setCategory] = useState<string>();
  const [selectedBundle, setSelectedBundle] = useState<any>();
  const bundlePois = useAreaBundle(id ?? "", "poi");
  const { data: dataPoi, loading: loadingFetch } = useCoolQuery<
    any,
    GetPoiResponse
  >(POI, { id: pid }, { disableFetchOnLoad: !isEdit });
  const [fileToInsert, setFilesToInsert] = useState<string[]>([]);
  const [fileToRemove, setFilesToRemove] = useState<string[]>([]);

  const [media, setMedia] = useState<Partial<Poi["media"]>>();
  const [createUpdate, { loading }] = useCoolMutation(
    isEdit ? POI_UPDATE : POI_CREATE,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: isEdit
            ? "Poi updated succesfully"
            : "Poi created succesfully",
        });
        !isEdit && navigate("/areas/" + id + "/pois");
      },
    }
  );

  const [deletePoi, { loading: loadingDelete }] = useCoolMutation(
    POI_DELETE,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: "Poi deleted succesfully",
        });
        navigate("/areas/" + id + "/pois");
      },
    }
  );

  const handleCreate = (data: Poi) => {
    const variables = CleanPayload(data);

    variables.media = {
      cover: {
        url: data.media?.cover?.url,
      },
      pictures: data.media?.pictures?.map((pic) => ({
        url: pic.url,
      })),
      videos: data.media?.videos?.map((pic) => ({
        url: pic.url,
      })),
    };

    const parsedCoordinates = JSON.parse(coordinates ?? "[]");
    console.log(parsedCoordinates);
    variables.media = {
      cover: { url: media?.cover?.url, _id: media?.cover?._id },
      pictures: media?.pictures?.map((pic) => ({
        url: pic.url,
        _id: pic._id,
      })),
      videos: data.media?.videos?.map((pic) => ({
        url: pic.url,
      })),
    };
    variables.category = category;
    variables.area = id;

    variables.geoLocation = {
      [coordinateType === "Point"
        ? "coordinatesPoint"
        : coordinateType === "LineString"
        ? "coordinatesLineString"
        : "coordinatesMultiLineString"]: parsedCoordinates,
    };
    const files = [
      ...fileToInsert
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "insert" })),
      ...fileToRemove
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "remove" })),
    ];
    variables.files = files;
    createUpdate({ variables });
  };

  useEffect(() => {
    setCoordinates(
      JSON.stringify(
        dataPoi?.userPoi.geoLocation.coordinatesLineString?.length
          ? dataPoi?.userPoi.geoLocation.coordinatesLineString
          : dataPoi?.userPoi.geoLocation.coordinatesMultiLineString?.length
          ? dataPoi?.userPoi.geoLocation.coordinatesMultiLineString
          : dataPoi?.userPoi.geoLocation.coordinatesPoint
      )
    );
    setCoordinateType(
      dataPoi?.userPoi.geoLocation.coordinatesLineString?.length
        ? "LineString"
        : dataPoi?.userPoi.geoLocation.coordinatesMultiLineString?.length
        ? "MultiLineString"
        : "Point"
    );
    setMedia(dataPoi?.userPoi?.media);
  }, [dataPoi]);

  useEffect(() => {
    const cate = dataPoi?.userPoi.category ?? bundlePois?.[0]?.name;
    setCategory(cate);
    setSelectedBundle(
      bundlePois?.find((a) => a.name === cate) ?? bundlePois?.[0]
    );
  }, [bundlePois]);

  useEffect(() => {
    setSelectedBundle(bundlePois?.find((a) => a.name === category));
  }, [category]);

  return (
    <FormBuilderV2
      loading={loadingFetch}
      editMode={isEdit}
      initialValues={dataPoi?.userPoi}
      submit={{
        action: isEdit ? "Update" : "Create",
        color: isEdit ? "purple" : "green",
        loading,
        onSubmit: handleCreate,
        title: isEdit ? "Update Poi?" : "Create poi?",
        subtitle: isEdit
          ? "This will update the poi"
          : "This will create a new poi in the current area.",
      }}
    >
      <FormCard title="Poi summary">
        <TypedField id="name" type="text" label="Name" required />
        <TypedField
          type="select"
          id="primaryService"
          label="Primary services"
          options={PRIMARY_SERVICES}
          required
        />
        <TypedField
          type="select"
          label="Category"
          onChange={(v) => setCategory(v.target.value)}
          options={bundlePois?.map((a) => ({
            name: a.name,
            value: a.name,
          }))}
          required
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="select"
          id="typology"
          label="Typology"
          options={selectedBundle?.typologies.map((a: any) => ({
            name: a.name,
            value: a.name,
          }))}
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="tags"
          id="localities"
          label="Localities"
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
          options={[
            { name: "", value: undefined },
            ...(selectedBundle?.localities.map((a: any) => ({
              name: a.name,
              value: a.name,
            })) ?? []),
          ]}
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="tags"
          id="tagFilters"
          label="TagFilters"
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
          options={[
            { name: "", value: undefined },
            ...(selectedBundle?.tagFilters.map((a: any) => ({
              name: a.name,
              value: a.name,
            })) ?? []),
          ]}
        />
        <TypedField
          id="technicalInfo.altimetry.highestPoint"
          type="number"
          label="Altimetry heighest point"
        />
        <TypedField id="originalStatus" type="switch" label="Original status" />
      </FormCard>

      <GeoLocation
        key="poi.geolocalization"
        type={coordinateType}
        coordinates={coordinates}
        onChange={(d, v) => {
          setCoordinateType(d);
          setCoordinates(v);
        }}
      />

      <FormCard>
        <Tabs>
          <TabList>
            <Tab>Italian</Tab>
            <Tab>English</Tab>
            <Tab>Deutsch</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="textArea"
                label="Description Long IT"
                id="description.long.it"
              />
              <TypedField
                type="text"
                label="Description Short IT"
                id="description.short.it"
              />
              <TypedField
                type="textArea"
                label="Locality IT"
                id="detailedInfo.locality.it"
              />
            </TabPanel>

            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="textArea"
                label="Description Long EN"
                id="description.long.en"
              />
              <TypedField
                type="text"
                label="Description Short EN"
                id="description.short.en"
              />
              <TypedField
                type="textArea"
                label="Locality EN"
                id="detailedInfo.locality.en"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="textArea"
                label="Description Long DE"
                id="description.long.de"
              />
              <TypedField
                type="text"
                label="Description Short DE"
                id="description.short.de"
              />
              <TypedField
                type="textArea"
                label="Locality DE"
                id="detailedInfo.locality.de"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Divider />
        <TypedField
          id="detailedInfo.importance"
          type="number"
          label="Importance"
        />
        <TypedField
          id="detailedInfo.tagServices"
          type="tags"
          label="Tag services"
          options={PRIMARY_SERVICES}
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
        />
      </FormCard>

      <FormCard title="Geo Location ">
        <TypedField type="text" label="Label" id="geoLocation.label" />
        <TypedField
          type="text"
          label="Color"
          id="geoLocation.color"
          placeholder="#fff"
        />
        <TypedField type="number" label="Size" id="geoLocation.size" />
        <TypedField
          type="switch"
          label="Hide if not selected"
          id="geoLocation.hideIfNotSelected"
        />
      </FormCard>

      <FormCard title="Contacts">
        <FormRow>
          <TypedField id="contact.phone" type="phone" label="Phone" />
          <TypedField id="contact.address" type="text" label="Address" />
        </FormRow>
        <FormRow>
          <TypedField
            id="contact.social.facebookUrl"
            type="text"
            label="Facebook"
          />
          <TypedField
            id="contact.social.instagramUrl"
            type="text"
            label="Instagram"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="contact.social.youtubeUrl"
            type="text"
            label="Youtube"
          />
          <TypedField
            id="contact.social.websiteUrl"
            type="text"
            label="Website"
          />
        </FormRow>

        <TypedField
          id="contact.social.whatsappNumber"
          type="text"
          label="Whatsapp"
        />
      </FormCard>
      <MediaSelector
        type="pois"
        values={media}
        onInsert={(id) => setFilesToInsert((a) => [...a, id])}
        onRemove={(id) => setFilesToRemove((a) => [...a, id])}
        onChange={setMedia}
      />

      <FormCard
        hide={!isEdit}
        title="Delete POI"
        subtitle="Do you want to delete this POI?"
        rightAction={
          <Button
            variant="outline"
            isLoading={loadingDelete}
            colorScheme="red"
            onClick={() => deletePoi({ variables: { id: pid } })}
          >
            Delete
          </Button>
        }
      />
    </FormBuilderV2>
  );
};

export default CreateUpdatePois;
