import { useToast } from "@chakra-ui/react";
import { ApolloConfig } from "apollo/config";
import { REFRESH_TOKEN } from "apollo/queries/auth";
import { useBehave } from "behave-react";
import { useEffect } from "react";
import { SessionAction, SessionSelect } from "store/modules/session";
import parseJwt from "utils/JWTParse";
import useCoolMutation from "./useCoolMutation";

const useRefreshToken = (
  cb?: () => void,
  area?: string,
  disabled?: boolean
) => {
  const toast = useToast();

  const { select, dispatch } = useBehave();
  const authenticated = select(SessionSelect.authenticated);
  const refreshToken = select(SessionSelect.refreshToken);
  const accessToken = select(SessionSelect.accessToken);

  const [refresh, { loading }] = useCoolMutation<
    RefreshTokenResponse,
    RefreshToken
  >(REFRESH_TOKEN, {
    clientName: "auth",
    error: (e) => handleResponse(e),
    success: (_d) => handleResponse(_d),
  });

  const handleResponse = (_d: any) => {
    if (_d?.refreshToken?.accessToken) {
      dispatch(SessionAction.authenticate({ loginUser: _d.refreshToken }));
      cb?.();
    } else {
      toast({
        title: "Error refreshing token, login again",
        status: "error",
        position: "bottom-right",
        duration: 60,
      });
      localStorage.clear();
      dispatch(SessionAction.purge());
    }
  };

  const onFocus = () => {
    if (!validateToken() && refreshToken && !loading) {
      refresh({
        variables: {
          refreshToken,
          application: ApolloConfig.application,
          area,
        },
      });
    }
  };

  const validateToken = () => {
    const JWT = parseJwt(accessToken ?? "");

    return (
      JWT?.auth?.area === area && JWT.exp * 1000 > Date.now() // Looks like the token is invalid even if it's not expired
    );
  };
  const trigger = (_area?: string, force?: boolean) => {
    if (disabled && !force) return;

    if (!validateToken() && refreshToken && !loading) {
      refresh({
        variables: {
          refreshToken,
          application: ApolloConfig.application,
          area: _area ?? area,
        },
      });
    } else if (authenticated) {
      window.addEventListener("focus", onFocus);
      onFocus();
      return () => {
        window.removeEventListener("focus", onFocus);
      };
    }
  };
  useEffect(() => {
    trigger();
  }, [area, disabled]);

  return { authenticated, loading, trigger };
};

export default useRefreshToken;
