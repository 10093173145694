/* eslint-disable */

const CleanPayload: any = (obj: any) => {
  const o1 = Clean(obj);
  const o2 = Clean(o1);
  const o3 = Clean(o2);
  const o4 = Clean(o3);
  const o5 = Clean(o4);

  return Clean(o5);
};
const Clean: any = (obj: any) => {
  if (!obj) return obj;
  if (Array.isArray(obj)) return obj.map((p) => Clean(p));
  else if (typeof obj === "object")
    return Object.entries(obj)
      .filter(([k, v]) => {
        if (k.startsWith("__")) return false;
        if (v && typeof v === "object") return Object.keys(v as any).length > 0;
        else return ![undefined, null, "", NaN].includes(v as any);
      })
      .reduce((r, [key, value]) => ({ ...r, [key]: Clean(value) }), {});
  else return obj;
};

export default CleanPayload;
