import {
  Box,
  Flex,
  Input,
  InputGroup,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApolloConfig } from "apollo/config";
import { useBehave } from "behave-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AreasSelect } from "store/modules/areas";

export function SearchBar() {
  const { select } = useBehave();
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const [searching, setSearching] = useState(false);
  const areas = select(AreasSelect.areas);
  const [filteredAreas, setFilteredAreas] = useState<Area[]>([]);
  const navigate = useNavigate();
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearching(!!e.target?.value?.length);
    if (!e.target?.value?.length) setFilteredAreas([]);
    else
      setFilteredAreas(
        areas.filter(
          (a) =>
            a.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            a.id.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
  };

  return (
    <InputGroup
      w={{ base: "100%", md: "250px" }}
      position="relative"
      overflow="visible"
    >
      <Input
        variant="search"
        fontSize="sm"
        bg={inputBg}
        color={inputText}
        onChange={handleChange}
        fontWeight="500"
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius="30px"
        placeholder="Search area..."
      />
      {searching && (
        <Flex
          zIndex={999999}
          flexDir="column"
          color={inputText}
          position="absolute"
          top="64px"
          w="120%"
          borderRadius="32px"
          backgroundColor="#fff"
          p="12px 24px"
          gap="12px"
          boxShadow="0px 4px 20px rgba(0, 0, 0, 0.05)"
        >
          <Text fontSize="14px" fontWeight="700" mb="6px">
            Results:
          </Text>
          <Flex maxHeight="350px" overflow="scroll" flexDir="column" gap="12px">
            {filteredAreas.length ? (
              filteredAreas.map((area) => (
                <Flex
                  borderBottom="1px solid #eee"
                  pb="12px"
                  cursor="pointer"
                  onClick={() => {
                    navigate("/areas/" + area.id + "/manage");
                    setSearching(false);
                    setFilteredAreas([]);
                  }}
                  gap="6px"
                  alignItems="center"
                >
                  <Flex direction="column" gap="6px" flex="1">
                    <Text
                      fontSize="14px"
                      lineHeight="14px"
                      fontWeight="700"
                      m="0px"
                    >
                      {area.name}
                    </Text>
                    <Text
                      fontSize="12px"
                      lineHeight="12px"
                      opacity="0.5"
                      m="0px"
                    >
                      {area.id}
                    </Text>
                  </Flex>
                  <Tag
                    variant="solid"
                    colorScheme={
                      ApolloConfig.application === "mowiBike"
                        ? "green"
                        : "facebook"
                    }
                  >
                    {ApolloConfig.application === "mowiBike" ? "BIKE" : "SKI"}
                  </Tag>
                </Flex>
              ))
            ) : (
              <Text
                w="100%"
                align="center"
                fontSize="14px"
                fontWeight="700"
                opacity="0.5"
              >
                No area found
              </Text>
            )}
          </Flex>
        </Flex>
      )}
    </InputGroup>
  );
}
