import { Tag, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Column } from "components/layout/TableComponent";

const UserColumns: Column<User>[] = [
  {
    id: "displayName",
    render: (value, data) => (
      <NavLink to={data.id ?? ""}>
        <Text color="blue.500">{value}</Text>
      </NavLink>
    ),
    title: "Name",
    width: "50%",
  },
  {
    id: "email",
    render: (value) => <Text>{value}</Text>,
    title: "Email",
    width: "30%",
  },
  {
    id: "id",
    render: (value) => (
      <NavLink to={value ?? ""}>
        <Text fontSize="11px">{value}</Text>
      </NavLink>
    ),
    title: "Id",
    width: "20%",
  },
  {
    id: "basicInfo",
    render: (value, data) =>
      data?.basicInfo?.sex && (
        <Tag colorScheme={data?.basicInfo?.sex === "male" ? "blue" : "pink"}>
          {data?.basicInfo?.sex}
        </Tag>
      ),
    title: "Sex",
    width: "20%",
  },
];

export default UserColumns;
