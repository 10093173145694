import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import Card from "components/layout/Card/Card";
import { AreaProfilation } from "utils/DataMap";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import InputField from "components/layout/FormBuilderV2/Fields/InputField";
import SelectField from "components/layout/FormBuilderV2/Fields/SelectField";
import SwitchField from "components/layout/FormBuilderV2/Fields/SwitchField";

interface BundleCategoryProps {
  title?: string;
  category?: AreaBundleCategory[];
  onUpdate?: (c: AreaBundleCategory[]) => void;
}
const BundleCategory = ({ category, title, onUpdate }: BundleCategoryProps) => {
  const [expanded, setExpanded] = useState(false);

  const onAdd = () => {
    if (!expanded) setExpanded(true);
    onUpdate?.([
      ...(category ?? []),
      {
        booleans: [],
        localities: [],
        name: "",
        profilation: "",
        tagFilters: [],
        typologies: [],
      },
    ]);
  };

  const onAddProperty = (
    key: "localities" | "booleans" | "tagFilters" | "typologies",
    index: number
  ) => {
    if (!category) return;
    const t = { ...category?.[index] };

    t[key] = [
      ...(t[key] ?? []),
      { name: "", isEnabled: true, color: "", __id: `${key}_${index}` },
    ];
    const _cate = [...category];
    _cate?.splice(index, 1, t);
    onUpdate?.(_cate);
  };

  const onUpdateProperty = (
    key: "localities" | "booleans" | "tagFilters" | "typologies",
    subKey: "name" | "isEnabled" | "color",
    index: number,
    subIndex: number,
    value: any
  ) => {
    if (!category) return;
    const t = { ...category?.[index] };

    const _n: any = { ...t[key][subIndex] } ?? {};
    _n[subKey] = value;

    const _t = [...t[key]];
    _t?.splice(subIndex, 1, _n);
    const __t = { ...t, [key]: _t };
    const _cate = [...category];
    _cate?.splice(index, 1, __t);
    onUpdate?.(_cate);
  };

  const onDeleteProperty = (
    key: "localities" | "booleans" | "tagFilters" | "typologies",
    index: number,
    subIndex: number
  ) => {
    if (!category) return;
    const t = { ...category?.[index] };

    const _t = [...t[key]];
    _t?.splice(subIndex, 1);
    const __t = { ...t, [key]: _t };
    const _cate = [...category];
    _cate?.splice(index, 1, __t);
    onUpdate?.(_cate);
  };

  const onDeleteCard = (i: number) => {
    if (!category) return;
    const _cate = [...category];
    _cate?.splice(i, 1);
    onUpdate?.(_cate);
  };

  const onUpdateField = (
    subKey: "name" | "profilation",
    index: number,
    value: any
  ) => {
    if (!category) return;
    const t = { ...category?.[index] };

    t[subKey] = value;

    const _cate = [...category];
    _cate?.splice(index, 1, t);
    onUpdate?.(_cate);
  };

  return (
    <Flex
      direction="column"
      gap="16px"
      p="16px"
      width="100%"
      borderRadius="16px"
      border="1px solid #eee"
    >
      <Flex gap="24px" alignItems="center" p="12px">
        <Text flex="1" fontWeight="600" fontSize="24px">
          {title}
        </Text>
        <Button variant="outline" onClick={onAdd}>
          +
        </Button>

        <Button variant="outline" onClick={() => setExpanded((s) => !s)}>
          {expanded ? "Collapse" : "Expand"}
        </Button>
      </Flex>
      {expanded && (
        <Flex gap="16px" flexWrap="wrap">
          {category?.length ? (
            category.map((t, i) => (
              <Card key={i} w="calc(max(50% - 8px , 500px))" gap="16px">
                <Flex gap="14px">
                  <InputField
                    defaultValue={t.name}
                    label="Name"
                    placeholder="Name"
                    onChange={(a) => onUpdateField("name", i, a.target.value)}
                  />

                  <SelectField
                    label="Profilation"
                    placeholder="Profilation"
                    options={AreaProfilation}
                    defaultValue={t.profilation}
                    onChange={(a) =>
                      onUpdateField("profilation", i, a.target.value)
                    }
                  />
                </Flex>
                <Divider />
                <Flex gap="24px" alignItems="center" p="10px">
                  <Text fontSize="20px" fontWeight="600">
                    Localities
                  </Text>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onAddProperty("localities", i)}
                  >
                    Add new
                  </Button>
                </Flex>

                <Flex direction="column" gap="24px">
                  {t.localities.map((l, j) => (
                    <Flex gap="12px" key={`card_fi_${j}`}>
                      <Flex flex="1" gap="12px">
                        <InputField
                          defaultValue={l.name}
                          label="Name"
                          placeholder="Name"
                          onChange={(t) =>
                            onUpdateProperty(
                              "localities",
                              "name",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />

                        <InputField
                          defaultValue={l.color}
                          label="Color"
                          placeholder="Color"
                          onChange={(t) =>
                            onUpdateProperty(
                              "localities",
                              "color",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />
                      </Flex>
                      <Box mt="40px" flex="0">
                        <SwitchField
                          isChecked={l.isEnabled}
                          placeholder="Enabled"
                          onChange={() =>
                            onUpdateProperty(
                              "localities",
                              "isEnabled",
                              i,
                              j,
                              !l.isEnabled
                            )
                          }
                        />
                      </Box>
                      <Button
                        variant="link"
                        colorScheme="red"
                        mt="28px"
                        size="sm"
                        leftIcon={<MdDelete />}
                        onClick={() => onDeleteProperty("localities", i, j)}
                      />
                    </Flex>
                  ))}
                </Flex>
                <Divider />

                <Flex gap="24px" alignItems="center" p="10px">
                  <Text fontSize="20px" fontWeight="600">
                    Typologies
                  </Text>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onAddProperty("typologies", i)}
                  >
                    Add new
                  </Button>
                </Flex>

                <Flex direction="column" gap="24px">
                  {t.typologies.map((l, j) => (
                    <Flex gap="12px" key={`card_ti_${j}`}>
                      <Flex flex="1" gap="12px">
                        <InputField
                          defaultValue={l.name}
                          label="Name"
                          placeholder="Name"
                          onChange={(t) =>
                            onUpdateProperty(
                              "typologies",
                              "name",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />

                        <InputField
                          defaultValue={l.color}
                          label="Color"
                          placeholder="Color"
                          onChange={(t) =>
                            onUpdateProperty(
                              "typologies",
                              "color",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />
                      </Flex>
                      <Box mt="40px" flex="0">
                        <SwitchField
                          isChecked={l.isEnabled}
                          placeholder="Enabled"
                          onChange={() =>
                            onUpdateProperty(
                              "typologies",
                              "isEnabled",
                              i,
                              j,
                              !l.isEnabled
                            )
                          }
                        />
                      </Box>
                      <Button
                        variant="link"
                        colorScheme="red"
                        mt="28px"
                        size="sm"
                        leftIcon={<MdDelete />}
                        onClick={() => onDeleteProperty("typologies", i, j)}
                      />
                    </Flex>
                  ))}
                </Flex>
                <Divider />
                <Flex gap="24px" alignItems="center" p="10px">
                  <Text fontSize="20px" fontWeight="600">
                    Tag Filters
                  </Text>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onAddProperty("tagFilters", i)}
                  >
                    Add new
                  </Button>
                </Flex>

                <Flex direction="column" gap="24px">
                  {t.tagFilters.map((l, j) => (
                    <Flex gap="12px" key={`card_tf_${j}`}>
                      <Flex flex="1" gap="12px">
                        <InputField
                          defaultValue={l.name}
                          label="Name"
                          placeholder="Name"
                          onChange={(t) =>
                            onUpdateProperty(
                              "tagFilters",
                              "name",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />

                        <InputField
                          defaultValue={l.color}
                          label="Color"
                          placeholder="Color"
                          onChange={(t) =>
                            onUpdateProperty(
                              "tagFilters",
                              "color",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />
                      </Flex>
                      <Box mt="40px" flex="0">
                        <SwitchField
                          isChecked={l.isEnabled}
                          placeholder="Enabled"
                          onChange={() =>
                            onUpdateProperty(
                              "tagFilters",
                              "isEnabled",
                              i,
                              j,
                              !l.isEnabled
                            )
                          }
                        />
                      </Box>
                      <Button
                        variant="link"
                        colorScheme="red"
                        mt="28px"
                        size="sm"
                        leftIcon={<MdDelete />}
                        onClick={() => onDeleteProperty("tagFilters", i, j)}
                      />
                    </Flex>
                  ))}
                </Flex>
                <Divider />
                <Flex gap="24px" alignItems="center" p="10px">
                  <Text fontSize="20px" fontWeight="600">
                    Booleans
                  </Text>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onAddProperty("booleans", i)}
                  >
                    Add new
                  </Button>
                </Flex>
                <Flex direction="column" gap="24px">
                  {t.booleans.map((l, j) => (
                    <Flex gap="12px" key={`card_bo_${j}`}>
                      <Flex flex="1" gap="12px">
                        <InputField
                          defaultValue={l.name}
                          label="Name"
                          placeholder="Name"
                          onChange={(t) =>
                            onUpdateProperty(
                              "booleans",
                              "name",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />

                        <InputField
                          defaultValue={l.color}
                          label="Color"
                          placeholder="Color"
                          onChange={(t) =>
                            onUpdateProperty(
                              "booleans",
                              "color",
                              i,
                              j,
                              t.currentTarget.value
                            )
                          }
                        />
                      </Flex>
                      <Box mt="40px" flex="0">
                        <SwitchField
                          isChecked={l.isEnabled}
                          placeholder="Enabled"
                          onChange={() =>
                            onUpdateProperty(
                              "booleans",
                              "isEnabled",
                              i,
                              j,
                              !l.isEnabled
                            )
                          }
                        />
                      </Box>
                      <Button
                        variant="link"
                        colorScheme="red"
                        mt="28px"
                        size="sm"
                        leftIcon={<MdDelete />}
                        onClick={() => onDeleteProperty("booleans", i, j)}
                      />
                    </Flex>
                  ))}
                </Flex>
                <Button
                  variant="link"
                  colorScheme="red"
                  size="sm"
                  mt="24px"
                  leftIcon={<MdDelete />}
                  onClick={() => onDeleteCard(i)}
                >
                  Delete
                </Button>
              </Card>
            ))
          ) : (
            <Button
              w="100%"
              textAlign="center"
              opacity="0.5"
              mb="24px"
              pointerEvents="none"
              variant="outline"
            >
              No categories
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default BundleCategory;
