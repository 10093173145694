import { Button, Flex, Grid, Tab, TabList, Tabs } from "@chakra-ui/react";
import AreaItem from "./components/AreaItem";
import AreaSkeleton from "./components//AreaSkeleton";
import { IoAdd } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import useRefreshToken from "hooks/useRefreshToken";
import useCoolQuery from "hooks/useCoolQuery";
import { ALL_AREAS } from "apollo/queries/areas";

const Areas = () => {
  useRefreshToken();

  const { loading, data } = useCoolQuery<any, GetAreas>(ALL_AREAS, {});

  return (
    <Flex direction="column">
      <Flex
        mt="24px"
        mb="24px"
        ml="12px"
        position="fixed"
        zIndex="popover"
        right="32px"
        bottom="12px"
        borderRadius="12px"
      >
        <NavLink to="create">
          <Button variant="brand" size="lg" leftIcon={<IoAdd />}>
            Add new area
          </Button>
        </NavLink>
      </Flex>
      <Grid
        gap={6}
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
      >
        {loading ? (
          <AreaSkeleton />
        ) : (
          data?.allAreas.map((a) => <AreaItem {...a} />)
        )}
      </Grid>
    </Flex>
  );
};

export default Areas;
