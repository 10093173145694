import { gql } from "@apollo/client";

const USER_ME_INFO_FRAGMENT = gql`
  fragment userMeInfoFragment on User {
    id
    email
    displayName
    lastUpdateAvatar
    basicInfo {
      country
      birthYear
      sex
    }
    auth {
      createdAt

      permissions {
        area
        scopes
      }
    }
  }
`;

export const ALL_USERS_BIKE = gql`
  query allUsers($page: Int, $perPage: Int) {
    allUsers(page: $page, perPage: $perPage) {
      ...userMeInfoFragment
      bike {
        notifications {
          hasUnreaded
          lastUpdate
        }
      }
    }
  }
  ${USER_ME_INFO_FRAGMENT}
`;

export const ALL_USERS_SKI = gql`
  query allUsers($page: Int, $perPage: Int) {
    allUsers(page: $page, perPage: $perPage) {
      ...userMeInfoFragment
      ski {
        notifications {
          hasUnreaded
          lastUpdate
        }
      }
    }
  }
  ${USER_ME_INFO_FRAGMENT}
`;

export const ALL_USERS = gql`
  query allUsers($page: Int, $perPage: Int) {
    allUsers(page: $page, perPage: $perPage) {
      id
      email
      displayName
      basicInfo {
        country
        birthYear
        sex
      }
      auth {
        createdAt
        lastLogin {
          area
          updatedAt
        }
        permissions {
          area
          scopes
        }
      }
    }
  }
`;

export const USER = gql`
  query User($id: ID!) {
    User(id: $id) {
      ...userMeInfoFragment
    }
  }
  ${USER_ME_INFO_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($id: ID!, $userAuth: UserAuthInput) {
    updateUser(id: $id, userAuth: $userAuth) {
      ...userMeInfoFragment
    }
  }
  ${USER_ME_INFO_FRAGMENT}
`;
