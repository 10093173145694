import { useBehave } from "behave-react";
import { useEffect, useState } from "react";
import UserColumns from "./TableColumns";
import Card from "components/layout/Card/Card";
import useCoolQuery from "hooks/useCoolQuery";
import { ALL_USERS } from "apollo/queries/users";
import useRefreshToken from "hooks/useRefreshToken";
import { UsersAction, UsersSelect } from "store/modules/users";
import TableComponent from "components/layout/TableComponent";

const TablePage = () => {
  const [users, setUsers] = useState<User[]>([]);

  useRefreshToken();

  const query = (v: string, u: User) => u.email.includes(v);

  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [latestPage, setLatestPage] = useState(false);
  const { data, loading, refetch } = useCoolQuery<GetUsers, GetUsersResponse>(
    ALL_USERS,
    { page: 0, perPage: 20 },
    { context: "auth" }
  );

  const handleRefresh = () => {
    setCurrentPage(0);
    refetch({ page: 0, perPage: 20 });
  };

  const handleLoadMore = () => {
    console.log("load more", latestPage, loadingMore);
    if (!latestPage && !loadingMore) {
      setLoadingMore(true);
      refetch({ page: currentPage + 1, perPage: 20 });
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const newUsersFetch = data?.allUsers ?? [];
    setLoadingMore(false);
    setUsers([...users, ...newUsersFetch]);
    setLatestPage(newUsersFetch.length === 0);
  }, [data]);

  return (
    <Card pl="0" pr="0" flexDirection="column">
      <TableComponent
        isLoading={loading}
        query={query}
        onRefresh={handleRefresh}
        queryPlaceholder="Search for user"
        columns={UserColumns}
        data={users}
        canLoadMore={!latestPage}
        onEndReached={handleLoadMore}
      />
    </Card>
  );
};

export default TablePage;
