import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { UPDATE_BUNDLE_VERSION } from "apollo/queries/areas";
import InputField from "components/layout/FormBuilderV2/Fields/InputField";

import useCoolMutation from "hooks/useCoolMutation";
import useRefreshToken from "hooks/useRefreshToken";
import { useRef, useState } from "react";

interface BundleUpdateModalProps {
  onClose: () => void;
  isOpen: boolean;
  area: Area;
}

const BundleUpdateModal = ({
  isOpen,
  onClose,
  area,
}: BundleUpdateModalProps) => {
  const toast = useToast();
  const [newVersion, setNewVersion] = useState("");

  useRefreshToken(() => {}, area.id, !isOpen);

  const [update, { loading }] = useCoolMutation(UPDATE_BUNDLE_VERSION, {
    error: (e) => toast({ title: "Error", status: "error", description: e }),
    success: (e) => {
      toast({
        title: "Success",
        status: "success",
        description: "Bundle succesfully updated",
      });
      onClose();
    },
  });

  const handleUpdate = () => {
    update({
      variables: {
        id: area.id,
        version: newVersion,
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upgrade Bundle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack mb="12px">
            <Text>Current bundle version:</Text>
            <Text fontWeight="600">{area.bundle.version}</Text>
          </HStack>
          <InputField
            onChange={(t) => setNewVersion(t.target.value)}
            placeholder="New bundle version"
          />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isLoading={loading}
            onClick={handleUpdate}
          >
            Upgrade
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BundleUpdateModal;
