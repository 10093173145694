import { Box, useDisclosure } from "@chakra-ui/react";
import client from "apollo/client";
import { ALL_AREAS } from "apollo/queries/areas";

import { useBehave } from "behave-react";
import AdminNavbar from "components/layout/Navbar/NavbarAdmin";
import Sidebar from "components/layout/Sidebar/Sidebar";
import useCoolQuery from "hooks/useCoolQuery";
import { ReactNode, useEffect, useState } from "react";

import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import routes, { NavRoute } from "routes";
import { AreasAction } from "store/modules/areas";

const Authorized = () => {
  const { onOpen } = useDisclosure();
  const { dispatch } = useBehave();
  const location = useLocation();
  const [brand, setBrand] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setBrand(getActiveRoute(routes));
    setMessage(getActiveNavbarText(routes));
    window.scrollTo(0, 0);
  }, [location]);

  useCoolQuery<any, GetAreas>(
    ALL_AREAS,
    {},
    {
      success: (data) => {
        dispatch(AreasAction.setAreas(data?.allAreas ?? []));
      },
    }
  );

  const getActiveRoute = (routes: NavRoute[]) => {
    const activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (window.location.pathname.includes(routes[i].path)) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbarText = (routes: NavRoute[]) => {
    const activeNavbar = "";
    for (let i = 0; i < routes.length; i++) {
      if (window.location.pathname.includes(routes[i].path)) {
        return routes[i].messageNavbar ?? "";
      }
    }
    return activeNavbar;
  };

  const RoutesBuilder: (_routes: NavRoute[]) => ReactNode[] = (
    _routes: NavRoute[]
  ) =>
    _routes.reduce((list: ReactNode[], route: NavRoute) => {
      if (route.component) {
        return [
          ...list,
          <Route
            key={route.path}
            path={route.path}
            element={route.component}
          />,
        ];
      } else return list;
    }, []);

  return (
    <Box>
      <Sidebar routes={routes} />
      <AdminNavbar onOpen={onOpen} brandText={brand} message={message} />
      <Box
        mx="auto"
        p={{ base: "20px", md: "30px" }}
        pe="20px"
        minH="100vh"
        pt={{ base: "260px", md: "160px", xl: "160px" }}
        w={{ base: "100%", xl: "calc( 100% - 290px )" }}
        maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
        float="right"
      >
        <Routes>
          {RoutesBuilder(routes)}
          {<Route path="/*" element={<Navigate to="/areas" />} />}
        </Routes>
      </Box>
    </Box>
  );
};

export default Authorized;
