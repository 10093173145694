import { Layer, Map, MapRef, Source } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { useCallback, useState } from "react";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibWF0ZW9kZWx1Y2lhIiwiYSI6ImNsOWQyMnhpeTAyYXUzcm15dTB6bGs5b3MifQ.ZitrPTcJfMi8CaR9pqGcMQ";

interface MapComponentProps {
  type: "Point" | "MultiLineString" | "LineString";
  coordinates: any;
}

const MapComponent = ({ type, coordinates }: MapComponentProps) => {
  const coordinatesParsed = () => {
    try {
      return JSON.parse(coordinates);
    } catch (error) {
      return undefined;
    }
  };

  const calculateViewPort = () => {
    if (type === "Point") {
      const c: number[] = coordinatesParsed() as number[];
      return {
        latitude: c?.[1],
        longitude: c?.[0],
        zoom: c ? 15 : 0,
      };
    }

    if (type === "LineString") {
      const c: number[][] = coordinatesParsed() as number[][];
      const l = c?.length ?? 0;
      return {
        latitude: (c?.[0]?.[1] + c?.[l - 1]?.[1]) / 2,
        longitude: (c?.[0]?.[0] + c?.[l - 1]?.[0]) / 2,
        zoom: 14,
      };
    }
    if (type === "MultiLineString") {
      const c: number[][][] = (coordinatesParsed() as any) ?? [[[0]]];
      const l = c?.[0]?.length ?? 0;

      return {
        latitude: (c?.[0]?.[0]?.[1] + c?.[0]?.[l - 1]?.[1]) / 2,
        longitude: (c?.[0]?.[0]?.[0] + c?.[0]?.[l - 1]?.[0]) / 2,
        zoom: 14,
      };
    }

    return { latitude: 0, longitude: 0, zoom: 0 };
  };

  return (
    <Map
      style={{ width: "100%", height: 500, borderRadius: "12px" }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={MAPBOX_TOKEN}
      initialViewState={calculateViewPort()}
    >
      <Source
        type="geojson"
        data={{
          type: "Feature",
          geometry: {
            type,
            coordinates: coordinatesParsed() as any,
          },
          properties: {
            name: "",
          },
        }}
      >
        <Layer
          id="layer"
          type="line"
          paint={{ "line-color": "blue", "line-opacity": 0.8 }}
        />
        <Layer
          id="layer2"
          type="circle"
          paint={{
            "circle-color": "blue",
            "circle-opacity": 0.8,
            "circle-radius": 4,
          }}
        />
        <Layer
          id="layer3"
          type="fill"
          paint={{ "fill-color": "blue", "fill-opacity": 0.2 }}
        />
      </Source>
    </Map>
  );
};

export default MapComponent;
