import {
  Tabs,
  TabList,
  Tab,
  Divider,
  Button,
  useToast,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import useCoolMutation from "hooks/useCoolMutation";

import { useEffect, useState } from "react";

import { PRIMARY_SERVICES } from "utils/DataMap";
import { useNavigate, useParams } from "react-router-dom";

import GeoLocation from "components/layout/GeoLocation";
import {
  TRANSPORT,
  TRANSPORT_CREATE,
  TRANSPORT_DELETE,
  TRANSPORT_UPDATE,
} from "apollo/queries/transports";
import useCoolQuery from "hooks/useCoolQuery";
import CleanPayload from "utils/CleanPayload";
import MediaSelector from "components/layout/MediaSelector";
import useAreaBundle from "hooks/useAreaBundle";
import {
  FormBuilderV2,
  FormCard,
  Field,
  FormRow,
} from "components/layout/FormBuilderV2";

const TypedField = Field<Transport>;

interface Props {
  isEdit?: boolean;
}

const CreateUpdateTransport = ({ isEdit }: Props) => {
  const toast = useToast();

  const { pid, id } = useParams();
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState<any>();
  const [coordinateType, setCoordinateType] = useState<any>();
  const [category, setCategory] = useState<string>();
  const [selectedBundle, setSelectedBundle] = useState<any>();

  const [fileToInsert, setFilesToInsert] = useState<string[]>([]);
  const [fileToRemove, setFilesToRemove] = useState<string[]>([]);

  const [media, setMedia] = useState<Partial<Transport["media"]>>();

  const bundleTransports = useAreaBundle(id ?? "", "transport");
  const { data: _dataTransport, loading: loadingFetch } = useCoolQuery<
    any,
    GetTransportResponse
  >(TRANSPORT, { id: pid }, { disableFetchOnLoad: !isEdit });

  const dataTransport = {
    userTransport: {
      ..._dataTransport?.userTransport,
      specialPOIs:
        _dataTransport?.userTransport?.specialPOIs?.map((p: any) => ({
          ...p,

          geoLocation: {
            coordinatesLineString: JSON.stringify(
              p.geoLocation.coordinatesLineString ?? "[]"
            ),
            coordinatesMultiLineString: JSON.stringify(
              p.geoLocation.coordinatesMultiLineString ?? "[]"
            ),
            coordinatesPoint: JSON.stringify(
              p.geoLocation.coordinatesPoint ?? "[]"
            ),
          },
        })) ?? [],
    },
  };

  const [createUpdate, { loading }] = useCoolMutation(
    isEdit ? TRANSPORT_UPDATE : TRANSPORT_CREATE,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: isEdit
            ? "Transport updated succesfully"
            : "Transport created succesfully",
        });
        !isEdit && navigate("/areas/" + id + "/transports");
      },
    }
  );

  const [deleteTransport, { loading: loadingDelete }] = useCoolMutation(
    TRANSPORT_DELETE,
    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: "Transport deleted succesfully",
        });
        navigate("/areas/" + id + "/transports");
      },
    }
  );

  const handleCreate = (data: Transport) => {
    const variables = CleanPayload(data);

    const parsedCoordinates = JSON.parse(coordinates ?? "[]");

    const parsedSpecialPois: Transport["specialPOIs"] =
      variables.specialPOIs?.map((p: any) => ({
        ...p,
        geoLocation: {
          coordinatesLineString: JSON.parse(
            p.geoLocation.coordinatesLineString ?? "[]"
          ),
          coordinatesMultiLineString: JSON.parse(
            p.geoLocation.coordinatesMultiLineString ?? "[]"
          ),
          coordinatesPoint: JSON.parse(p.geoLocation.coordinatesPoint ?? "[]"),
        },
      }));
    variables.specialPOIs = parsedSpecialPois;
    variables.media = {
      cover: { url: media?.cover?.url, _id: media?.cover?._id },
      pictures: media?.pictures?.map((pic) => ({
        url: pic.url,
        _id: pic._id,
      })),
      videos: data.media?.videos?.map((pic) => ({
        url: pic.url,
      })),
    };
    variables.category = category;
    variables.area = id;
    variables.geoLocation = {
      [coordinateType === "Point"
        ? "coordinatesPoint"
        : coordinateType === "LineString"
        ? "coordinatesLineString"
        : "coordinatesMultiLineString"]: parsedCoordinates,
    };

    const files = [
      ...fileToInsert
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "insert" })),
      ...fileToRemove
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "remove" })),
    ];
    variables.files = files;
    createUpdate({ variables });
  };

  useEffect(() => {
    setCoordinates(
      JSON.stringify(
        dataTransport?.userTransport?.geoLocation?.coordinatesLineString?.length
          ? dataTransport?.userTransport?.geoLocation?.coordinatesLineString
          : dataTransport?.userTransport?.geoLocation
              ?.coordinatesMultiLineString?.length
          ? dataTransport?.userTransport.geoLocation.coordinatesMultiLineString
          : dataTransport?.userTransport?.geoLocation?.coordinatesPoint
      )
    );
    setCoordinateType(
      dataTransport?.userTransport?.geoLocation?.coordinatesLineString?.length
        ? "LineString"
        : dataTransport?.userTransport?.geoLocation?.coordinatesMultiLineString
            ?.length
        ? "MultiLineString"
        : "Point"
    );

    setMedia(dataTransport?.userTransport?.media);
  }, [_dataTransport]);
  useEffect(() => {
    const cate =
      dataTransport?.userTransport.category ?? bundleTransports?.[0]?.name;
    setCategory(cate);

    setSelectedBundle(
      bundleTransports?.find((a) => a.name === cate) ?? bundleTransports?.[0]
    );
  }, [bundleTransports, _dataTransport]);
  useEffect(() => {
    setSelectedBundle(bundleTransports?.find((a) => a.name === category));
  }, [category]);

  return (
    <FormBuilderV2
      loading={loadingFetch}
      editMode={isEdit}
      initialValues={dataTransport?.userTransport}
      submit={{
        action: isEdit ? "Update" : "Create",
        color: isEdit ? "purple" : "green",
        loading,
        onSubmit: handleCreate,
        title: isEdit ? "Update Transport?" : "Create Transport?",
        subtitle: isEdit
          ? "This will update the Transport"
          : "This will create a new Transport in the current area.",
      }}
    >
      <FormCard title="Transport summary">
        <TypedField id="name" type="text" label="Name" required />
        <TypedField
          id="primaryService"
          type="select"
          options={PRIMARY_SERVICES}
          label="Primary Service"
          required
        />
        <TypedField
          type="select"
          label="Category"
          value={category}
          onChange={(v) => setCategory(v.target.value)}
          options={bundleTransports?.map((a) => ({
            name: a.name,
            value: a.name,
          }))}
          required
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="select"
          id="typology"
          label="Typology"
          options={selectedBundle?.typologies.map((a: any) => ({
            name: a.name,
            value: a.name,
          }))}
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="tags"
          id="localities"
          label="Localities"
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
          options={[
            { name: "", value: undefined },
            ...(selectedBundle?.localities.map((a: any) => ({
              name: a.name,
              value: a.name,
            })) ?? []),
          ]}
        />
        <TypedField
          hidden={!category || !selectedBundle?.typologies}
          type="tags"
          id="tagFilters"
          label="TagFilters"
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
          options={[
            { name: "", value: undefined },
            ...(selectedBundle?.tagFilters.map((a: any) => ({
              name: a.name,
              value: a.name,
            })) ?? []),
          ]}
        />

        <TypedField id="originalStatus" type="switch" label="Original status" />
        <TypedField
          id="externalInfo.externalId"
          type="text"
          label="External Id"
        />
      </FormCard>
      <GeoLocation
        type={coordinateType}
        coordinates={coordinates}
        onChange={(d, v) => {
          setCoordinateType(d);
          setCoordinates(v);
        }}
      />
      <FormCard title="Detailed info">
        <Tabs>
          <TabList>
            <Tab>Italian</Tab>
            <Tab>English</Tab>
            <Tab>Deutsch</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Station start name IT"
                id="detailedInfo.stations.start.name.it"
              />
              <TypedField
                type="text"
                label="Station end name IT"
                id="detailedInfo.stations.end.name.it"
              />
              <TypedField
                type="textArea"
                label="Description Long IT"
                id="description.long.it"
              />
              <TypedField
                type="text"
                label="Description Short IT"
                id="description.short.it"
              />
              <TypedField
                type="textArea"
                label="Locality IT"
                id="detailedInfo.locality.it"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Station start name EN"
                id="detailedInfo.stations.start.name.en"
              />
              <TypedField
                type="text"
                label="Station end name EN"
                id="detailedInfo.stations.end.name.en"
              />
              <TypedField
                type="textArea"
                label="Description Long EN"
                id="description.long.en"
              />
              <TypedField
                type="text"
                label="Description Short EN"
                id="description.short.en"
              />
              <TypedField
                type="textArea"
                label="Locality EN"
                id="detailedInfo.locality.en"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Station start name DE"
                id="detailedInfo.stations.start.name.de"
              />
              <TypedField
                type="text"
                label="Station end name DE"
                id="detailedInfo.stations.end.name.de"
              />
              <TypedField
                type="textArea"
                label="Description Long DE"
                id="description.long.de"
              />
              <TypedField
                type="text"
                label="Description Short DE"
                id="description.short.de"
              />
              <TypedField
                type="textArea"
                label="Locality DE"
                id="detailedInfo.locality.de"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Divider />
        <TypedField
          id="detailedInfo.importance"
          type="number"
          label="Importance"
        />
        <TypedField
          id="detailedInfo.tagServices"
          type="tags"
          label="Tag services"
        />
        <TypedField
          id="detailedInfo.isForDisabled"
          type="switch"
          label="For Disabled"
        />
        <TypedField
          id="detailedInfo.isForPedestrians"
          type="switch"
          label="For Pedestrian"
        />
        <TypedField
          id="detailedInfo.isForSnowscoot"
          type="switch"
          label="For Snowscoot"
        />
      </FormCard>
      <MediaSelector
        type="transports"
        values={media}
        onInsert={(id) => setFilesToInsert((a) => [...a, id])}
        onRemove={(id) => setFilesToRemove((a) => [...a, id])}
        onChange={setMedia}
      />
      <FormCard title="Geo Location ">
        <TypedField type="text" label="Label" id="geoLocation.label" />
        <TypedField
          type="text"
          label="Color"
          id="geoLocation.color"
          placeholder="#fff"
        />
        <TypedField type="number" label="Size" id="geoLocation.size" />
        <TypedField
          type="switch"
          label="Hide if not selected"
          id="geoLocation.hideIfNotSelected"
        />
      </FormCard>

      <FormCard title="Detailed infos">
        <FormRow>
          <TypedField
            id="detailedInfo.stations.start.altitude"
            type="number"
            label="Start altitude"
            rightAddon="mt"
          />
          <TypedField
            id="detailedInfo.stations.end.altitude"
            type="number"
            label="End altitude"
            rightAddon="mt"
          />
        </FormRow>
      </FormCard>
      <FormCard title="Special pois" fullWidth>
        <TypedField
          type="array"
          id="specialPOIs"
          bordered
          element={(id) => (
            <>
              <FormRow>
                <Field id={`${id}name`} label="Name" type="text" />
                <Field
                  id={`${id}primaryService`}
                  label="Primary service"
                  type="select"
                  options={PRIMARY_SERVICES}
                />
              </FormRow>
              <Tabs w="100%">
                <TabList>
                  <Tab>Italian</Tab>
                  <Tab>English</Tab>
                  <Tab>Deutsch</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel
                    p="16px 0px"
                    gap="12px"
                    display="flex"
                    flexDir="column"
                  >
                    <Field
                      id={`${id}description.long.it`}
                      label="Description Long IT"
                      type="textArea"
                    />
                    <Field
                      id={`${id}description.short.it`}
                      label="Description short IT"
                      type="text"
                    />
                  </TabPanel>
                  <TabPanel
                    p="16px 0px"
                    gap="12px"
                    display="flex"
                    flexDir="column"
                  >
                    <Field
                      id={`${id}description.long.en`}
                      label="Description Long EN"
                      type="textArea"
                    />
                    <Field
                      id={`${id}description.short.en`}
                      label="Description short EN"
                      type="text"
                    />
                  </TabPanel>
                  <TabPanel
                    p="16px 0px"
                    gap="12px"
                    display="flex"
                    flexDir="column"
                  >
                    <Field
                      id={`${id}description.long.de`}
                      label="Description Long DE"
                      type="textArea"
                    />
                    <Field
                      id={`${id}description.short.de`}
                      label="Description short DE"
                      type="text"
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Field
                id={`${id}altimetry.highestPoint`}
                label="Highest point"
                type="number"
              />
              <FormRow>
                <Field
                  type="text"
                  label="Point"
                  id={`${id}geoLocation.coordinatesPoint`}
                />
                <Field
                  type="text"
                  label="Line"
                  id={`${id}geoLocation.coordinatesLineString`}
                />
                <Field
                  type="text"
                  label="Area"
                  id={`${id}geoLocation.coordinatesMultiLineString`}
                />
              </FormRow>
              <FormRow>
                <Field
                  type="text"
                  label="Label"
                  id={`${id}geoLocation.label`}
                />

                <Field
                  type="text"
                  label="Color"
                  id={`${id}geoLocation.color`}
                />
              </FormRow>
            </>
          )}
        />
      </FormCard>
      <FormCard
        hide={!isEdit}
        title="Delete Transport"
        subtitle="Do you want to delete this Transport?"
        rightAction={
          <Button
            variant="outline"
            isLoading={loadingDelete}
            colorScheme="red"
            onClick={() => deleteTransport({ variables: { id: pid } })}
          >
            Delete
          </Button>
        }
      />
    </FormBuilderV2>
  );
};

export default CreateUpdateTransport;
