import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { ApolloConfig } from "./config";
import { createUploadLink } from "apollo-upload-client";

export type ApolloContext = "auth" | "mowi";

const auth = new HttpLink({
  uri: ApolloConfig.authUrl,
});
const mowi = new HttpLink({
  uri: ApolloConfig.mowiUrl,
});
const uploadLink = createUploadLink({
  uri: ApolloConfig.mowiUrl,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `${localStorage.getItem("accessToken") || null}`,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: ApolloLink.concat(
    authMiddleware,
    ApolloLink.split(
      (operation) => operation.getContext().clientName === "auth",
      auth,
      ApolloLink.split(
        (operation) => operation.getContext().hasUpload,
        uploadLink,
        mowi
      )
    )
  ),
  cache: new InMemoryCache(),
});

export default client;
