import moment from "moment";

const FormTransforms = {
  strArrayToInt: [(v: any) => v, (v: any) => v?.map((a: any) => parseInt(a))],
  strArrayToFloat: [
    (v: any) => v,
    (v: any) => v?.map((a: any) => parseFloat(a)),
  ],

  jsonToString: [
    (v: any) => {
      try {
        return JSON.stringify(v);
      } catch (e) {
        return undefined;
      }
    },
    (v: any) => {
      try {
        return JSON.parse(v);
      } catch (e) {
        return undefined;
      }
    },
  ],
  dateToString: [
    (v: any) => {
      return v ? moment(v, moment.ISO_8601).format("YYYY-MM-DD") : undefined;
    },
    (v: any) => (v ? moment(v).format("YYYY-MM-DD") : undefined),
  ],

  strToBool: [(v: any) => v, (v: any) => v === "true"],
};

export default FormTransforms;
