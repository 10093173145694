import { ReactNode } from "react";
import {
  MdDashboard,
  MdPointOfSale,
  MdEdit,
  MdTraffic,
  MdNotifications,
  MdOutlineMessage,
  MdMessage,
  MdEditRoad,
  MdShop,
  MdPeople,
} from "react-icons/md";
import Areas from "pages/Areas/Table";
import Users from "pages/Users/TablePage";
import UpdateArea from "pages/Areas/Update";
import CreateArea from "pages/Areas/Create";
import PoisPage from "pages/Pois/TablePage";
import TrackPage from "pages/Tracks/TablePage";
import TransportsPage from "pages/Transports/TablePage";
import InAppMessages from "pages/InAppMessages/TablePage";
import NotificationsPage from "pages/Notifications/TablePage";
import { IconType } from "react-icons/lib";
import UpdateUser from "pages/Users/Update";
import CreateUpdatePois from "pages/Pois/CreateUpdate";
import CreateUpdateTrack from "pages/Tracks/CreateUpdate";
import CreateUpdateTransport from "pages/Transports/CreateUpdate";
import CreateUpdateIAM from "pages/InAppMessages/CreateUpdate";
import CreateUpdateNotification from "pages/Notifications/CreateUpdate";
import { Navigate } from "react-router-dom";

export interface NavRoute {
  name: string;
  path: string;
  icon?: IconType;
  component?: ReactNode;
  messageNavbar?: string;
  hideFromSidebar?: boolean;
  pattern?: RegExp;
  submenu?: boolean;
}

const routes: NavRoute[] = [
  {
    name: "Areas",
    path: "/areas",
    icon: MdDashboard,
    component: <Areas />,
    pattern: /\/areas|users|iams/,
  },
  {
    name: "Users",
    path: "/users",
    icon: MdPeople,
    component: <Users />,
    pattern: /\/areas|users|iams/,
  },
  {
    name: "Users",
    path: "/users/:id",
    hideFromSidebar: true,
    component: <UpdateUser />,
    pattern: /\/areas|users|iams/,
  },
  {
    name: "Create new area",
    path: "/areas/create",
    component: <CreateArea />,
    hideFromSidebar: true,
  },
  {
    name: "In app messages",
    path: "/iams",
    component: <InAppMessages />,
    icon: MdMessage,
    pattern: /\/areas|users|iams/,
  },
  {
    name: "Manage area",
    path: "/areas/:id/manage",
    component: <UpdateArea />,
    icon: MdEdit,
    pattern: /\/areas\/.{7,}/,
  },
  {
    name: "Manage area",
    path: "/areas/:id",
    component: <Navigate to="manage" />,
    hideFromSidebar: true,
  },

  {
    name: "In app messages",
    path: "/iams/create",
    component: <CreateUpdateIAM />,
    hideFromSidebar: true,
    pattern: /\/areas|users|iams/,
  },

  {
    name: "In app messages",
    path: "/iams/:id",
    component: <CreateUpdateIAM isEdit />,
    hideFromSidebar: true,
    pattern: /\/areas|users|iams/,
  },
  {
    name: "Pois",
    path: "/areas/:id/pois",
    component: <PoisPage />,
    icon: MdShop,
    pattern: /\/areas\/.{7,}/,
    submenu: true,
  },
  {
    name: "Pois",
    path: "/areas/:id/pois/create",
    component: <CreateUpdatePois />,
    hideFromSidebar: true,
  },
  {
    name: "Pois",
    path: "/areas/:id/pois/:pid",
    component: <CreateUpdatePois isEdit />,
    hideFromSidebar: true,
  },

  {
    name: "Tracks",
    path: "/areas/:id/tracks",
    component: <TrackPage />,
    icon: MdEditRoad,
    submenu: true,
    pattern: /\/areas\/.{7,}/,
  },

  {
    name: "Tracks",
    path: "/areas/:id/tracks/create",
    component: <CreateUpdateTrack />,
    hideFromSidebar: true,
  },
  {
    name: "Tracks",
    path: "/areas/:id/tracks/:pid",
    component: <CreateUpdateTrack isEdit />,
    hideFromSidebar: true,
  },

  {
    name: "Transports",
    path: "/areas/:id/transports",
    component: <TransportsPage />,
    icon: MdTraffic,
    submenu: true,
    pattern: /\/areas\/.{7,}/,
  },

  {
    name: "Transports",
    path: "/areas/:id/transports/create",
    component: <CreateUpdateTransport />,
    hideFromSidebar: true,
  },
  {
    name: "Transports",
    path: "/areas/:id/transports/:pid",
    component: <CreateUpdateTransport isEdit />,
    hideFromSidebar: true,
  },

  {
    name: "Notifications",
    path: "/areas/:id/notifications",
    component: <NotificationsPage />,
    icon: MdNotifications,
    submenu: true,
    pattern: /\/areas\/.{7,}/,
  },

  {
    name: "Notifications",
    path: "/areas/:id/notifications/create",
    component: <CreateUpdateNotification />,
    hideFromSidebar: true,
    pattern: /\/areas\/.{7,}/,
  },

  {
    name: "Notifications",
    path: "/areas/:id/notifications/:pid",
    component: <CreateUpdateNotification isEdit />,
    hideFromSidebar: true,
    pattern: /\/areas\/.{7,}/,
  },
];

export default routes;
