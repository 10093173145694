import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormFieldComponent } from "../types";

const ArrayField = ({
  id,
  element,
  disabled,
  bordered,
}: FormFieldComponent<any>) => {
  const formcontroller = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: formcontroller.control,
    name: id ?? "a",
  });

   
  return (
    <Flex direction="column" gap="12px">
      {fields?.map((field, index) => (
        <Flex
          key={index}
          gap="12px"
          borderBottom="1px solid #eee"
          pb="12px"
          borderRadius={bordered ? "12px" : 0}
          border={bordered ? "1px solid #eee" : undefined}
          p={bordered ? "12px" : undefined}
        >
          <Text
            fontWeight="bold"
            opacity="0.5"
            mb="6px"
            alignSelf="center"
            w="20px"
          >
            {index + 1}.
          </Text>
          <Flex
            flex="1"
            wrap="wrap"
            gap="6px"
            alignItems="center"
            justifyContent="stretch"
          >
            {element?.(`${id}.${index}.`)}
          </Flex>

          <Button
            alignSelf="center"
            onClick={() => remove(index)}
            colorScheme="red"
            size="xs"
            variant="outline"
          >
            -
          </Button>
        </Flex>
      ))}
      {!disabled && (
        <Button
          onClick={() => append({})}
          w="100%"
          variant="outline"
          borderStyle="dashed"
        >
          Add
        </Button>
      )}
    </Flex>
  );
};

export default ArrayField;
