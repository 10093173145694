import { useMutation, DocumentNode } from "@apollo/client";
import { ApolloContext } from "apollo/client";
import { useEffect } from "react";

const useCoolMutation = <T, R>(
  mutation: DocumentNode,

  config?: {
    clientName?: ApolloContext;
    error?: (e: string) => void;
    success?: (data: any) => void;
    hasUpload?: boolean;
  }
) => {
  const mut = useMutation<T, R>(mutation, {
    context: {
      clientName: config?.clientName ?? "mowi",
      hasUpload: config?.hasUpload,
    },
  });

  const [func, { data, loading, error }] = mut;

  useEffect(() => {
    if (config?.error && error && !loading) config.error(error.message);
    if (config?.success && data && !loading) config.success(data);
  }, [error, data, loading]);

  return [func, { data, loading, error }] as typeof mut;
};

export default useCoolMutation;
