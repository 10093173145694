import { Badge, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Column } from "components/layout/TableComponent";

const PoisColumns: Column<Partial<Poi>>[] = [
  {
    id: "name",
    render: (value, data) => (
      <NavLink to={data.id ?? ""}>
        <Text color="blue.500">{value}</Text>
      </NavLink>
    ),
    title: "Name",
    width: "20%",
  },
  {
    id: "description",
    render: (value) => (
      <Text whiteSpace="break-spaces" fontSize="12px">
        {value?.short?.it?.slice(0, 100) ?? "No description"}
      </Text>
    ),
    title: "Description",
    width: "50%",
  },
  {
    id: "id",
    render: (value) => <Text fontSize="12px">{value}</Text>,
    title: "Id",
  },
  {
    id: "primaryService",
    render: (value, data) => <Badge>{value}</Badge>,
    title: "Primary service",
  },
  {
    id: "localities",
    render: (value: string[]) => (
      <Text fontSize="12px" whiteSpace="nowrap">
        {value.join(", ")}
      </Text>
    ),
    title: "Localities",
    width: "20%",
  },
];

export default PoisColumns;
