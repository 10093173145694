import React, { useContext } from "react";
import { FieldValues } from "react-hook-form";
import { Transformation, TransformContextType } from "../types";

const TransformContext = React.createContext<TransformContextType>({
  bind: () => {},
  disabled: false,
});

const useTransformationBind = () => {
  return useContext(TransformContext);
};
const TransformProvider = TransformContext.Provider;
export { TransformProvider, useTransformationBind };
