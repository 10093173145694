import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import * as selectors from "./selectors";
import saga from "./saga";

const MODULE = "areas";

const INITIAL_STATE: AreasModuleState = {};

const slice = createSlice({
  initialState: INITIAL_STATE,
  name: MODULE,
  reducers: { ...reducers.actions, ...reducers.saga },
});

type RS = keyof typeof reducers.saga;
export type AreasSagas = `${typeof MODULE}/${RS}`;
export const AreasAction = slice.actions;
export const AreasSelect = selectors;
export const AreasSaga = saga;
export const AreasInitialState = INITIAL_STATE;
export default slice.reducer;
