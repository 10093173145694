import React, { useState, useMemo, ReactNode, useEffect } from "react";
import { AiOutlineReload } from "react-icons/ai";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Text,
  VStack,
  Spinner,
  HStack,
  Image,
  Input,
  Button,
  Icon,
  Box,
} from "@chakra-ui/react";
import Pagination from "./components/Pagination";
import SkeletonLoading from "./components/SkeletonLoading";
import EmptyTable, { EmptyTableProps } from "./components/EmptyTable";

export interface Column<T> {
  id: keyof T;
  width?: string | number;
  minWidth?: string | number;
  title?: string;
  render: (value: any, data: T) => ReactNode;
}

export interface TableProps<T> {
  columns: Column<T>[];
  data?: T[];
  title?: string;
  isLoading?: boolean;
  placeholder?: EmptyTableProps;
  query?: (value: string, data: T) => boolean;
  queryPlaceholder?: string;
  action1?: ReactNode;
  action2?: ReactNode;
  pageSize?: number;
  pageSiblings?: number;
  onRefresh?: () => void;
  onEndReached?: () => void;
  canLoadMore?: boolean;
}

const TableComponent = <T,>({
  columns,
  data,
  title,
  placeholder,
  isLoading,
  query,
  queryPlaceholder,
  action1,
  action2,
  pageSize,
  pageSiblings,
  onRefresh,
  onEndReached,
  canLoadMore,
}: TableProps<T>) => {
  const [displayData, setDisplayData] = useState(data ?? []);
  const [currentPage, setCurrentPage] = useState(0);
  const [queryValue, setQueryValue] = useState<string>();

  useEffect(() => {}, [data]);

  const dataSlice = useMemo(() => {
    if (!pageSize && queryValue)
      return data?.filter((value) => query?.(queryValue, value));
    else if (!pageSize) return data;
    else
      return displayData.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize
      );
  }, [currentPage, data, displayData]);

  useEffect(() => {
    if (!queryValue) return setDisplayData(data ?? []);
    setDisplayData(data?.filter((value) => query?.(queryValue, value)) ?? []);
  }, [data]);

  const queryItems = (event: any) => {
    const queryEvent = event.target.value.toLowerCase();
    setQueryValue(queryEvent);
    if (queryEvent === "") {
      setDisplayData(data ?? []);
    } else {
      setCurrentPage(0);
      const queried = data?.filter((value) => query?.(queryEvent, value)) ?? [];
      setDisplayData(queried);
      if (queried.length < 15) onEndReached?.();
    }
  };

  const handleScroll = (e: any) => {
    const value =
      e.currentTarget.scrollHeight -
      e.currentTarget.clientHeight -
      e.currentTarget.scrollTop;

    if (!onEndReached) return;
    if (!canLoadMore) return;
    if (value < 10) {
      onEndReached();
    }
  };

  return (
    <Flex
      w="100%"
      direction="column"
      gap="12px"
      mt="16px"
      maxH="calc(100vh - 240px)"
    >
      <Flex
        px="25px"
        justify="space-between"
        direction={!query ? "row" : "column"}
        alignItems={query ? undefined : "center"}
        align="left"
        width="100%"
        mb="12px"
      >
        {title && (
          <Text
            fontSize="24px"
            ms="8px"
            mb="10px"
            fontWeight="600"
            lineHeight="100%"
          >
            {title}
          </Text>
        )}
        <Flex
          direction="row"
          gap="5px"
          w="100%"
          maxH="100%"
          justifyContent={query ? undefined : "flex-end"}
        >
          {query && (
            <Input
              variant="outline"
              placeholder={queryPlaceholder}
              onChange={queryItems}
              width="100%"
              borderRadius="10px"
              background="transparent"
            />
          )}
          {onRefresh && (
            <Button
              isLoading={isLoading}
              variant="outline"
              onClick={onRefresh}
              alignItems="center"
            >
              <Icon as={AiOutlineReload} w="5" h="5" />
            </Button>
          )}
          {action1}
          {action2}
        </Flex>
      </Flex>

      {isLoading && !data?.length ? (
        <SkeletonLoading />
      ) : (
        <Table
          onScroll={(e) => handleScroll(e)}
          variant="unstyled"
          style={{
            width: "100%",
            minWidth: "100%",
            overflow: "scroll",
            maxHeight: "100%",
            display: "block",
          }}
        >
          <Thead
            borderBottom="1px solid #e2e8f0"
            borderTop="1px solid #e2e8f0"
            background="#e2e8f02f"
          >
            <Tr w="100%">
              {columns?.map((col: Column<T>) => (
                <Th
                  color="gray.600"
                  fontSize="12px"
                  w={col.width}
                  minWidth={col.minWidth}
                  letterSpacing="none"
                >
                  <Text whiteSpace="nowrap">{col.title}</Text>
                </Th>
              ))}
            </Tr>
          </Thead>

          {dataSlice?.length ? (
            <Tbody>
              {dataSlice.map((d) => (
                <Tr borderTop="1px solid #e2e8f0">
                  {columns.map((col) => (
                    <Td color="gray.500">{col.render(d[col.id], d)} </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <EmptyTable {...placeholder} />
          )}
          {canLoadMore && (
            <Flex
              w="100%"
              justifyContent="center"
              gap="32px"
              alignItems="center"
              padding="24px"
              opacity="0.4"
            >
              <Text>Loading more...</Text>
              <Spinner size="sm" />
            </Flex>
          )}
        </Table>
      )}

      {pageSize && (
        <Pagination
          siblings={pageSiblings}
          pages={Math.ceil(displayData.length / (pageSize ?? 1))}
          current={currentPage}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </Flex>
  );
};

export default TableComponent;
