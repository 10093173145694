import {
  Tabs,
  TabList,
  Tab,
  Divider,
  Button,
  useToast,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import useCoolMutation from "hooks/useCoolMutation";

import { useEffect, useState } from "react";

import { REPEAT_IAM, SCOPE_IAM } from "utils/DataMap";
import { useNavigate, useParams } from "react-router-dom";

import {
  DELETE_IAMS,
  IAM,
  UPDATE_IAMS,
  CREATE_IAMS,
} from "apollo/queries/iams";
import useCoolQuery from "hooks/useCoolQuery";

import IAMPreview from "./components/IAMPreview";
import CleanPayload from "utils/CleanPayload";
import {
  FormBuilderV2,
  FormCard,
  Field,
  FormRow,
} from "components/layout/FormBuilderV2";
import { useBehave } from "behave-react";
import { AreasSelect } from "store/modules/areas";

const TypedField = Field<Message>;

interface Props {
  isEdit?: boolean;
}

const CreateUpdateIAM = ({ isEdit }: Props) => {
  const toast = useToast();
  const [infosLen, setInfosLen] = useState("it");
  const { id } = useParams();
  const [image, setImage] = useState<any>();
  const { select } = useBehave();
  const areas = select(AreasSelect.areas);
  const AREA_OPTIONS = areas.map((area) => ({
    value: area.id,
    name: area.name,
  }));

  const { data: dataIam, loading: loadingFetch } = useCoolQuery<
    any,
    GetInAppMessage
  >(IAM, { id }, { disableFetchOnLoad: !isEdit });

  if (
    dataIam?.InAppMessage?.area &&
    !AREA_OPTIONS.find((a) => a.value === dataIam?.InAppMessage?.area)
  ) {
    AREA_OPTIONS.push({
      name: dataIam?.InAppMessage?.area,
      value: dataIam?.InAppMessage?.area,
    });
  }
  const navigate = useNavigate();

  const [createUpdate, { loading }] = useCoolMutation(
    isEdit ? UPDATE_IAMS : CREATE_IAMS,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: isEdit
            ? "Successfully update Iam!"
            : "Succesfully created Iam",
        });
        !isEdit && navigate("/iams");
      },
    }
  );

  const [deleteIam, { loading: loadingDelete }] = useCoolMutation(
    DELETE_IAMS,

    {
      error: (e) => toast({ title: "Error", status: "error", description: e }),
      success: (e) => {
        toast({
          title: "Success",
          status: "success",
          description: "Succesfully deleted Iam",
        });
        isEdit && navigate("/iams");
      },
    }
  );

  const handleCreate = (data: Message) => {
    const variables = CleanPayload({ ...data, image });

    createUpdate({ variables });
  };

  useEffect(() => {
    setImage(dataIam?.InAppMessage?.image);
  }, [dataIam]);

  return (
    <FormBuilderV2
      loading={loadingFetch}
      editMode={isEdit}
      initialValues={dataIam?.InAppMessage}
      submit={{
        action: isEdit ? "Update" : "Create",
        color: isEdit ? "purple" : "green",
        loading,
        onSubmit: handleCreate,
        title: isEdit ? "Update Message?" : "Create Message?",
        subtitle: isEdit
          ? "This will update the message"
          : "This will create a new message in the current area.",
      }}
    >
      <FormCard title="Message summary">
        <TypedField
          id="scope"
          type="select"
          label="Scope"
          options={SCOPE_IAM}
          required
        />
        <TypedField
          type="select"
          id="repeat"
          label="Repeat"
          options={REPEAT_IAM}
          required
        />
        <TypedField type="text" id="appMinVersion" label="App min version" />
        <TypedField
          type="select"
          id="area"
          label="Area ID"
          options={AREA_OPTIONS}
          required
        />

        <Tabs>
          <TabList>
            <Tab onClick={() => setInfosLen("it")}>Italian</Tab>
            <Tab onClick={() => setInfosLen("en")}>English</Tab>
            <Tab onClick={() => setInfosLen("de")}>Deutsch</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Title IT"
                placeholder="Title"
                id="title.it"
              />
              <TypedField
                type="textArea"
                label="Description IT"
                placeholder="Add a description"
                id="description.it"
              />
              <TypedField
                type="text"
                label="Link IT"
                placeholder="Link"
                id="link.it"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Title EN"
                placeholder="Title"
                id="title.en"
              />
              <TypedField
                type="textArea"
                label="Description EN"
                placeholder="Add a description"
                id="description.en"
              />
              <TypedField
                type="text"
                label="Link EN"
                placeholder="Link"
                id="link.en"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="text"
                label="Title DE"
                placeholder="Title"
                id="title.de"
              />
              <TypedField
                type="textArea"
                label="Description DE"
                placeholder="Add a description"
                id="description.de"
              />
              <TypedField
                type="text"
                label="Link DE"
                placeholder="Link"
                id="link.de"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormCard>
      <IAMPreview
        image={image?.[infosLen]}
        onImageChange={(v) => setImage((a: any) => ({ ...a, [infosLen]: v }))}
        title={(dataIam?.InAppMessage.title as any)?.[infosLen]}
        description={(dataIam?.InAppMessage.description as any)?.[infosLen]}
      />

      <FormCard
        hide={!isEdit}
        title="Delete In App Message"
        subtitle="Do you want to delete this IAM?"
        rightAction={
          <Button
            variant="outline"
            isLoading={loadingDelete}
            colorScheme="red"
            onClick={() => deleteIam({ variables: { id } })}
          >
            Delete
          </Button>
        }
      />
    </FormBuilderV2>
  );
};

export default CreateUpdateIAM;
