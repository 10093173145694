import { InputProps, Switch } from "@chakra-ui/react";
import React, { ReactNode, useEffect } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import access from "utils/DotNotation";
import ArrayField from "../Fields/ArrayField";
import InputField from "../Fields/InputField";
import SelectField from "../Fields/SelectField";
import SwitchField from "../Fields/SwitchField";
import TagsField from "../Fields/TagsField";
import TextAreaField from "../Fields/TextAreaField";

import { FormField } from "../types";
import { useTransformationBind } from "./TransformProvider";

const Field = <T extends FieldValues>(props: FormField<T>) => {
  const { type, ...fieldProps } = props;
  const formController = useFormContext();
  const { bind, disabled } = useTransformationBind();

  useEffect(() => {
    if (props.transform && props.id)
      bind(
        props.id,
        typeof props.transform === "string"
          ? (props.transform as string)
          : (props.transform as any)[0],
        (props.transform as any)[1]
      );
  }, []);

  if (props.hidden) return null;

  switch (type) {
    case "text":
    case "email":
    case "date":
    case "phone":
    case "number":
    case "password":
      return (
        <InputField
          disabled={disabled}
          errors={formController?.formState.errors}
          formRef={
            props.id
              ? formController?.register(props.id, {
                  required: props.required,
                  pattern: props.pattern,
                  validate: props.validate,
                  maxLength: props.maxLength,
                  minLength: props.minLength,
                  onChange: props.onChange,
                  valueAsNumber: props.type === "number",
                })
              : undefined
          }
          type={type}
          {...fieldProps}
        />
      );
    case "tags":
      return (
        <TagsField
          disabled={disabled}
          {...fieldProps}
          onChange={
            !props.id
              ? props.onChange
              : (a) => formController.setValue(props.id as string, a)
          }
          value={props.value ?? access(formController.getValues?.(), props.id)}
        />
      );
    case "select":
      return (
        <SelectField
          disabled={disabled}
          {...fieldProps}
          formRef={
            props.id
              ? formController?.register(props.id, {
                  required: props.required,
                  pattern: props.pattern,
                  onChange: props.onChange,
                })
              : undefined
          }
        />
      );
    case "switch":
      return (
        <SwitchField
          disabled={disabled}
          {...fieldProps}
          onChange={!props.id ? props.onChange : undefined}
          isChecked={props.value}
          formRef={
            props.id
              ? formController?.register(props.id, {
                  required: props.required,
                  pattern: props.pattern,
                  onChange: props.onChange,
                })
              : undefined
          }
        />
      );

    case "textArea":
      return (
        <TextAreaField
          disabled={disabled}
          {...fieldProps}
          onChange={!props.id ? props.onChange : undefined}
          value={props.value}
          formRef={
            props.id
              ? formController?.register?.(props.id as any, {
                  required: props.required,
                  validate: props.validate,
                  onChange: props.onChange,
                })
              : undefined
          }
        />
      );
    case "array":
      return <ArrayField disabled={disabled} {...fieldProps} />;
  }

  return null;
};

export default Field;
