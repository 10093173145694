import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useBehave } from "behave-react";
import { useState } from "react";
import { AreasSelect } from "store/modules/areas";

interface AreaSelectPopupProps {
  onSelect: (area: Area) => void;
  onClose: () => void;
  isOpen: boolean;
  filter: string[];
}
const AreaSelectPopup = ({
  onSelect,
  onClose,
  isOpen,
  filter,
}: AreaSelectPopupProps) => {
  const { select } = useBehave();
  const areas = select(AreasSelect.areas);
  const cleanAreas = areas.filter((a) => !filter.includes(a.id));
  const [filteredAreas, setFilteredAreas] = useState<Area[]>(cleanAreas);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target?.value?.length) setFilteredAreas(cleanAreas);
    else
      setFilteredAreas(
        cleanAreas.filter(
          (a) =>
            a.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            a.id.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="12px">
          <Input
            fontSize="sm"
            onChange={handleChange}
            placeholder="Search area..."
            mb="12px"
          />
          <Flex>
            <Flex
              maxHeight="350px"
              w="100%"
              overflow="scroll"
              flexDir="column"
              gap="12px"
            >
              {filteredAreas.length ? (
                filteredAreas.map((area) => (
                  <Flex
                    borderBottom="1px solid #eee"
                    pb="12px"
                    cursor="pointer"
                    onClick={() => onSelect(area)}
                    gap="6px"
                    alignItems="center"
                  >
                    <Flex direction="column" gap="6px" flex="1">
                      <Text
                        fontSize="14px"
                        lineHeight="14px"
                        fontWeight="700"
                        m="0px"
                      >
                        {area.name}
                      </Text>
                      <Text
                        fontSize="12px"
                        lineHeight="12px"
                        opacity="0.5"
                        m="0px"
                      >
                        {area.id}
                      </Text>
                    </Flex>
                  </Flex>
                ))
              ) : (
                <Text
                  w="100%"
                  align="center"
                  fontSize="14px"
                  fontWeight="700"
                  opacity="0.5"
                >
                  No area found
                </Text>
              )}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AreaSelectPopup;
