import { Text } from "@chakra-ui/react";
import { Column } from "components/layout/TableComponent";
import { NavLink } from "react-router-dom";

const NotificationsColumns: Column<Notification>[] = [
  {
    id: "head",
    render: (value, data) => (
      <NavLink to={data.id ?? ""}>
        <Text color="blue.500">{value?.it ?? "No title"} </Text>
      </NavLink>
    ),
    title: "Title",
    width: "100%",
  },
  {
    id: "id",
    render: (value) => <Text>{value}</Text>,
    title: "Id",
    width: "30%",
  },
  {
    id: "notificationType",
    render: (value) => <Text>{value}</Text>,
    title: "Type",
    width: "20%",
  },
  {
    id: "status",
    render: (value) => <Text>{value}</Text>,
    title: "Status",
    width: "20%",
  },
  {
    id: "numberOfRecipients",
    render: (value) => <Text>{value}</Text>,
    title: "Number of recipients",
    width: "20%",
  },
];

export default NotificationsColumns;
