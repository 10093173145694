import { NavLink, useParams } from "react-router-dom";

import PoisColumns from "./TableColumns";
import Card from "components/layout/Card/Card";

import useCoolQuery from "hooks/useCoolQuery";
import { Button } from "@chakra-ui/react";
import { IoAdd } from "react-icons/io5";
import TableComponent from "components/layout/TableComponent";
import { AREA_POIS } from "apollo/queries/pois";

const TablePage = () => {
  const { id } = useParams<any>();
  const { data, loading, refetch } = useCoolQuery<GetAreaInfo, GetPoisResponse>(
    AREA_POIS,
    { area: id ?? "" }
  );

  const handleQuery = (v: string, d: Poi) =>
    d.id.includes(v) || d.name.includes(v);

  return (
    <Card pl="0" pr="0" flexDirection="column">
      <TableComponent
        action2={
          <NavLink to="create">
            <Button variant="brand" leftIcon={<IoAdd />}>
              Add new
            </Button>
          </NavLink>
        }
        title="Pois"
        onRefresh={refetch}
        isLoading={loading}
        query={handleQuery}
        queryPlaceholder="Search for Poi"
        columns={PoisColumns}
        data={data?.userPois}
      />
    </Card>
  );
};

export default TablePage;
