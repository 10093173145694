import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SelectFieldProps,
  Text,
  ThemingProps,
} from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import access from "utils/DotNotation";
import { FormFieldComponent } from "../types";

const SelectField = ({
  id,
  label,
  placeholder,
  formRef,
  errors,
  disabled,
  extra,
  options,
  onSelected,
  ...rest
}: FormFieldComponent<any>) => {
  const handleSelect: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onSelected?.(e.currentTarget.value);
  };

  return (
    <Flex direction="column" w="100%">
      <FormControl isInvalid={access(errors, id)} isDisabled={disabled}>
        {label && (
          <FormLabel
            display="flex"
            ms="10px"
            htmlFor={id}
            fontSize="sm"
            fontWeight="bold"
            _hover={{ cursor: "pointer" }}
          >
            {label}
            <Text fontSize="sm" fontWeight="400" ms="8px">
              {extra}
            </Text>
          </FormLabel>
        )}
        <Select
          borderRadius="16px"
          h="42px"
          {...formRef}
          onChange={formRef ? formRef.onChange : handleSelect}
          {...rest}
        >
          {options?.map((e) => (
            <option key={e.value + id} value={e.value}>
              {e.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage fontSize="smaller">
          {access(errors, id) &&
            (access(errors, id).message?.lenght
              ? access(errors, id).message
              : "*  This field is required")}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
};

export default SelectField;
