import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import Card from "components/layout/Card/Card";
import { Field, FormCard } from "components/layout/FormBuilderV2";
import { useEffect } from "react";
import { useFilePicker } from "use-file-picker";

interface MessageMediaProps {
  onImageChange: (url?: string) => void;
  image?: string;
  title?: string;
  description?: string;
}

const IAMPreview = ({
  image,
  onImageChange,
  title,
  description,
}: MessageMediaProps) => {
  return (
    <FormCard key="preview-iam">
      <Flex align="center" direction="column" w="100%">
        <Card width={250} height={500} p={3} backgroundColor="#333">
          <Box cursor={"pointer"} w="100%" position="relative" h="100%">
            <Image
              position="absolute"
              borderRadius={16}
              w="100%"
              h="100%"
              sx={{ objectFit: "cover" }}
              src={image ?? "/imgs/areaPlaceholder.png"}
              alt="back"
            />
            <Flex
              position="relative"
              zIndex={12}
              direction="column"
              align="center"
              h="100%"
              justifyContent="center"
            >
              <Text fontSize={16} fontWeight={700}>
                {title ?? "Title here"}
              </Text>
              <Text fontSize={14}>{description ?? "Description here"}</Text>
            </Flex>
          </Box>
        </Card>
        <Input
          placeholder="Image URL"
          value={image ?? ""}
          mt="16px"
          onChange={(e) => onImageChange(e.currentTarget.value)}
        />
      </Flex>
    </FormCard>
  );
};

export default IAMPreview;
