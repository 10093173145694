import { ApolloContext } from "apollo/client";
import { AREA_COMPLETE } from "apollo/queries/areas";
import { useEffect, useState } from "react";
import useCoolQuery from "./useCoolQuery";

const useAreaBundle = (id: string, type: "poi" | "track" | "transport") => {
  const [bundle, setBundle] = useState<AreaBundleCategory[]>();
  const { data } = useCoolQuery<{ id: string }, { Area: Area }>(
    AREA_COMPLETE,
    {
      id: id ?? "",
    }
  );

  useEffect(() => {
    if (data?.Area.bundle) {
      const bundle = data.Area.bundle[type];
      setBundle(bundle);
    }
  }, [data]);

  return bundle;
};
export default useAreaBundle;
