import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation ($email: String!, $password: String!, $application: Application) {
    loginUser(email: $email, password: $password, application: $application) {
      accessToken
      refreshToken
      user {
        email
        displayName
      }
      info
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation ($refreshToken: String!, $area: ID, $application: Application) {
    refreshToken(
      refreshToken: $refreshToken
      area: $area
      application: $application
    ) {
      accessToken
      refreshToken
      user {
        email
        displayName
      }
      info
    }
  }
`;

export const ADMIN_ADD_FILES = gql`
  mutation ($media: [Upload!]!, $scope: FileScope) {
    AdminAddFiles(files: $media, scope: $scope) {
      _id
      url
    }
  }
`;
