type DMap<T> = { name: string; value: T }[];

export const AREA_TYPES: DMap<string> = [
  { name: "Bike", value: "bike" },
  { name: "Ski", value: "ski" },
];

export const SEX_OPTIONS: DMap<string> = [
  { name: "Man", value: "male" },
  { name: "Woman", value: "female" },
];

export const AREA_DOMAINS: DMap<string> = [
  { name: "MowiBike", value: "mowiBike" },
  { name: "MowiSki", value: "mowiSnow" },
  { name: "MowiTrek", value: "mowiTrek" },
];

export const MOWI_TYPES: DMap<AreaMowiType> = [
  { name: "Gold", value: "mowi_gold" },
  { name: "Silver", value: "mowi_silver" },
  { name: "Bronze", value: "mowi_bronze" },
  { name: "Wood", value: "mowi_wood" },
];

export const AreaProfilation: DMap<any> = [
  { name: "None", value: undefined },
  { name: "Pedaled", value: "pedaled" },
  { name: "Gravity", value: "gravity" },
  { name: "Family", value: "family" },
];

export const SUGGESTER_PERIOD: DMap<number | undefined> = [
  { value: undefined, name: "None" },
  {
    value: 0,
    name: "Gennaio",
  },
  {
    value: 1,
    name: "Febbraio",
  },
  {
    value: 2,
    name: "Marzo",
  },
  {
    value: 3,
    name: "Aprile",
  },
  {
    value: 4,
    name: "Maggio",
  },
  {
    value: 5,
    name: "Giugno",
  },
  {
    value: 6,
    name: "Luglio",
  },
  {
    value: 7,
    name: "Agosto",
  },
  {
    value: 8,
    name: "Settembre",
  },
  {
    value: 9,
    name: "Ottobre",
  },
  {
    value: 10,
    name: "Novembre",
  },
  {
    value: 11,
    name: "Dicembre",
  },
];

export const PRIMARY_SERVICES = [
  {
    value: "aerialway_chair_lift",
    name: "aerialway_chair_lift",
  },
  {
    value: "aerialway_gondola",
    name: "aerialway_gondola",
  },
  {
    value: "aerialway_lift",
    name: "aerialway_lift",
  },
  {
    value: "aerialway_station",
    name: "aerialway_station",
  },
  {
    value: "alpine_hut",
    name: "alpine_hut",
  },
  {
    value: "alpine_hut_sponsored",
    name: "alpine_hut_sponsored",
  },
  {
    value: "alpineskiing_gear",
    name: "alpineskiing_gear",
  },
  {
    value: "andalo_life",
    name: "andalo_life",
  },
  {
    value: "animals_no",
    name: "animals_no",
  },
  {
    value: "animals_yes",
    name: "animals_yes",
  },
  {
    value: "atm",
    name: "atm",
  },
  {
    value: "baby_backpack",
    name: "baby_backpack",
  },
  {
    value: "bicycle_bus_stop",
    name: "bicycle_bus_stop",
  },
  {
    value: "bicycle_rent",
    name: "bicycle_rent",
  },
  {
    value: "bicycle_repair",
    name: "bicycle_repair",
  },
  {
    value: "bicycle_repair_sponsored",
    name: "bicycle_repair_sponsored",
  },
  {
    value: "bicycle_sell",
    name: "bicycle_sell",
  },
  {
    value: "bicycle_wash",
    name: "bicycle_wash",
  },
  {
    value: "bus",
    name: "bus",
  },
  {
    value: "charging_station",
    name: "charging_station",
  },
  {
    value: "crampons",
    name: "crampons",
  },
  {
    value: "crosscountry_skiing_gear",
    name: "crosscountry_skiing_gear",
  },
  {
    value: "disabled_instructor",
    name: "disabled_instructor",
  },
  {
    value: "doctor",
    name: "doctor",
  },
  {
    value: "food",
    name: "food",
  },
  {
    value: "food_sponsored",
    name: "food_sponsored",
  },
  {
    value: "fountain",
    name: "fountain",
  },
  {
    value: "googles_and_mask",
    name: "googles_and_mask",
  },
  {
    value: "gym",
    name: "gym",
  },
  {
    value: "handicap_yes",
    name: "handicap_yes",
  },
  {
    value: "helmet",
    name: "helmet",
  },
  {
    value: "historical_point",
    name: "historical_point",
  },
  {
    value: "ice_skating",
    name: "ice_skating",
  },
  {
    value: "info_point",
    name: "info_point",
  },
  {
    value: "info_point_outdoor",
    name: "info_point_outdoor",
  },
  {
    value: "info_point_panel",
    name: "info_point_panel",
  },
  {
    value: "info_point_panel_ar",
    name: "info_point_panel_ar",
  },
  {
    value: "info_point_sponsored",
    name: "info_point_sponsored",
  },
  {
    value: "instructor_meeting_point",
    name: "instructor_meeting_point",
  },
  {
    value: "kart",
    name: "kart",
  },
  {
    value: "kids_trail",
    name: "kids_trail",
  },
  {
    value: "kinderclub",
    name: "kinderclub",
  },
  {
    value: "my_hotel",
    name: "my_hotel",
  },
  {
    value: "outdoor_school",
    name: "outdoor_school",
  },
  {
    value: "outdoor_school_sponsored",
    name: "outdoor_school_sponsored",
  },
  {
    value: "panorama",
    name: "panorama",
  },
  {
    value: "parking",
    name: "parking",
  },
  {
    value: "party",
    name: "party",
  },
  {
    value: "pedestrians_no",
    name: "pedestrians_no",
  },
  {
    value: "pedestrians_yes",
    name: "pedestrians_yes",
  },
  {
    value: "pharmacy",
    name: "pharmacy",
  },
  {
    value: "point_danger",
    name: "point_danger",
  },
  {
    value: "point_forbidden",
    name: "point_forbidden",
  },
  {
    value: "point_warning",
    name: "point_warning",
  },
  {
    value: "pub",
    name: "pub",
  },
  {
    value: "pub_sponsored",
    name: "pub_sponsored",
  },
  {
    value: "rent",
    name: "rent",
  },
  {
    value: "rent_sponsored",
    name: "rent_sponsored",
  },
  {
    value: "repair_kit",
    name: "repair_kit",
  },
  {
    value: "restaurant",
    name: "restaurant",
  },
  {
    value: "riding_school",
    name: "riding_school",
  },
  {
    value: "school",
    name: "school",
  },
  {
    value: "school_sponsored",
    name: "school_sponsored",
  },
  {
    value: "sell",
    name: "sell",
  },
  {
    value: "shop",
    name: "shop",
  },
  {
    value: "shop_bicycle",
    name: "shop_bicycle",
  },
  {
    value: "shop_sponsored",
    name: "shop_sponsored",
  },
  {
    value: "shop_ticket",
    name: "shop_ticket",
  },
  {
    value: "shower",
    name: "shower",
  },
  {
    value: "ski_boots",
    name: "ski_boots",
  },
  {
    value: "ski_bus_station",
    name: "ski_bus_station",
  },
  {
    value: "ski_instructor",
    name: "ski_instructor",
  },
  {
    value: "ski_lift",
    name: "ski_lift",
  },
  {
    value: "ski_sell",
    name: "ski_sell",
  },
  {
    value: "ski_storage",
    name: "ski_storage",
  },
  {
    value: "skimo_gear",
    name: "skimo_gear",
  },
  {
    value: "sleeding_gear",
    name: "sleeding_gear",
  },
  {
    value: "sleep",
    name: "sleep",
  },
  {
    value: "snow_clothing",
    name: "snow_clothing",
  },
  {
    value: "snow_shoes_gear",
    name: "snow_shoes_gear",
  },
  {
    value: "snow_tubing",
    name: "snow_tubing",
  },
  {
    value: "snowboard_gear",
    name: "snowboard_gear",
  },
  {
    value: "snowboard_instructor",
    name: "snowboard_instructor",
  },
  {
    value: "snowscoot",
    name: "snowscoot",
  },
  {
    value: "snowscoot_gear",
    name: "snowscoot_gear",
  },
  {
    value: "snowshoes_gear",
    name: "snowshoes_gear",
  },
  {
    value: "spa",
    name: "spa",
  },
  {
    value: "swimming_pool",
    name: "swimming_pool",
  },
  {
    value: "tapis_roulant",
    name: "tapis_roulant",
  },
  {
    value: "toilette",
    name: "toilette",
  },
  {
    value: "tourist_attraction",
    name: "tourist_attraction",
  },
  {
    value: "track_pump",
    name: "track_pump",
  },
  {
    value: "train",
    name: "train",
  },
];

export const SPONSOR_LEVEL = [
  { name: "Base", value: "base" },
  { name: "Medium", value: "medium" },
  { name: "Premium", value: "premium" },
];

export const SCOPE_IAM = [
  { value: "commercial", name: "Commercial" },
  { value: "alert", name: "Alert" },
  { value: "info", name: "Info" },
];

export const TRACK_CATEGORY = [
  { value: "bike_gravity", name: "Bike Gravity" },
  { value: "bike_trails", name: "Bike Trails" },
  { value: "MtbWorldChamp2021", name: "MtbWorldChamp2021" },
];
export const TRACK_TYPE = [
  { value: "en", name: "Enduro" },
  { value: "dh", name: "Down hill" },
];

export const TRACK_ORIGIN = [
  { value: "", name: "Non specificato" },
  { value: "artificiale", name: "Artificiale" },
  { value: "naturale", name: "Naturale" },
];

export const YES_NO_OPTIONAL = [
  { value: "", name: "Non specificato" },
  { value: true, name: "Yes" },
  { value: false, name: "No" },
];

export const REPEAT_IAM = [
  { value: undefined, name: "" },
  { value: "week", name: "Weekly" },
  { value: "month", name: "Monthly" },
];

export const MOWI_TYPE_COLORS: { [key in AreaMowiType]: string } = {
  mowi_bronze: "bronze",
  mowi_gold: "gold",
  mowi_silver: "silver",
  mowi_wood: "brown",
};

export const SCOPE_COLORS: { [key in Message["scope"]]: string } = {
  alert: "red",
  auth: "blue",
  commercial: "orange",
  info: "green",
  noauth: "pink",
};

export const NOTIFICATION_TYPES: DMap<Notification["notificationType"]> = [
  { value: "frienshipAccepted", name: "Frienship Accepted" },
  { value: "frienshipRequest", name: "Frienship Request" },
  { value: "groupUserAdd", name: "Group User Add" },
  { value: "groupUserRemove", name: "Group User Remove" },
  { value: "poiWarning", name: "Poi Warning" },
  { value: "realTimePoiStatusClose", name: "Real Time Poi Status Close" },
  { value: "realTimePoiStatusOpen", name: "Real Time Poi Status Open" },
  { value: "patrolUserNewNotice", name: "Patrol User New Notice" },
  { value: "trackWarning", name: "Track Warning" },
  { value: "transportWarning", name: "Transport Warning" },
  { value: "realTimeTrackStatusOpen", name: "Real Time Track Status Open" },
  { value: "realTimeTrackStatusClose", name: "Real Time Track Status Close" },
  {
    value: "realTimeTransportStatusOpen",
    name: "Real Time Transport Status Open",
  },
  {
    value: "realTimeTransportStatusClose",
    name: "Real Time Transport Status Close",
  },
];

export const NOTIFICATION_RECIPEINTS: DMap<Notification["recipients"]> = [
  { value: "devices", name: "Devices" },
  { value: "devicesInArea", name: "Devices In Area" },
  /* { value: "devicesInAreaWithUser", name: "Devices In Area With User" },
  { value: "noOne", name: "No One" },
  { value: "devicesNowInArea", name: "Devices Now In Area" },
  { value: "devicesNowInAreaWithUser", name: "Devices Now In Area With User" },
  { value: "devicesWithUser", name: "Devices With User" },*/
];
