import { Box, Flex, Text } from "@chakra-ui/react";
import Card from "components/layout/Card/Card";
import { FormCard } from "components/layout/FormBuilderV2";


interface NotifactionMediaProps {
  title?: string;
  description?: string;
}

const NotificationPreview = ({ title, description }: NotifactionMediaProps) => {
  return (
    <FormCard key="preview-iam">
      <Flex align="center" direction="column" w="100%">
        <Card
          width={500}
          height={300}
          p={4}
          pb="0"
          borderRadius={48}
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
          backgroundColor="#333"
          boxShadow="none "
        >
          <Box
            cursor={"pointer"}
            w="100%"
            position="relative"
            h="100%"
            borderRadius={33}
            backgroundColor="#f2f2f2"
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}
            padding="16px"
          >
            <Card borderRadius={12} p="0px " backgroundColor="#fff" mt="52px">
              <Flex
                position="relative"
                zIndex={12}
                direction="column"
                align="center"
                h="100%"
                justifyContent="center"
                p="12px"
              >
                <Text fontSize={16} fontWeight={700}>
                  {title ?? "Title here"}
                </Text>
                <Text fontSize={14}>{description ?? "Description here"}</Text>
              </Flex>
            </Card>
          </Box>
          <Box
            position="absolute"
            background="linear-gradient(#fff0,#fff)"
            w="100%"
            h="60px"
            left="0"
            bottom="0"
          />
        </Card>
      </Flex>
    </FormCard>
  );
};

export default NotificationPreview;
