import { behaveStore, poolReducer } from "behave-react";
import { combineReducers } from "redux";
import SessionModule from "./modules/session";
import AreaModule, { AreasSaga } from "./modules/areas";
import UserModule, { UsersSaga } from "./modules/users";

const rootReducer = combineReducers({
  ...poolReducer(),
  session: SessionModule,
  areas: AreaModule,
  users: UserModule,
});

const { store, persistor } = behaveStore({
  persistKey: "mowi-app-admin",
  reducers: rootReducer,
  sagas: [AreasSaga, UsersSaga],
  devtool: true,
});

export type RootState = ReturnType<typeof rootReducer>;
const Behave = { store, persistor };
export default Behave;
