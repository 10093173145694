import { PAction } from "behave-react";
import { AreasInitialState } from ".";

const Reducers = {
  setAreas: (state: AreasModuleState, action: PAction<Area[]>) => {
    state.areas = action.payload;
  },

  purge: (state: AreasModuleState) => ({ ...AreasInitialState }),
};

const AreasReducers = { actions: Reducers, saga: {} };
export default AreasReducers;
