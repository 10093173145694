import { Button, Flex, Text } from "@chakra-ui/react";
import Card from "components/layout/Card/Card";
import { SubmitFormProps } from "../types";

const SubmitForm = (props: SubmitFormProps<any>) => {
  return (
    <Card
      display="flex"
      flexDirection="row"
      alignItems="center"
      position="fixed"
      right="48px"
      bottom="48px"
      gap="24px"
      maxW="80%"
      width="max-content"
      justifyContent="space-between"
    >
      <Flex direction="column">
        <Text fontSize="18px" fontWeight="700">
          {props.title}
        </Text>
        <Text opacity="0.5">{props.subtitle}</Text>
      </Flex>
      <Button
        type="submit"
        padding="0px 24px"
        colorScheme={props.color}
        variant="outline"
        isLoading={props.loading}
      >
        {props.action}
      </Button>
    </Card>
  );
};

export default SubmitForm;
