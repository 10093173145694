import { Fade, Flex } from "@chakra-ui/react";
import { FormBuilderV2Props } from "./types";
import FormFallback from "./components/Fallback";
import FormLoading from "./components/Loading";
import SubmitForm from "./components/Submit";
import FullForm from "./components/FullForm";
import Field from "./components/Field";
import FormCard from "./components/FormCard";
import FormRow from "./components/FormRow";
const FormBuilderV2 = (props: FormBuilderV2Props<any>) => {
  const { loading, fallback, editMode, initialValues, submit, children } =
    props;
  if (loading) return <FormLoading loading />;

  if (editMode && !initialValues && fallback)
    return <FormFallback fallback={fallback} />;

  if (submit) return <FullForm {...props} />;

  return (
    <Fade in>
      <Flex
        w="100%"
        wrap="wrap"
        gap="24px"
        alignItems="flex-start"
        pb={submit ? "140px" : undefined}
      >
        {children}
      </Flex>
    </Fade>
  );
};

export { FormBuilderV2, Field, FormCard, FormRow };
