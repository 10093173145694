import { SEX_OPTIONS } from "utils/DataMap";
import { useParams } from "react-router-dom";
import AuthPermissions from "./components/AuthPermissions";
import { USER } from "apollo/queries/users";
import useCoolQuery from "hooks/useCoolQuery";
import {
  Field,
  FormBuilderV2,
  FormCard,
  FormRow,
} from "components/layout/FormBuilderV2";

const TypedField = Field<User>;
const UpdateUser = () => {
  const { id } = useParams<any>();
  const userQuery = useCoolQuery<any, GetUser>(
    USER,
    { id },
    { context: "auth" }
  );

  return (
    <FormBuilderV2
      loading={!userQuery.data?.User ? "Loading user..." : undefined}
      initialValues={userQuery.data?.User}
      submit={{ onSubmit: () => {} }}
      fallback="User not found"
      disabled
    >
      <FormCard title="User summary">
        <TypedField type="text" id="displayName" label="Name" />
        <FormRow>
          <TypedField type="text" id="id" label="Id" />
          <TypedField type="text" id="email" label="Email" />
        </FormRow>
      </FormCard>
      <FormCard title="Basic infos">
        <FormRow>
          <TypedField
            type="number"
            id="basicInfo.birthYear"
            label="Birth year"
          />
          <TypedField type="text" id="basicInfo.country" label="Country" />
        </FormRow>
        <TypedField
          type="select"
          id="basicInfo.sex"
          label="Sex"
          options={SEX_OPTIONS}
        />
      </FormCard>

      <FormCard title="Privacy">
        <TypedField
          type="switch"
          id="privacy.marketing.isAccept"
          label="Marketing"
          description="Is accepted?"
        />

        <FormRow>
          <TypedField
            type="text"
            id="privacy.marketing.updatedAt"
            label="Updated at"
          />
          <TypedField
            type="text"
            id="privacy.marketing.version"
            label="Version"
          />
        </FormRow>
      </FormCard>
      <FormCard title="Auth">
        <FormRow>
          <TypedField type="date" id="auth.createdAt" label="Created at" />
          <TypedField type="text" id="auth.createdWith" label="Created with" />
        </FormRow>
        <TypedField type="switch" id="auth.isActive" label="Is Active?" />
        <TypedField type="switch" id="auth.isComplete" label="Is Complete?" />
        <TypedField
          type="switch"
          id="auth.isEmailVerified"
          label="Is Email verified?"
        />
      </FormCard>

      <AuthPermissions {...(userQuery.data?.User as any)} />
    </FormBuilderV2>
  );
};

export default UpdateUser;
