import {
  useMutation,
  DocumentNode,
  useQuery,
  OperationVariables,
} from "@apollo/client";
import { ApolloContext } from "apollo/client";
import { useEffect } from "react";

const useCoolQuery = <T extends OperationVariables, R>(
  quer: DocumentNode,
  variables: T,
  config?: {
    context?: ApolloContext;
    disableCache?: boolean;
    disableFetchOnLoad?: boolean;
    error?: (e: string) => void;
    success?: (data: R) => void;
    finally?: (data: R) => void;
  }
) => {
  const query = useQuery<R>(quer, {
    context: { clientName: config?.context ?? "mowi" },
    fetchPolicy: config?.disableCache ? "no-cache" : "cache-and-network",
    initialFetchPolicy: config?.disableFetchOnLoad ? "standby" : undefined,
    variables,
  });

  const { loading, data, error, refetch } = query;

  useEffect(() => {
    if (error && error.message.includes("jwt malformed")) {
      localStorage.clear();
      window.location.reload();
    } else if (config?.error && error && !loading) config.error(error.message);
    else if (config?.success && data && !loading) config.success(data);
  }, [error, data as R, loading]);

  return { data, loading, error, refetch } as typeof query;
};

export default useCoolQuery;
