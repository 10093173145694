import { ThemeOverride } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const headingStyle: ThemeOverride = {
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "Quicksand",
      },
      variants: {
        h1: {
          fontSize: "48px",
          fontWeight: 700,
        },
      },
    },
  },
};
