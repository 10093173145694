import { gql } from "@apollo/client";

const POI_FRAGMENT = gql`
  fragment poiFragment on Poi {
    id
    name
    area
    category
    localities
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    permission {
      owner
      isPrivate
    }
    detailedInfo {
      locality {
        it
        en
        de
      }
      importance
      tagServices
    }
    primaryService
    tagFilters
    technicalInfo {
      altimetry {
        highestPoint
      }
    }
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
    }
    contact {
      phone
      address
      social {
        facebookUrl
        instagramUrl
        youtubeUrl
        websiteUrl
        whatsappNumber
      }
    }
    geoLocation {
      size
      label
      color
      coordinatesMultiLineString
      coordinatesPoint
      coordinatesLineString
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const POI_MEDIA_FRAGMENT = gql`
  fragment poiMediaFragment on Poi {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

const USER_POI_FRAGMENT = gql`
  fragment poiFragment on UserPoi {
    id
    name
    area
    category
    localities
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    permission {
      owner
      isPrivate
    }
    detailedInfo {
      locality {
        it
        en
        de
      }
      importance
      tagServices
    }
    primaryService
    tagFilters
    technicalInfo {
      altimetry {
        highestPoint
      }
    }
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesPoint
      coordinatesLineString
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const USER_POI_MEDIA_FRAGMENT = gql`
  fragment poiMediaFragment on UserPoi {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

export const AREA_POIS = gql`
  query POIS($area: ID!) {
    userPois(area: $area) {
      ...poiFragment
    }
  }
  ${USER_POI_FRAGMENT}
`;

export const POI = gql`
  query POI($id: ID!) {
    userPoi(id: $id) {
      id
      ...poiFragment
      ...poiMediaFragment
    }
  }
  ${USER_POI_FRAGMENT}
  ${USER_POI_MEDIA_FRAGMENT}
`;

export const POI_CREATE = gql`
  mutation POI_CREATE(
    $name: String!
    $area: ID!
    $category: String
    $originalStatus: Boolean
    $localities: [String]
    $detailedInfo: PoiDetailedInfoInput
    $primaryService: String
    $typology: String
    $tagFilters: [String]
    $technicalInfo: PoiTechnicalInfoInput
    $description: PoiDescriptionInput
    $media: PoiMediaInput
    $geoLocation: PoiGeoLocationInput
    $externalInfo: PoiExternalInfoInput
    $permission: PoiPermissionInput
    $sponsor: PoiSponsorInput
    $contact: PoiContactInput
    $files: [StorageFileInput]
  ) {
    createPoi(
      name: $name
      area: $area
      category: $category
      originalStatus: $originalStatus
      localities: $localities
      detailedInfo: $detailedInfo
      primaryService: $primaryService
      typology: $typology
      tagFilters: $tagFilters
      technicalInfo: $technicalInfo
      description: $description
      media: $media
      geoLocation: $geoLocation
      externalInfo: $externalInfo
      permission: $permission
      sponsor: $sponsor
      contact: $contact
      files: $files
    ) {
      ...poiFragment
      ...poiMediaFragment
    }
  }
  ${POI_FRAGMENT}
  ${POI_MEDIA_FRAGMENT}
`;

export const POI_DELETE = gql`
  mutation POI_DELETE($id: ID!) {
    deletePoi(id: $id) {
      id
    }
  }
`;

export const POI_UPDATE = gql`
  mutation POI_UPDATE(
    $id: ID!
    $name: String!
    $area: ID!
    $category: String
    $originalStatus: Boolean
    $localities: [String]
    $detailedInfo: PoiDetailedInfoInput
    $primaryService: String
    $typology: String
    $tagFilters: [String]
    $technicalInfo: PoiTechnicalInfoInput
    $description: PoiDescriptionInput
    $media: PoiMediaInput
    $geoLocation: PoiGeoLocationInput
    $externalInfo: PoiExternalInfoInput
    $permission: PoiPermissionInput
    $sponsor: PoiSponsorInput
    $contact: PoiContactInput
    $files: [StorageFileInput]
  ) {
    updatePoi(
      id: $id
      name: $name
      area: $area
      category: $category
      originalStatus: $originalStatus
      localities: $localities
      detailedInfo: $detailedInfo
      primaryService: $primaryService
      typology: $typology
      tagFilters: $tagFilters
      technicalInfo: $technicalInfo
      description: $description
      media: $media
      geoLocation: $geoLocation
      externalInfo: $externalInfo
      permission: $permission
      sponsor: $sponsor
      contact: $contact
      files: $files
    ) {
      ...poiFragment
    }
  }
  ${POI_FRAGMENT}
`;
