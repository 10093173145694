import { Button, Flex, Grid, Tab, TabList, Tabs } from "@chakra-ui/react";
import { useBehave, useTask } from "behave-react";
import { useEffect, useState } from "react";
import { AreasAction, AreasSelect } from "store/modules/areas";

import { IoAdd } from "react-icons/io5";
import { NavLink, useParams } from "react-router-dom";

import Card from "components/layout/Card/Card";
import TableComponent from "components/layout/TableComponent";
import useCoolQuery from "hooks/useCoolQuery";
import { AREA_NOTIFICATIONS } from "apollo/queries/notifications";
import NotificationsColumns from "./TableColumns";

const TablePage = () => {
  const { id } = useParams<any>();

  const { data, loading, refetch } = useCoolQuery<
    GetAreaInfo,
    GetNotificationResponse
  >(AREA_NOTIFICATIONS, { area: id ?? "" });

  const handleQuery = (v: string, d: Notification) => d.title.includes(v);

  return (
    <Card pl="0" pr="0" flexDirection="column">
      <TableComponent
        action2={
          <NavLink to="create">
            <Button variant="brand" leftIcon={<IoAdd />}>
              Add new
            </Button>
          </NavLink>
        }
        title="Notifications"
        query={handleQuery}
        isLoading={loading}
        onRefresh={refetch}
        queryPlaceholder="Search for notification"
        columns={NotificationsColumns}
        data={data?.areaAllNotifications}
      />
    </Card>
  );
};

export default TablePage;
