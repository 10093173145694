import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Card from "components/layout/Card/Card";

import { useBehave } from "behave-react";

import useCoolMutation from "hooks/useCoolMutation";
import { SessionAction } from "store/modules/session";
import { useForm } from "react-hook-form";

import { LOGIN_USER } from "apollo/queries/auth";
import { ApolloConfig } from "apollo/config";
import InputField from "components/layout/FormBuilderV2/Fields/InputField";

const application = ApolloConfig.application;

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();

  const { dispatch } = useBehave();
  const toast = useToast();

  const [login, { data, loading, error }] = useCoolMutation<
    LoginResponse,
    LoginForm
  >(LOGIN_USER, {
    clientName: "auth",
    error: () => handleResponse(),
    success: () => handleResponse(),
  });

  const handleLogin = (variables: LoginForm) => {
    variables.application = application;
    const payload = { variables };
    login(payload);
  };

  const handleResponse = () => {
    if (error || data?.loginUser?.info) {
      toast({
        title: "Error",
        description: error ? "Generic error occurred" : data?.loginUser?.info,
        position: "bottom-right",
        status: "error",
        duration: 2000,
      });
    } else if (data?.loginUser.accessToken) {
      dispatch(SessionAction.authenticate(data));
    }
  };

  return (
    <Flex width="100%" height="100vh" flexDirection="column">
      <Box
        background="linear-gradient(135deg, #136EF8 0%, #004FCE 100%)"
        flex={1}
      />
      <Box flex={1} />
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        pos="absolute"
      >
        <Container flexDirection="column" display="flex">
          <Card flexDirection="column" gap="12px" p="32px">
            <Heading>Welcome Admin!</Heading>
            <Text>Welcome back to the admin dashboard</Text>
            <form autoComplete="none" onSubmit={handleSubmit(handleLogin)}>
              <VStack mt="24px">
                <InputField
                  errors={errors}
                  placeholder="Enter email"
                  label="Email"
                  id="email"
                  type="email"
                  formRef={register("email", { required: true })}
                />

                <InputField
                  placeholder="Password"
                  label="Password"
                  id="password"
                  type="password"
                  errors={errors}
                  formRef={register("password", { required: true })}
                />

                <Button
                  isLoading={loading}
                  type="submit"
                  marginTop="32px !important"
                  width="180px"
                  variant="brand"
                >
                  Login
                </Button>
              </VStack>
            </form>
          </Card>
        </Container>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
