import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import * as selectors from "./selectors";
import saga from "./saga";

const MODULE = "users";

const INITIAL_STATE: UsersModuleState = {};

const slice = createSlice({
  initialState: INITIAL_STATE,
  name: MODULE,
  reducers: { ...reducers.actions, ...reducers.saga },
});

type RS = keyof typeof reducers.saga;
export type UsersSagas = `${typeof MODULE}/${RS}`;
export const UsersAction = slice.actions;
export const UsersSelect = selectors;
export const UsersSaga = saga;
export const UsersInitialState = INITIAL_STATE;
export default slice.reducer;
