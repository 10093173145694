import { gql } from "@apollo/client";

const IAMS_FRAGMENT = gql`
  fragment iamsFragment on InAppMessage {
    id
    scope
    title {
      it
      en
      de
    }
    description {
      it
      en
      de
    }
    image {
      it
      en
      de
    }
    link {
      it
      en
      de
    }
    repeat
    appMinVersion
    area
  }
`;

export const IAM = gql`
  query InAppMessage($id: ID!) {
    InAppMessage(id: $id) {
      ...iamsFragment
    }
  }
  ${IAMS_FRAGMENT}
`;

export const ALL_IAMS = gql`
  query ALL_IAMS {
    allInAppMessages {
      ...iamsFragment
    }
  }
  ${IAMS_FRAGMENT}
`;

export const CREATE_IAMS = gql`
  mutation areaCreateInAppMessage(
    $scope: AreaInAppMessageScopes!
    $title: DescriptionInput!
    $repeat: AreaInAppMessageRepeat
    $description: DescriptionInput
    $image: ImageInput
  ) {
    areaCreateInAppMessage(
      scope: $scope
      title: $title
      repeat: $repeat
      description: $description
      image: $image
    ) {
      ...iamsFragment
    }
  }
  ${IAMS_FRAGMENT}
`;

export const DELETE_IAMS = gql`
  mutation areaDeleteInAppMessage($id: ID!) {
    areaDeleteInAppMessage(id: $id)
  }
`;

export const UPDATE_IAMS = gql`
  mutation updateInAppMessage(
    $id: ID!
    $scope: String!
    $title: DescriptionInput
    $description: DescriptionInput
    $image: DescriptionInput
    $link: DescriptionInput
    $repeat: String
    $appMinVersion: String
    $area: ID
  ) {
    updateInAppMessage(
      id: $id
      scope: $scope
      title: $title
      description: $description
      image: $image
      link: $link
      repeat: $repeat
      appMinVersion: $appMinVersion
      area: $area
    ) {
      ...iamsFragment
    }
  }
  ${IAMS_FRAGMENT}
`;
