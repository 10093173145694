import {
  Button,
  Flex,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ADMIN_ADD_FILES } from "apollo/queries/auth";
import useCoolMutation from "hooks/useCoolMutation";
import { useEffect, useState } from "react";
import { useFilePicker } from "use-file-picker";
import urlToFile from "utils/urlToFile";
import { Field, FormCard, FormRow } from "../FormBuilderV2";

interface MediaSelectorProps {
  values?: Partial<Poi["media"]>;
  hideCover?: boolean;
  onInsert: (value: string) => void;
  onRemove: (value: string) => void;
  onChange: (values: Partial<Poi["media"]>) => void;
  type: FileScope;
}

const MediaSelector = ({
  values,
  onChange,
  onInsert,
  onRemove,
  type,
  hideCover,
}: MediaSelectorProps) => {
  const toast = useToast();

  const [mode, setMode] = useState<"cover" | "list">("cover");
  const [uploading, setUploading] = useState(false);

  const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
  });

  const [uploadMedia] = useCoolMutation<any, AdminAddFiles>(ADMIN_ADD_FILES, {
    error: (e) => {
      toast({ title: "Error", status: "error", description: e });
      setUploading(false);
    },
    hasUpload: true,
  });

  const handleChange = (cover: any, pictures: any) => {
    onChange({
      cover,
      pictures,
    });
  };

  const handleUpload = async () => {
    setUploading(true);
    const file = await urlToFile(filesContent[0].content, "12-image");

    const { data } = await uploadMedia({
      variables: {
        media: [file],
        scope: type,
      },
    });

    if (mode === "cover") {
      const url = data?.AdminAddFiles?.[0].url ?? "";
      const _id = data?.AdminAddFiles?.[0]._id ?? "";
      onInsert(_id);
      handleChange({ url, _id }, values?.pictures);
    } else {
      const url = data?.AdminAddFiles?.[0].url ?? "";
      const _id = data?.AdminAddFiles?.[0]._id ?? "";
      onInsert(_id);
      handleChange(values?.cover, [...(values?.pictures ?? []), { url, _id }]);
    }

    setUploading(false);
  };

  const removeCover = () => {
    onRemove(values?.cover?._id ?? "");
    handleChange(undefined, values?.pictures);
  };

  const removeImage = (id: string) => {
    onRemove(id);
    const images = values?.pictures?.filter((i: any) => i._id !== id);
    handleChange(values?.cover, images);
  };
  const coverClick = () => {
    setMode("cover");
    openFileSelector();
  };

  const addImage = () => {
    setMode("list");
    openFileSelector();
  };

  useEffect(() => {
    if (filesContent?.length) handleUpload();
  }, [filesContent]);

  return (
    <FormCard fullWidth={hideCover}>
      <Flex direction="column" w="100%">
        <Text fontSize="24px" fontWeight="600">
          Media
        </Text>
        {!hideCover && (
          <>
            <Flex alignItems="center" justifyContent="space-between" mt="12px">
              <Text fontWeight="bold" fontSize="18px">
                Cover
              </Text>
              <Button
                ms="32px"
                colorScheme="red"
                size="xs"
                variant="link"
                onClick={removeCover}
              >
                Remove
              </Button>
            </Flex>
            <Flex position="relative" justifyContent="center">
              <Image
                borderRadius="16px"
                src={values?.cover?.url ?? "/imgs/areaPlaceholder.png"}
                maxHeight="300px"
                backgroundImage={"/imgs/transparent_bg.png"}
                objectFit="cover"
                cursor="pointer"
                border="1px solid #ddd"
                onClick={coverClick}
              />
              {uploading && mode === "cover" && (
                <Spinner position="absolute" left="48%" top="40%" />
              )}
            </Flex>
          </>
        )}
        <Flex direction={hideCover ? "row" : "column"} w="100%" gap="24px">
          <Flex direction="column" flex="1">
            <Text fontWeight="bold" ml="4px" mt="12px">
              Videos
            </Text>
            <Field
              type="array"
              id="media.videos"
              element={(id) => (
                <FormRow>
                  <Field type="text" id={id + "url"} placeholder="Url" />
                </FormRow>
              )}
            />
          </Flex>
          <Flex direction="column" flex="1">
            <Text fontWeight="bold" ml="4px" mt="12px">
              Images
            </Text>
            <Button
              borderStyle="dashed"
              variant="outline"
              onClick={addImage}
              isLoading={uploading && mode === "list"}
            >
              Add
            </Button>

            {values?.pictures?.map((value: any, i: number) => (
              <Flex
                alignItems="center"
                gap="16px"
                mt="18px"
                border="1px solid #eee"
                borderRadius="8px"
                padding="12px"
                direction="row"
              >
                <Image
                  src={value.url}
                  width="30%"
                  maxH="100px"
                  borderRadius={8}
                  backgroundImage={"/imgs/transparent_bg.png"}
                />
                <Flex
                  flex={1}
                  direction="column"
                  gap="16px"
                  height="100%"
                  alignItems="start"
                >
                  <Text ml="4px" fontSize="14px" flex="1">
                    {i + 1}. {value._id}
                  </Text>
                  <Button
                    colorScheme="red"
                    size="xs"
                    onClick={() => removeImage(value._id)}
                  >
                    Remove
                  </Button>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </FormCard>
  );
};

export default MediaSelector;
