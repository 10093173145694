import { PAction } from "behave-react";
import { UsersInitialState } from ".";

const AsyncReducers = {};

const Reducers = {
  setUsers: (state: UsersModuleState, action: PAction<User[]>) => {
    state.users = action.payload;
  },
  purge: (state: UsersModuleState) => ({ ...UsersInitialState }),
};

const AreasReducers = { actions: Reducers, saga: AsyncReducers };
export default AreasReducers;
