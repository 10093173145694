import { Fade, Flex, Text } from "@chakra-ui/react";
import Card from "components/layout/Card/Card";
import { FieldValues } from "react-hook-form";

import { FormCardProps } from "../types";

const FormCard = <T extends FieldValues>(card: FormCardProps<T>) => {
  if (card.hide) return null;
  return (
    <Card
      direction="column"
      maxW={card.fullWidth ? undefined : "calc(max(50% - 12px , 500px))"}
      gap="24px"
      alignItems="end"
    >
      <Flex w="100%" alignItems="center" gap="24px">
        <Flex direction="column" flex="1">
          <Text fontSize="24px" fontWeight="700">
            {card.title}
          </Text>
          {card.subtitle}
        </Flex>
        {card.rightAction}
      </Flex>
      {!card.collapsed && card.children && (
        <Flex gap="12px" direction="column" w="100%">
          {card.children}
        </Flex>
      )}
    </Card>
  );
};

export default FormCard;
