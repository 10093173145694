import { Skeleton, Stack } from "@chakra-ui/react";

const AreaSkeleton = () => {
  return (
    <>
      <Skeleton
        height="300px"
        opacity="0.2"
        borderRadius="20px"
        maxW={{ md: "400px", base: "100%" }}
      />
      <Skeleton
        opacity="0.2"
        borderRadius="20px"
        height="300px"
        maxW={{ md: "400px", base: "100%" }}
      />
      <Skeleton
        opacity="0.2"
        borderRadius="20px"
        height="300px"
        maxW={{ md: "400px", base: "100%" }}
      />
      <Skeleton
        opacity="0.2"
        borderRadius="20px"
        height="300px"
        maxW={{ md: "400px", base: "100%" }}
      />
      <Skeleton
        opacity="0.2"
        borderRadius="20px"
        height="300px"
        maxW={{ md: "400px", base: "100%" }}
      />
      <Skeleton
        opacity="0.2"
        borderRadius="20px"
        height="300px"
        maxW={{ md: "400px", base: "100%" }}
      />
    </>
  );
};

export default AreaSkeleton;
