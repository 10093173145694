import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useCollQuery from "hooks/useCoolQuery";

import {
  FormBuilderV2,
  Field,
  FormCard,
  FormRow,
} from "components/layout/FormBuilderV2";
import { AREA_COMPLETE, DELETE_AREA, UPDATE_AREA } from "apollo/queries/areas";
import UpdateAreaAssets from "./components/UpdateAreaAssets";

import { AREA_DOMAINS, MOWI_TYPES, SUGGESTER_PERIOD } from "utils/DataMap";
import moment from "moment";
import useRefreshToken from "hooks/useRefreshToken";
import useCoolMutation from "hooks/useCoolMutation";
import {
  Box,
  Button,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import CleanPayload from "utils/CleanPayload";
import urlToFile from "utils/urlToFile";
import BundleCategory from "./components/BundleCategory";
import { ADMIN_ADD_FILES } from "apollo/queries/auth";
import MediaSelector from "components/layout/MediaSelector";

const TypedField = Field<Area>;

const UpdateArea = () => {
  const toast = useToast();
  const { id } = useParams<BasicID>();
  const [media, setMedia] = useState<Partial<AreaMedia>>();

  const { loading, data, refetch } = useCollQuery<BasicID, GetArea>(
    AREA_COMPLETE,
    {
      id: id ?? "",
    }
  );
  const navigate = useNavigate();
  const { loading: loadingToken } = useRefreshToken(refetch, id);
  const [assets, setAssets] = useState<Partial<AreaAsset>>({});

  const [track, setTracks] = useState(data?.Area?.bundle.track);
  const [poi, setPoi] = useState(data?.Area?.bundle.poi);
  const [transport, setTransport] = useState(data?.Area?.bundle.transport);
  const [isComingSoon, setIsComingSoon] = useState<string>();
  const [fileToInsert, setFilesToInsert] = useState<string[]>([]);
  const [fileToRemove, setFilesToRemove] = useState<string[]>([]);

  const [update, updateStatus] = useCoolMutation(UPDATE_AREA, {
    error: (e) => toast({ title: "Error", status: "error", description: e }),
    success: (e) =>
      toast({
        title: "Succesfull",
        status: "success",
        description: "Area updated successfully",
      }),
  });

  const [deletee, deleteStatus] = useCoolMutation(DELETE_AREA, {
    error: (e) => toast({ title: "Error", status: "error", description: e }),
    success: (e) => {
      toast({
        title: "Succesfull",
        status: "success",
        description: "Area deleted successfully",
      });
      navigate("/areas");
    },
  });

  const handleUpdate = async (payload: Partial<Area>) => {
    const variables = CleanPayload({
      ...payload,
      isCoomingSoon: isComingSoon,
      bundle: {
        ...payload.bundle,
        track,
        poi,
        transport,
      },
    });
    delete variables.domain;
    variables.assets = {
      cover: { url: assets?.cover?.url, _id: assets?.cover?._id },
      logo: { url: assets?.logo?.url, _id: assets?.logo?._id },
      logoUrl: assets?.logoUrl,
      coverUrl: assets?.coverUrl,
    };
    variables.media = {
      cover: { url: media?.cover?.url, _id: media?.cover?._id },
      pictures: media?.pictures?.map((pic) => ({
        url: pic.url,
        _id: pic._id,
      })),
      videos: payload.media?.videos?.map((pic) => ({
        url: pic.url,
      })),
    };

    const parsedTopolgyes: AreaMapTopology[] = variables.map?.topology?.map(
      (p: any) => ({
        ...p,
        geometry: {
          ...p.geometry,
          coordinates: JSON.parse(p.geometry.coordinates ?? "[]"),
        },
      })
    );

    if (variables.map) variables.map.topology = parsedTopolgyes;

    const files = [
      ...fileToInsert
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "insert" })),
      ...fileToRemove
        .filter((a) => a)
        .map((fileId) => ({ fileId, action: "remove" })),
    ];

    variables.files = files;
    update({ variables });
  };

  useEffect(() => {
    setAssets(data?.Area?.assets ?? {});
    setTracks(data?.Area?.bundle.track);
    setPoi(data?.Area?.bundle.poi);
    setTransport(data?.Area?.bundle.transport);
    setIsComingSoon(
      data?.Area?.isCoomingSoon
        ? moment(data?.Area?.isCoomingSoon, moment.ISO_8601).format(
            "YYYY-MM-DD"
          )
        : undefined
    );
    setMedia(data?.Area?.media);
  }, [data]);

  return (
    <FormBuilderV2
      initialValues={CleanPayload(data?.Area)}
      loading={loading || loadingToken ? "Loading area..." : undefined}
      editMode
      fallback="No area found"
      submit={{
        action: "Update",
        onSubmit: handleUpdate,
        loading: updateStatus.loading,
        color: "brand",
        title: "Update area",
        subtitle: "This will update the area.",
      }}
    >
      <FormCard
        title={data?.Area.name}
        subtitle="Here you can set and update the informations about the area"
      >
        <FormRow>
          <TypedField id="name" type="text" label="Name" required />
          <TypedField id="nameRef" type="text" label="NameRef" required />
        </FormRow>
        <FormRow>
          <TypedField id="locality" type="text" label="Locality" required />
          <TypedField
            id="mowiType"
            type="select"
            label="Mowi Type"
            required
            options={MOWI_TYPES}
          />
        </FormRow>
        <TypedField
          value={!!isComingSoon}
          type="switch"
          label="Is coming soon?"
          description="Is this area a coming soon?"
          onChange={() =>
            setIsComingSoon((v) =>
              !v
                ? moment(data?.Area?.isCoomingSoon, moment.ISO_8601).format(
                    "YYYY-MM-DD"
                  )
                : undefined
            )
          }
        />

        <TypedField
          type="date"
          hidden={!isComingSoon}
          value={isComingSoon}
          onChange={(v) => {
            setIsComingSoon(v.currentTarget.value);
          }}
        />
        <TypedField
          label="Is Disabeld?"
          id="isDisabled"
          description="Area will not be available"
          type="switch"
        />
        <TypedField
          type="switch"
          label="Is Open?"
          id="season.isOpen"
          description="Area will be open"
        />
        <TypedField
          type="textArea"
          label="Description"
          id="description"
          placeholder="Add a description"
        />
      </FormCard>
      <UpdateAreaAssets
        onInsert={(f) => setFilesToInsert([...fileToInsert, f])}
        onRemove={(f) => setFilesToRemove([...fileToRemove, f])}
        onCoverChange={(cover) =>
          setAssets({ ...assets, cover, coverUrl: cover?.url })
        }
        onLogoChange={(logo) =>
          setAssets({ ...assets, logo, logoUrl: logo?.url })
        }
        logo={assets.logo}
        cover={assets.cover}
      />
      <FormCard title="Infos">
        <Tabs>
          <TabList>
            <Tab>Italian</Tab>
            <Tab>English</Tab>
            <Tab>Deutsch</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="textArea"
                label="Description long IT"
                placeholder="Add a description"
                id="info.description.long.it"
              />
              <TypedField
                type="text"
                label="Description short IT"
                placeholder="Short description"
                id="info.description.short.it"
              />
              <TypedField
                type="text"
                label="How to get IT"
                placeholder="How to get"
                id="info.location.howToGet.description.it"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="textArea"
                label="Description long EN"
                placeholder="Add a description"
                id="info.description.long.en"
              />
              <TypedField
                type="text"
                label="Description short EN"
                placeholder="Short description"
                id="info.description.short.en"
              />
              <TypedField
                type="text"
                label="How to get EN"
                placeholder="How to get"
                id="info.location.howToGet.description.en"
              />
            </TabPanel>
            <TabPanel p="16px 0px" gap="12px" display="flex" flexDir="column">
              <TypedField
                type="textArea"
                label="Description long DE"
                placeholder="Add a description"
                id="info.description.long.de"
              />
              <TypedField
                type="text"
                label="Description short De"
                placeholder="Short description"
                id="info.description.short.de"
              />
              <TypedField
                type="text"
                label="How to get DE"
                placeholder="How to get"
                id="info.location.howToGet.description.de"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Divider />
        <TypedField
          id="info.suggestedPeriod"
          transform="strArrayToInt"
          label="Suggested period"
          type="tags"
          options={SUGGESTER_PERIOD}
          filterOptions={(tags, value: any) =>
            !tags.find((a) => String(a) === String(value.value))
          }
        />
      </FormCard>

      <FormCard title="Where">
        <FormRow>
          <TypedField id="info.location.state" type="text" label="State" />
          <TypedField id="info.location.region" type="text" label="Region" />
        </FormRow>
        <FormRow>
          <TypedField
            id="info.location.locality"
            type="text"
            label="Locality"
          />
          <TypedField
            id="info.location.howToGet.street"
            type="text"
            label="How to get Street"
          />
        </FormRow>
        <TypedField
          id="info.location.howToGet.coords"
          type="text"
          label="Coordinates [lat,long]"
          transform="jsonToString"
        />
        <TypedField id="info.map.extension" type="number" label="Extension" />
        <FormRow>
          <TypedField
            id="info.map.maxHeight"
            type="number"
            label="Max Height"
          />
          <TypedField
            id="info.map.minHeight"
            type="number"
            label="Min Height"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="info.map.totalTracks"
            type="number"
            label="Total tracks"
          />
          <TypedField
            id="info.map.totalTransports"
            type="number"
            label="Total transports"
          />
        </FormRow>
        <TypedField
          id="info.map.totalTracksExtension"
          type="number"
          rightAddon="mt"
          label="Total track extension"
        />
      </FormCard>
      <FormCard title="Contacts">
        <FormRow>
          <TypedField
            id="info.contacts.phoneNumber"
            type="phone"
            label="Phone"
          />
          <TypedField id="info.contacts.webSite" type="text" label="Website" />
        </FormRow>
        <TypedField id="info.contacts.email" type="email" label="Email" />
      </FormCard>
      <FormCard title="Bundle info">
        <TypedField id="bundle.version" type="text" label="Version" />
      </FormCard>
      <FormCard title="Sponsors">
        <TypedField
          type="array"
          id="info.sponsors"
          element={(id) => (
            <FormRow>
              <Field type="text" id={id + "name"} label="Name" />
              <Field type="text" id={id + "image"} label="Image" />
            </FormRow>
          )}
        />
      </FormCard>
      <FormCard title="Webcams">
        <TypedField
          type="array"
          id="webcams"
          element={(id) => (
            <>
              <Field type="text" id={id + "url"} label="URL" />

              <FormRow>
                <Field type="text" id={id + "name.it"} label="Name IT" />
                <Field type="text" id={id + "name.en"} label="Name EN" />
                <Field type="text" id={id + "name.de"} label="Name DE" />
              </FormRow>
            </>
          )}
        />
      </FormCard>
      <FormCard title="Wheater Forecast">
        <TypedField
          type="array"
          id="weatherForecast"
          element={(id) => (
            <>
              <Field type="text" id={id + "name"} label="Name" />

              <FormRow>
                <Field type="text" id={id + "latitude"} label="Latitude" />
                <Field type="text" id={id + "longitude"} label="Longitude" />
              </FormRow>
            </>
          )}
        />
      </FormCard>
      <BundleCategory
        key="tracksBundle"
        onUpdate={setTracks}
        title="Bundle Track"
        category={track}
      />

      <BundleCategory
        key="poiBundle"
        title="Bundle Poi"
        category={poi}
        onUpdate={setPoi}
      />

      <BundleCategory
        key="transportBundle"
        title="Bundle Transport"
        category={transport}
        onUpdate={setTransport}
      />
      <FormCard title="Map general" fullWidth>
        <TypedField type="text" id="map.version" label="Version" />
        <FormRow>
          <TypedField
            id="map.textures"
            type="tags"
            label="Textures"
            transform="strArrayToFloat"
          />
          <TypedField
            id="map.texturesLow"
            type="tags"
            label="Textures Low"
            transform="strArrayToFloat"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="map.limits.geo"
            type="tags"
            label="Limits GEO"
            transform="strArrayToFloat"
          />
          <TypedField
            id="map.limits.height"
            type="tags"
            label="Limit heigh"
            transform="strArrayToFloat"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="map.coordsCenter"
            type="tags"
            transform="strArrayToFloat"
            label="Coords Center"
          />
          <TypedField
            id="map.resolution"
            type="tags"
            transform="strArrayToFloat"
            label="Resolution"
          />
        </FormRow>
        <FormRow>
          <TypedField
            id="map.modelData.precision"
            type="tags"
            transform="strArrayToFloat"
            label="Model data precision"
          />
          <TypedField
            id="map.modelData.subdivisions"
            transform="strArrayToFloat"
            type="tags"
            label="Model data subdivision"
          />
        </FormRow>
        <Text fontWeight="600">Topologies</Text>
        <TypedField
          type="array"
          id="map.topology"
          element={(id) => (
            <>
              <FormRow>
                <Field type="text" id={id + "type"} label="Type" />
                <Field type="text" id={id + "properties.name"} label="Name" />
              </FormRow>
              <FormRow>
                <Field
                  type="text"
                  id={id + "geometry.type"}
                  label="Geometry Type"
                />
                <Field
                  type="text"
                  id={id + "geometry.coordinates"}
                  label="Geometry coordinates"
                />
              </FormRow>
            </>
          )}
        />
      </FormCard>
      <MediaSelector
        hideCover
        type="areas"
        onInsert={(f) => setFilesToInsert([...fileToInsert, f])}
        onRemove={(f) => setFilesToRemove([...fileToRemove, f])}
        onChange={setMedia}
        values={(media as any) ?? {}}
      />

      <FormCard title="WebEvents " fullWidth>
        <TypedField
          type="array"
          id="info.webEvents"
          bordered
          element={(id) => (
            <>
              <Box w="40%">
                <Field
                  type="switch"
                  id={id + "isActive"}
                  label="Is active?"
                  description="Check if the webevent is active"
                />
              </Box>
              <Tabs w="100%">
                <TabList>
                  <Tab>Italian</Tab>
                  <Tab>English</Tab>
                  <Tab>Deutsch</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel
                    p="16px 0px"
                    gap="12px"
                    display="flex"
                    flexDir="column"
                  >
                    <FormRow>
                      <Field
                        type="text"
                        id={id + "title.it"}
                        label="Title IT"
                      />
                      <Field type="text" id={id + "url.it"} label="Url IT" />
                      <Field
                        type="text"
                        id={id + "image.it"}
                        label="Image IT"
                      />
                    </FormRow>
                  </TabPanel>
                  <TabPanel
                    p="16px 0px"
                    gap="12px"
                    display="flex"
                    flexDir="column"
                  >
                    <FormRow>
                      <Field
                        type="text"
                        id={id + "title.en"}
                        label="Title EN"
                      />
                      <Field type="text" id={id + "url.en"} label="Url EN" />
                      <Field
                        type="text"
                        id={id + "image.en"}
                        label="Image EN"
                      />
                    </FormRow>
                  </TabPanel>
                  <TabPanel
                    p="16px 0px"
                    gap="12px"
                    display="flex"
                    flexDir="column"
                  >
                    <FormRow>
                      <Field
                        type="text"
                        id={id + "title.de"}
                        label="Title DE"
                      />
                      <Field type="text" id={id + "url.de"} label="Url DE" />
                      <Field
                        type="text"
                        id={id + "image.de"}
                        label="Image DE"
                      />
                    </FormRow>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
        />
      </FormCard>
      <FormCard
        title="Delete Area"
        subtitle="Do you want to delete this area?"
        rightAction={
          <Button
            variant="outline"
            isLoading={deleteStatus.loading}
            colorScheme="red"
            onClick={() => deletee({ variables: { id: data?.Area?.id } })}
          >
            Delete
          </Button>
        }
      />
    </FormBuilderV2>
  );
};

export default UpdateArea;
