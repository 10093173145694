import { Flex, Text } from "@chakra-ui/react";
import { EditModeFallback } from "../types";

const FormFallback = ({
  fallback,
}: {
  fallback: EditModeFallback | string;
}) => {
  if (typeof fallback === "string")
    return (
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Text fontSize="24px" fontWeight="700">
          {fallback}
        </Text>
      </Flex>
    );

  return (
    <Flex
      width="100%"
      height="50vh"
      alignItems="center"
      direction="column"
      justifyContent="center"
      gap="12px"
    >
      {fallback.title && (
        <Text fontWeight="bold" fontSize="24px">
          {fallback.title}
        </Text>
      )}
      {fallback.message && <Text opacity="0.5">{fallback.message}</Text>}
      {fallback.content}
      {fallback.action}
    </Flex>
  );
};

export default FormFallback;
