import {
  Badge,
  Box,
  Button,
  Circle,
  Fade,
  Flex,
  Icon,
  Image,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApolloConfig } from "apollo/config";
import Card from "components/layout/Card/Card";
import useRefreshToken from "hooks/useRefreshToken";
import { useEffect } from "react";
import { IoPencil, IoMedal } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { MOWI_TYPE_COLORS } from "utils/DataMap";
import BundleUpdateModal from "./BundleUpdateModal";

const AreaItem = (props: Area) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { trigger } = useRefreshToken(undefined, undefined, true);

  useEffect(() => {
    if (isOpen) trigger(undefined, true);
  }, [isOpen]);

  return (
    <Fade in>
      <Card
        p="16px"
        maxW={{ md: "450px", base: "100%" }}
        zIndex={0}
        key={props.id}
      >
        <NavLink to={`${props.id}/manage`}>
          <Image
            src={props.assets?.coverUrl ?? "imgs/areaPlaceholder.png"}
            h={180}
            w="100%"
            fallbackSrc={"imgs/areaPlaceholder.png"}
            objectFit="cover"
            outline="none"
            backgroundColor="#00000002"
            borderRadius={12}
            border="none"
          />
        </NavLink>
        <Flex dir="row" align="center" mt="16px" mb="12px">
          <Text flex={1} mb="5px" fontWeight="bold">
            {props.name}
          </Text>

          <Tag
            variant="solid"
            colorScheme={
              ApolloConfig.application === "mowiBike" ? "green" : "facebook"
            }
          >
            {ApolloConfig.application === "mowiBike" ? "BIKE" : "SKI"}
          </Tag>
        </Flex>

        <Flex align="center" mt="6px" gap="6px">
          <Badge variant="outline" p="6px">
            V {props.bundle.version ?? "0.0.0"}
          </Badge>

          {props.mowiType && (
            <Box
              w="16px"
              h="16px"
              borderRadius="12px"
              backgroundColor={MOWI_TYPE_COLORS[props.mowiType]}
            />
          )}

          {props.isCoomingSoon && <Badge p="6px">Coming soon</Badge>}
          {props.isDisabled && (
            <Badge p="6px" colorScheme="red">
              Disabled
            </Badge>
          )}

          <Box flex="1" />
          <Button variant="darkBrand" size="sm" onClick={onOpen}>
            Upgrade
          </Button>
        </Flex>
        <BundleUpdateModal isOpen={isOpen} onClose={onClose} area={props} />
      </Card>
    </Fade>
  );
};

export default AreaItem;
