import {
  Tabs,
  TabList,
  Tab,
  Divider,
  useToast,
  TabPanels,
  TabPanel,
  Text,
  Box,
} from "@chakra-ui/react";
import useCoolMutation from "hooks/useCoolMutation";
import { useState } from "react";
import { AREA_DOMAINS, MOWI_TYPES, SUGGESTER_PERIOD } from "utils/DataMap";
import { useNavigate } from "react-router-dom";
import CleanPayload from "utils/CleanPayload";
import useRefreshToken from "hooks/useRefreshToken";
import { CREATE_AREA } from "apollo/queries/areas";
import {
  FormBuilderV2,
  Field,
  FormCard,
  FormRow,
} from "components/layout/FormBuilderV2";
import { ApolloConfig } from "../../apollo/config";
const TypedField = Field<Area>;

const CreateArea = () => {
  const toast = useToast();

  const [isComingSoon, setIsComingSoon] = useState<string>();

  useRefreshToken();

  const navigate = useNavigate();

  const [create, { loading }] = useCoolMutation(CREATE_AREA, {
    error: (e) => toast({ title: "Error", status: "error", description: e }),
    success: (e) => {
      toast({
        title: "Success",
        status: "success",
        description: "Area created successfully",
      });
      navigate("/areas");
    },
  });

  const handleCreate = (data: Partial<Area>) => {
    const variables = CleanPayload(data);

    variables.ya =
      ApolloConfig.application === "mowiBike" ? "mowiBike" : "mowiSnow";

    create({ variables });
  };

  return (
    <FormBuilderV2
      initialValues={{}}
      submit={{
        action: "Create",
        loading,
        onSubmit: handleCreate,
        color: "green",
        title: "Create area",
        subtitle: "This will create a new area.",
      }}
    >
      <FormCard
        title="New Area"
        subtitle="Here you can set and update the informations about the area"
      >
        <FormRow>
          <TypedField id="name" type="text" label="Name" required />
          <TypedField id="nameRef" type="text" label="NameRef" required />
        </FormRow>
        <FormRow>
          <TypedField id="locality" type="text" label="Locality" required />
          <TypedField
            id="mowiType"
            type="select"
            label="Mowi Type"
            required
            options={MOWI_TYPES}
          />
        </FormRow>
        <TypedField
          value={!!isComingSoon}
          type="switch"
          label="Is coming soon?"
          description="Is this area a coming soon?"
          onChange={() =>
            setIsComingSoon(isComingSoon ? undefined : "01-01-2021")
          }
        />

        <TypedField
          type="date"
          hidden={!isComingSoon}
          value={isComingSoon}
          onChange={(v) => {
            setIsComingSoon(v.currentTarget.value);
          }}
        />
        <TypedField
          label="Is Disabeld?"
          id="isDisabled"
          description="Area will not be available"
          type="switch"
        />
        <TypedField
          type="switch"
          label="Is Open?"
          id="season.isOpen"
          description="Area will be open"
        />
        <TypedField
          type="textArea"
          label="Description"
          id="description"
          placeholder="Add a description"
        />
      </FormCard>
    </FormBuilderV2>
  );
};

export default CreateArea;
