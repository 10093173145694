import { Fade, Flex, FlexProps, Text } from "@chakra-ui/react";
import Card from "components/layout/Card/Card";

const FormRow = ({ children }: FlexProps) => {
  return (
    <Flex gap="12px" width="100%">
      {children}
    </Flex>
  );
};

export default FormRow;
