import { PAction } from "behave-react";
import parseJwt from "utils/JWTParse";
import { SessionInitialState } from ".";

const AsyncReducers = {};

const Reducers = {
  authenticate: (state: SessionModuleState, action: PAction<LoginResponse>) => {
    localStorage.setItem("accessToken", action.payload.loginUser.accessToken);
    localStorage.setItem("refreshToken", action.payload.loginUser.refreshToken);
    state.authenticated = true;
    state.expiresAt =
      parseInt(parseJwt(action.payload.loginUser.accessToken)?.["exp"]) * 1000;
    state.token = action.payload.loginUser.accessToken;
    state.refreshToken = action.payload.loginUser.refreshToken;
    state.user = action.payload.loginUser.user;
  },
  purge: () => {
    localStorage.clear();
    return { ...SessionInitialState };
  },
};

const SessionReducers = { actions: Reducers, saga: AsyncReducers };
export default SessionReducers;
