import { Flex, Spinner, Text } from "@chakra-ui/react";

const FormLoading = ({ loading }: { loading: boolean | string }) => {
  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      direction="column"
      gap="12px"
      justifyContent="center"
    >
      <Spinner margin="0px 24px" />
      {typeof loading === "string" && <Text opacity="0.5">{loading}</Text>}
    </Flex>
  );
};

export default FormLoading;
