import { NavLink, useParams } from "react-router-dom";
import Columns from "./TableColumns";
import Card from "components/layout/Card/Card";
import useCoolQuery from "hooks/useCoolQuery";

import TableComponent from "components/layout/TableComponent";
import { AREA_TRANSPORTS } from "apollo/queries/transports";
import { Button } from "@chakra-ui/react";
import { IoAdd } from "react-icons/io5";

const TablePage = () => {
  const { id } = useParams<any>();

  const { data, loading, refetch } = useCoolQuery<
    GetAreaInfo,
    GetTransportsResponse
  >(AREA_TRANSPORTS, { area: id ?? "" });

  const handleQuery = (v: string, d: Transport) =>
    d.id.includes(v) || d.name.includes(v);

  return (
    <Card pl="0" pr="0" flexDirection="column">
      <TableComponent
        title="Transports"
        isLoading={loading}
        query={handleQuery}
        queryPlaceholder="Search for Transports"
        columns={Columns}
        data={data?.userTransports}
        onRefresh={() => refetch()}
        action2={
          <NavLink to="create">
            <Button variant="brand" leftIcon={<IoAdd />}>
              Add new
            </Button>
          </NavLink>
        }
      />
    </Card>
  );
};

export default TablePage;
