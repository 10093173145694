import { Flex, Text, Divider, Tag } from "@chakra-ui/react";
import { ApolloConfig } from "apollo/config";
import { NavLink } from "react-router-dom";

const SidebarBrand = () => {
  return (
    <Flex align="center" direction="column">
      <NavLink to="/areas">
        <Flex w="100%" align="center" gap="24px" mb="12px">
          <Text fontWeight="bold" fontSize="24px">
            Mowi Admin
            <Text fontSize="12px" opacity={0.5}>
              {process.env.REACT_APP_VERSION}
            </Text>
          </Text>

          <Tag
            variant="solid"
            colorScheme={
              ApolloConfig.application === "mowiBike" ? "green" : "facebook"
            }
          >
            {ApolloConfig.application === "mowiBike" ? "BIKE" : "SKI"}
          </Tag>
        </Flex>
      </NavLink>
      <Divider />
    </Flex>
  );
};

export default SidebarBrand;
