interface ApolloConfigType {
  application: "mowiBike" | "mowiSki";
  authUrl: string;
  mowiUrl: string;
}
export const ApolloConfig: ApolloConfigType = {
  application: "mowiBike",
  authUrl: "https://auth.mowi.space/graphql",
  mowiUrl: "https://backend.mowi.ski/graphql",
};
