import React, { useEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorModeValue,
  FormControl,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import { FormFieldComponent } from "../types";
import access from "utils/DotNotation";

const TagsField = (props: FormFieldComponent<any>) => {
  const {
    label,
    id,
    value,
    onChange,
    errors,
    placeholder,
    options,
    filterOptions,
  } = props;

  const [tags, setTags] = useState<string[]>(value ?? []);

  const handleSelect = (val: string) => {
    setTags((t) => [...t, val]);
  };

  const keyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const val = e.currentTarget.value;
      setTags((t) => [...t, val]);
      e.currentTarget.value = "";
    }
  };

  useEffect(() => {
    if (tags) onChange?.(tags);
  }, [tags]);

  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const bg = useColorModeValue("brand.500", "brand.400");
  return (
    <Flex flex="1" mt="12px">
      <FormControl isInvalid={errors?.password}>
        <FormLabel
          ms="6px"
          htmlFor={id}
          fontWeight="bold"
          fontSize="sm"
          mb="8px"
        >
          {label}
        </FormLabel>
        <Flex
          direction="row"
          p="12px"
          wrap="wrap"
          bg="transparent"
          border="1px solid"
          borderColor={borderColor}
          borderRadius="16px"
          _focus={{ borderColor: "teal.300" }}
          minH="40px"
          h="stretch"
          cursor="text"
        >
          {tags?.map((tag, index) => {
            return (
              <Tag
                fontSize="xs"
                h="25px"
                mb="6px"
                me="6px"
                borderRadius="12px"
                variant="solid"
                bg={bg}
                key={index}
              >
                <TagLabel w="100%">
                  {options
                    ? options.find((a) => String(a.value) === String(tag))
                        ?.name ?? tag
                    : tag}
                </TagLabel>
                <TagCloseButton
                  justifySelf="flex-end"
                  color="white"
                  onClick={() =>
                    setTags([...tags.filter((element) => element !== tag)])
                  }
                />
              </Tag>
            );
          })}
          {options ? (
            <Select
              borderRadius="16px"
              h="42px"
              variant="filled"
              border="none"
              
              onChange={(e) =>
                e.currentTarget?.value && handleSelect(e.currentTarget.value)
              }
              fontSize="sm"
              placeholder={placeholder}
            >
              {options
                ?.filter((a) => filterOptions?.(tags, a) ?? true)
                .map((e) => (
                  <option key={e.value + id} value={e.value}>
                    {e.name}
                  </option>
                ))}
            </Select>
          ) : (
            <Input
              variant="filled"
              border="none"
              onKeyDown={(e) => e.currentTarget?.value && keyPress(e)}
              fontSize="sm"
              placeholder={placeholder}
            />
          )}
        </Flex>
        <FormErrorMessage fontSize="smaller">
          {access(errors, id) &&
            (access(errors, id).message?.lenght > 0
              ? access(errors, id).message
              : "*  This field is required")}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
};

export default TagsField;
