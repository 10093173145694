import {
  Badge,
  Button,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ApolloConfig } from "apollo/config";
import { UPDATE_USER } from "apollo/queries/users";
import { useBehave } from "behave-react";
import Card from "components/layout/Card/Card";
import useCoolMutation from "hooks/useCoolMutation";
import { useState } from "react";
import { MdAdd, MdDelete } from "react-icons/md";
import { AreasSelect } from "store/modules/areas";
import select from "utils/ArrayUtils";
import CleanPayload from "utils/CleanPayload";

import AreaSelectPopup from "./AreaSelectPopup";
import ScopeSelectPopup from "./ScopeSelectPopup";

const AuthPermissions = (user?: User) => {
  const toast = useToast();
  const Behave = useBehave();
  const areas = Behave.select(AreasSelect.areas);
  const areaName = (id: string) => areas?.find((a) => a.id === id)?.name ?? id;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedScope, selectScope] = useState<string>();
  const [permissions, setPermissions] = useState<UserAuthPermission[]>(
    CleanPayload(user)?.auth.permissions ?? []
  );

  const [updateUser, { loading }] = useCoolMutation(UPDATE_USER, {
    clientName: "auth",
    error: (e) => toast({ title: "Error", status: "error", description: e }),
    success: (e) => {
      toast({
        title: "Success",
        status: "success",
        description: "User permissions updated succesfully",
      });
    },
  });

  const reset = () => {
    setPermissions(CleanPayload(user)?.auth.permissions ?? []);
  };

  const handleAddAreaPermission = (area: Area) => {
    onClose();
    setPermissions([
      ...permissions,
      { area: area.id, application: ApolloConfig.application, scopes: [] },
    ]);
  };

  const handleRemoveAreaPermission = (area: string) => {
    setPermissions(permissions.filter((p) => p.area !== area));
  };

  const handleAddPermission = (permission: string) => {
    if (!selectedScope) return;
    const perms: UserAuthPermission = {
      ...select(permissions, "area", selectedScope).element,
    };
    const temp = [...permissions];
    perms.scopes = [...perms.scopes, permission];
    select(temp, "area", selectedScope).set(perms);
    setPermissions(temp);
    selectScope(undefined);
  };
  const handleRemovePermission = (area: string, permission: string) => {
    const perms: UserAuthPermission = {
      ...select(permissions, "area", area).element,
    };
    const temp = [...permissions];
    perms.scopes = perms.scopes.filter((p) => p !== permission);
    select(temp, "area", area).set(perms);
    setPermissions(temp);
  };

  return (
    <Card key="auth-permissions" gap="8px">
      <Text fontSize="24px" fontWeight="bold" mb="16px">
        Auth permissions
      </Text>
      {permissions.map((a) => (
        <Flex alignItems="center" gap="12px" w="100%">
          <Text flex={0.3} color="linkedin.800">
            {areaName(a.area)}
          </Text>
          <Flex flex={0.7} gap="6px" overflow="scroll" align="center">
            {a.scopes.map((s) => (
              <Button
                textTransform="uppercase"
                colorScheme="linkedin"
                variant="outline"
                onClick={() => handleRemovePermission(a.area, s)}
                rightIcon={<MdDelete />}
              >
                {s}
              </Button>
            ))}

            <IconButton
              aria-label=""
              variant="outline"
              size="sm"
              icon={<MdAdd />}
              onClick={() => selectScope(a.area)}
            />
          </Flex>
          <IconButton
            aria-label=""
            colorScheme="red"
            variant="outline"
            size="xs"
            icon={<MdDelete />}
            onClick={() => handleRemoveAreaPermission(a.area)}
          />
        </Flex>
      ))}
      <Button
        variant="outline"
        leftIcon={<MdAdd />}
        alignSelf="start"
        borderStyle="dashed"
        colorScheme="linkedin"
        onClick={onOpen}
      >
        Add new area
      </Button>
      <Flex alignSelf="end" gap="12px">
        {user?.auth.permissions !== permissions && (
          <Button variant="outline" onClick={reset}>
            Reset
          </Button>
        )}
        <Button
          isLoading={loading}
          variant="brand"
          onClick={() => {
            updateUser({
              variables: { id: user?.id, userAuth: { permissions } },
            });
          }}
        >
          Update
        </Button>
      </Flex>
      <AreaSelectPopup
        isOpen={isOpen}
        onClose={onClose}
        onSelect={handleAddAreaPermission}
        filter={permissions.map((a) => a.area)}
      />
      <ScopeSelectPopup
        isOpen={!!selectedScope?.length}
        onClose={() => selectScope(undefined)}
        onSelect={(s) => handleAddPermission(s)}
      />
    </Card>
  );
};

export default AuthPermissions;
