import { gql } from "@apollo/client";
const AREA_FRAGMENT = gql`
  fragment areaFragment on Area {
    id
    name
    nameRef
    locality
    description
    isDisabled
    isCoomingSoon
    domain
    season {
      isOpen
    }
    media {
      videos {
        url
        _id
      }
      pictures {
        url
        _id
      }
    }
    admins {
      user {
        _id
        displayName
      }
      role
    }
    assets {
      coverUrl
      logoUrl
      tailsPath
      logo {
        url
        _id
      }
      cover {
        url
        _id
      }
    }
    bundle {
      version
      updatedAt
      track {
        name
        profilation
        localities {
          name
          isEnabled
          color
        }
        typologies {
          name
          isEnabled
          color
        }
        tagFilters {
          name
          isEnabled
          color
        }
        booleans {
          name
          isEnabled
          color
        }
      }
      poi {
        name
        localities {
          name
          isEnabled
          color
        }
        typologies {
          name
          isEnabled
          color
        }
        tagFilters {
          name
          isEnabled
          color
        }
        booleans {
          name
          isEnabled
          color
        }
      }
      transport {
        name
        localities {
          name
          isEnabled
          color
        }
        typologies {
          name
          isEnabled
          color
        }
        tagFilters {
          name
          isEnabled
          color
        }
        booleans {
          name
          isEnabled
          color
        }
      }
    }
    info {
      webEvents {
        isActive
        title {
          it
          en
          de
        }
        url {
          it
          en
          de
        }
        image {
          it
          en
          de
        }
      }
      contacts {
        phoneNumber
        email
        webSite
      }
      description {
        short {
          it
          en
          de
        }
        long {
          it
          en
          de
        }
      }
      sponsors {
        name
        image
      }
      map {
        extension
        maxHeight
        minHeight
        totalTracks
        totalTransports
        totalTracksExtension
      }
      suggestedPeriod
      features
      location {
        state
        region
        locality
        howToGet {
          street
          coords
          description {
            it
            en
            de
          }
        }
      }
    }
    webcams {
      url
      name {
        it
        en
        de
      }
    }
    weatherForecast {
      name
      latitude
      longitude
    }

    map {
      version
      textures
      texturesLow
      limits {
        geo
        height
      }
      coordsCenter
      resolution
      topology {
        type
        properties {
          name
          importance
        }
        geometry {
          type
          coordinates
        }
      }
      modelData {
        precision
        subdivisions
      }
    }
  }
`;
export const AREA_COMPLETE = gql`
  query Area($id: ID!) {
    Area(id: $id) {
      ...areaFragment
    }
  }
  ${AREA_FRAGMENT}
`;
export const CREATE_AREA = gql`
  mutation (
    $domain: AreaDomain!
    $name: String!
    $nameRef: String!
    $mowiType: AreaMowiType!
    $locality: String
    $description: String
    $media: AreaMediaInput
    $assets: AreaAssetInput
    $bundle: AreaBundleInput
    $map: AreaMapInput
    $isDisabled: Boolean
    $isCoomingSoon: Date
    $webcams: [AreaWebCamInput]
    $weatherForecast: [AreaWeatherForecastInput]
    $info: AreaInfoInput
  ) {
    createArea(
      domain: $domain
      name: $name
      nameRef: $nameRef
      mowiType: $mowiType
      locality: $locality
      description: $description
      media: $media
      assets: $assets
      bundle: $bundle
      map: $map
      isDisabled: $isDisabled
      isCoomingSoon: $isCoomingSoon
      webcams: $webcams
      weatherForecast: $weatherForecast
      info: $info
    ) {
      ...areaFragment
    }
  }
  ${AREA_FRAGMENT}
`;
export const UPDATE_AREA = gql`
  mutation (
    $id: ID!
    $name: String!
    $nameRef: String!
    $mowiType: AreaMowiType!
    $locality: String
    $description: String
    $media: AreaMediaInput
    $assets: AreaAssetInput
    $bundle: AreaBundleInput
    $map: AreaMapInput
    $isDisabled: Boolean
    $isCoomingSoon: Date
    $webcams: [AreaWebCamInput]
    $weatherForecast: [AreaWeatherForecastInput]
    $info: AreaInfoInput
    $files: [StorageFileInput]
  ) {
    updateArea(
      id: $id
      name: $name
      nameRef: $nameRef
      mowiType: $mowiType
      locality: $locality
      description: $description
      media: $media
      assets: $assets
      bundle: $bundle
      map: $map
      isDisabled: $isDisabled
      isCoomingSoon: $isCoomingSoon
      webcams: $webcams
      weatherForecast: $weatherForecast
      info: $info
      files: $files
    ) {
      ...areaFragment
    }
  }
  ${AREA_FRAGMENT}
`;
export const DELETE_AREA = gql`
  mutation ($id: ID!) {
    deleteArea(id: $id) {
      id
    }
  }
`;
export const UPDATE_BUNDLE_VERSION = gql`
  mutation ($id: ID!, $version: String!) {
    updateAreaBundle(id: $id, version: $version) {
      id
      bundle {
        version
      }
    }
  }
`;
export const ALL_AREAS = gql`
  query ALL_AREAS {
    allAreas {
      ...areaFragment
    }
  }
  ${AREA_FRAGMENT}
`;
