import { Badge, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Column } from "components/layout/TableComponent";

const Columns: Column<Track>[] = [
  {
    id: "name",
    render: (value, data) => (
      <NavLink to={data.id ?? ""}>
        <Text color="blue.500">{value}</Text>
      </NavLink>
    ),
    title: "Name",
    width: "40%",
  },
  {
    id: "description",
    render: (value) => (
      <Text fontSize="12px" whiteSpace="nowrap">
        {value?.short?.it?.slice(0, 60) ?? "No description"}
      </Text>
    ),
    title: "Description",
    width: "50%",
  },
  {
    id: "id",
    render: (value) => <Text fontSize="12px">{value}</Text>,
    title: "Id",
  },
  {
    id: "category",
    render: (value, data) => <Badge>{value}</Badge>,
    title: "Category",
  },
  {
    id: "typology",
    render: (value, data) => <Badge>{value}</Badge>,
    title: "Typology",
  },
  {
    id: "id",
    render: (value, data) => <Badge>{data.detailedInfo.trackNumber}</Badge>,
    title: "Track number",
  },
];

export default Columns;
