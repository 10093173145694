import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FormCard } from "../FormBuilderV2";
import InputField from "../FormBuilderV2/Fields/InputField";
import SelectField from "../FormBuilderV2/Fields/SelectField";
import MapComponent from "../Map/MapComponent";

interface GeoLocationProps {
  type?: "Point" | "MultiLineString" | "LineString";
  coordinates?: any;
  onChange: (
    type: "Point" | "MultiLineString" | "LineString",
    coordinates: any
  ) => void;
}

const GeoLocation = (props: GeoLocationProps) => {
  return (
    <FormCard>
      <Flex w="100%">
        <Text fontSize="22px" fontWeight="bold">
          Geo Location
        </Text>
      </Flex>
      <SelectField
        id="typepoint"
        options={[
          { name: "Point", value: "Point" },
          { name: "Line", value: "LineString" },
          { name: "Area", value: "MultiLineString" },
        ]}
        value={props.type}
        onSelected={(s) => {
          props.onChange(s as any, props.coordinates);
        }}
      />
      <InputField
        id="coo"
        label="Coordinates"
        type="text"
        value={props.coordinates}
        placeholder="Coordinates"
        onChange={(a) => props.onChange(props.type ?? "Point", a.target.value)}
      />
      <MapComponent
        type={props.type ?? "Point"}
        coordinates={props.coordinates}
      />
    </FormCard>
  );
};

export default GeoLocation;
